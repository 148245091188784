import api from "../../../assets/genericComponents/API";

const login = async (userData, setCookies) => {
  const response = await api.post(`api/Account/login`, userData);
  if (response.data.data) {
    const user = response.data.data;

    api.defaults.headers.Authorization = `Bearer ${user.token}`;

    delete user.moduleList;
    // sessionStorage.removeItem("intely_health_user");
    // sessionStorage.setItem("intely_health_user", JSON.stringify(user));
    setCookies(user);
  }
  return response.data;
};

const LoginServices = {
  login,
};

export default LoginServices;
