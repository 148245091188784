import api from "../../../../assets/genericComponents/API";

const getFeePercentage = async (data) => {
  const response = await api.get("api/FeePercentage/getFeePercentage", {
    params: data,
  });
  return response.data.data;
};
// const updateFeePercentage = async (data) => {
//   const response = await api.post(`api/FeePercentage/updateFeePercentage`, {
//     params: data,
//   });
//   return response.data;
// };
const updateFeePercentage = async (key, updatedValue) => {
  const response = await api.post(
    `api/FeePercentage/updateFeePercentage?key=${key}&updatedValue=${updatedValue}`
  );
  return response.data;
};

const FeePercentageServices = {
  updateFeePercentage,
  getFeePercentage,
};

export default FeePercentageServices;
