import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SignUpServices from "./signup.services";

const initialState = {
  registerAccount: null,
  isError: false,
  isSuccess: false,
  verifyOTPCodeisSuccess: false,
  registerisSuccess: false,
  reSendOtpCodeisSuccess: false,
  isLoading: false,
  message: "",
  reSendOtpCode: [],
  switchUser: [],
  doctorOnBoarding: [],
  patientOnBoarding: [],
  isEmailExist: [],
  verifyOTPCode: [],
  approvedUser: [],
};

export const registerAction = createAsyncThunk(
  "signUp/register",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await SignUpServices.signUpProcess(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const approvedUserAction = createAsyncThunk(
  "signUp/approvedUser",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await SignUpServices.approvedUser(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const switchUserAction = createAsyncThunk(
  "signUp/switchUserAction",
  async ({ finalData, Notificiation, UpDateCookies }, thunkAPI) => {
    try {
      const response = await SignUpServices.switchUser(finalData);
      if (response.succeeded === true) {
        if (UpDateCookies) {
          UpDateCookies(response.data);
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const reSendOtpCodeAction = createAsyncThunk(
  "signUp/reSendOtpCode",
  async ({ email, Notificiation }, thunkAPI) => {
    try {
      const response = await SignUpServices.reSendOtpCode(email);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const doctorOnBoardingAction = createAsyncThunk(
  "signUp/doctorOnBoardingAction",
  async (thunkAPI) => {
    try {
      return await SignUpServices.doctorOnBoarding();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patientOnBoardingAction = createAsyncThunk(
  "signUp/patientOnBoardingAction",
  async (thunkAPI) => {
    try {
      return await SignUpServices.patientOnBoarding();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const isEmailExistAction = createAsyncThunk(
  "signUp/isEmailExist",
  async (data, thunkAPI) => {
    try {
      return await SignUpServices.isEmailExist(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const verifyOTPCodeAction = createAsyncThunk(
  "signUp/verifyOTPCode",
  async ({ email, code, moveToNext2, Notificiation2 }, thunkAPI) => {
    try {
      const response = await SignUpServices.verifyOTPCode(email, code);
      if (response.succeeded === true) {
        if (Notificiation2) {
          Notificiation2(response.message, "success");
        }
        if (moveToNext2) {
          moveToNext2();
        }
      } else {
        Notificiation2(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    clearSignUp: (state) => {
      state.registerAccount = null;
      state.approvedUser = [];
      state.doctorOnBoarding = [];
      state.switchUser = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.reSendOtpCodeisSuccess = false;
      state.verifyOTPCodeisSuccess = false;
      state.registerisSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registerisSuccess = true;
        state.registerAccount = action.payload;
      })
      .addCase(registerAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(reSendOtpCodeAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reSendOtpCodeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reSendOtpCodeisSuccess = true;
        state.reSendOtpCode = action.payload;
      })
      .addCase(reSendOtpCodeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(switchUserAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(switchUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reSendOtpCodeisSuccess = true;
        state.switchUser = action.payload;
      })
      .addCase(switchUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(doctorOnBoardingAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(doctorOnBoardingAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.approvedUserisSuccess = true;
        state.doctorOnBoarding = action.payload;
      })
      .addCase(doctorOnBoardingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(patientOnBoardingAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientOnBoardingAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.approvedUserisSuccess = true;
        state.patientOnBoarding = action.payload;
      })
      .addCase(patientOnBoardingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(approvedUserAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approvedUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.approvedUserisSuccess = true;
        state.approvedUser = action.payload;
      })
      .addCase(approvedUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(isEmailExistAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(isEmailExistAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isEmailExist = action.payload;
      })
      .addCase(isEmailExistAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(verifyOTPCodeAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyOTPCodeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.verifyOTPCodeisSuccess = true;
        state.verifyOTPCode = action.payload;
      })
      .addCase(verifyOTPCodeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearSignUp } = signUpSlice.actions;

export default signUpSlice.reducer;
