import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(
    `api/PatientMotherExamination/addOrUpdate`,
    data
  );
  return response.data;
};
const deletePatientMotherExamination = async (data) => {
  const response = await api.post(`api/PatientMotherExamination/delete`, data);
  return response.data;
};

const getAllPatientMotherExamination = async (data) => {
  const response = await api.get(
    "api/PatientMotherExamination/getAllPatientMotherExamination",
    {
      params: data,
    }
  );
  return response.data.data;
};

const PatientMotherExaminationServices = {
  addOrUpdate,
  getAllPatientMotherExamination,
  deletePatientMotherExamination,
};

export default PatientMotherExaminationServices;
