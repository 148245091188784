import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(
    `api/PatientCurrentMedication/addOrUpdate`,
    data
  );
  return response.data;
};
const deletePatientCurrentMedication = async (data) => {
  const response = await api.post(`api/PatientCurrentMedication/delete`, data);
  return response.data;
};
const getAllCurrentMedication = async (data) => {
  const response = await api.get(
    `api/PatientCurrentMedication/getAllCurrentMedication?userId=${data.userId}&medicationListType=${data.medicationListType}&doctorId=${data.doctorId}&patientAppointmentId=${data.patientAppointmentId}`
  );
  return response.data.data;
};

const PatientCurrentMedicationServices = {
  addOrUpdate,
  getAllCurrentMedication,
  deletePatientCurrentMedication,
};

export default PatientCurrentMedicationServices;
