import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FeePercentageServices from "./feePercentage.services";

const initialState = {
  getFeePercentage: [],
  updateFeePercentage: [],
  isError: false,
  isLoading: false,
  updateFeePercentageIsLoading: false,
  isSuccess: false,
  message: "",
};

export const updateFeePercentageAction = createAsyncThunk(
  "feePercentage/updateFeePercentageAction",
  async ({ finalData, Notification, moveToNext }, thunkAPI) => {
    debugger;
    try {
      const response = await FeePercentageServices.updateFeePercentage(
        finalData?.key,
        finalData?.updatedValue
      );
      if (response.succeeded === true) {
        if (Notification) {
          Notification(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notification(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFeePercentageAction = createAsyncThunk(
  "feePercentage/getFeePercentageAction",
  async (data, thunkAPI) => {
    try {
      return await FeePercentageServices.getFeePercentage(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const FeePercentageSlice = createSlice({
  name: "feePercentage",
  initialState,
  reducers: {
    clearFeePercentage: (state) => {
      state.getFeePercentage = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeePercentageAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFeePercentageAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getFeePercentage = action.payload;
      })
      .addCase(getFeePercentageAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearFeePercentage } = FeePercentageSlice.actions;

export default FeePercentageSlice.reducer;
