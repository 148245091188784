import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ServiceRegionServices from "./serviceRegion.services";

const initialState = {
  updateRegion: null,
  getServiceRegionById: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const serviceRegionAction = createAsyncThunk(
  "ServiceRegion/serviceRegionAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await ServiceRegionServices.updateRegion(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getServiceRegionByIdAction = createAsyncThunk(
  "ServiceRegion/getServiceRegionByIdAction",
  async (userId, thunkAPI) => {
    try {
      return await ServiceRegionServices.getServiceRegionById(userId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const serviceRegionSlice = createSlice({
  name: "ServiceRegion",
  initialState,
  reducers: {
    clearServiceRegion: (state) => {
      state.updateRegion = null;
      state.getServiceRegionById = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(serviceRegionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(serviceRegionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateRegion = action.payload;
      })
      .addCase(serviceRegionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getServiceRegionByIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getServiceRegionByIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getServiceRegionById = action.payload;
      })
      .addCase(getServiceRegionByIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearServiceRegion } = serviceRegionSlice.actions;

export default serviceRegionSlice.reducer;
