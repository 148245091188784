import api from "../../../../../assets/genericComponents/API";

const getServiceRegionById = async (userId) => {
  const response = await api.get(
    `api/ServiceRegion/getServiceRegionById?userId=${userId}`
  );
  return response.data;
};
const updateRegion = async (data) => {
  const response = await api.post(`api/ServiceRegion/update`, data);
  return response.data;
};

const ServiceRegionServices = {
  getServiceRegionById,
  updateRegion,
};

export default ServiceRegionServices;
