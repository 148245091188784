import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientSurgeryHistoryServices from "./patientSurgeryHistory.services";

const initialState = {
  addOrUpdate: [],
  getAllPatientSurgeryHistory: [],
  deletePatientSurgeryHistory: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateSurgeryAction = createAsyncThunk(
  "patientSurgeryHistory/addOrUpdateSurgeryAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientSurgeryHistoryServices.addOrUpdate(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllPatientSurgeryHistoryAction = createAsyncThunk(
  "patientSurgeryHistory/getAllPatientSurgeryHistoryAction",
  async (patientId, thunkAPI) => {
    try {
      return await PatientSurgeryHistoryServices.getAllSurgeryHistory(
        patientId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePatientSurgeryHistoryAction = createAsyncThunk(
  "patientSurgeryHistory/deletePatientSurgeryHistoryAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response =
        await PatientSurgeryHistoryServices.deletePatientSurgeryHistory(
          finalData
        );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const PatientSurgeryHistorySlice = createSlice({
  name: "patientSurgeryHistory",
  initialState,
  reducers: {
    clearPatientSurgeryHistory: (state) => {
      state.addOrUpdate = [];
      state.getAllPatientSurgeryHistory = [];
      state.deletePatientSurgeryHistory = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateSurgeryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateSurgeryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateSurgeryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPatientSurgeryHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientSurgeryHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientSurgeryHistory = action.payload;
      })
      .addCase(getAllPatientSurgeryHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deletePatientSurgeryHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatientSurgeryHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePatientSurgeryHistory = action.payload;
      })
      .addCase(deletePatientSurgeryHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearPatientSurgeryHistory } =
  PatientSurgeryHistorySlice.actions;

export default PatientSurgeryHistorySlice.reducer;
