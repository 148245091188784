const ERROR = {
  SYSTEM_ERROR: "System error. Please try again later!",
};

const PATH = {
  NOPAGE: "*",
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  SIGNUP: "/signup",
  PATIENTSIGNUP: "/patient-signup",
  FORGOTPASSWORD: "/forgot-password",
  DOCTORINFORMATION: "/general-information",
  PATIENTINFORMATION: "/patient-general-information",
  DOCTORDASHBOARD: "/doctor-dashboard",
  DOCTORSLOTS: "/doctor-slots",
  PATIENTDASHBOARD: "/patient-dashboard",
  PATIENTLISTING: "/patient-list",
  DOCTORPROFILE: "/doctor-profile",
  ALLVISITS: "/all-visits",
  VIEWLISTFORVISITER: "/patient-view",
  VIEWNOTES: "/view-notes",
  CALENDARVIEW: "/calendar-view",
  ADDFAMILYMEMBER: "/add-members",
  UPDATEFAMILYMEMBER: "/edit-members",
  MYEARNING: "/my-earning",
  COMMUNITYFORM: "/community-form",
  BLOGS: "/blog",
  FINDADOCTOR: "/find-doctor",
  PATIENTVIEW: "/patient-view-detail",
  PAYMENTINFO: "/payment-information",
  CHAT: "/Chat",
  PATIENTCHAT: "/patient-chat",
  E_PRESCRIPTION: "/e-prescription",
  LABTESTREPORT: "/labtest",
  VIDEOCONSULTATION: "/video-consultation",
  TELECONSULTATION: "/tele-consultation",
  MESSAGE: "/message",
  MYAPPOINTMENTS: "/my-appointments",
  NOTES: "/notes-list",
  DOCTORPATIENTPROFILE: "/doctor-detail",
  APPOINTMENTSLOTS: "/appointment-slots",
  ADMINDASHBOARD: "/admin-dashboard",
  ADMINAPPOINTMENTS: "/admin-appointments",
  ADMINROLESETTINGS: "/admin-role-settings",
  ADMINCREATENEWROLE: "/admin-create-new-role",
  ADMINROLEPERMISSIONS: "/admin-role-permissions",
  SUBSCRIPTIONPLAN: "/subscription-plan",
  FAMILYMEMBERS: "/family-members",
  EMPLOYEEMANAGEMENT: "/admin-employee-management",
  ADMINCREATEANEMPLOYEE: "/admin-create-employee",
  PAYMENTMANAGEMENT: "/payment-management",
  PAYMENTSETTINGS: "/payment-settings",
  COMMUNITYFORUM: "/community-forum",
  PAYMENTREFUND: "/payment-refund",
  ADMINCOMMUNITYFORUM: "/admin-community-forum",
  PHYSICIANMANAGEMENT: "/physician-management",
  DOCTORPROFILEPHYSICIAN: "/doctor-profile-physician",
  ADMINSUBSCRIPTIONPLAN: "/subscription-plans",
  SUBSCRIPTIONUSAGE: "/subscription-usage",
  COUPONMANAGEMENT: "/coupon-management",
  CHECKBOXFLOW: "/checkbox-flow",
  USERDOCTORMANAGEMENT: "/doctor-management",
  USERPATIENTMANAGEMENT: "/patient-management",
  PATIENTPROFILEMANAGEMENT: "/patient-profile-management",
  ADMINFEEDBACK: "/admin-feedback",
  LANDINGSUBSCRIPTION: "/subscription",
  MYPLANS: "/myplans",
  PLANSHISTORY: "/planhistory",
  LANDINGAVATR: "/avatar/docid=:docid/appid=:appid/gid=:gid",
  LANDINGNOTES: "/notes/patid=:patid/docid=:docid/apptid=:apptid",
  LANDINGEPRESCRIPTION:
    "/eprescription/patid=:patid/docid=:docid/apptid=:apptid",
  LANDINGLABTEST: "/labtest/patid=:patid/docid=:docid/apptid=:apptid",
  LANDINGCOMMUNITYFORM: "/communityform/docid=:docid/comid=:comid",
  ALLSUBSCRIBERVIEW: "/subscriber",
  INDIVIDUALCOMMUNITYFORM: "/community-forum-single",
  PATIENTOWNVITALS: "/patient-vitals",
  PRIVACYPOLICY: "/privacy-and-policy",
};

const baseUrl = "url_here_/api/";

const APP_SETTINGS = {
  API_PATH: {
    LOGIN: {
      login: baseUrl + "login/post",
    },
  },
};
const AZURE_BLOB_BASE_URL = "blob_url_here";

const ROLEID = {
  ADMIN: 1,
  DOCTOR: 2,
  PATIENT: 3,
  STAFF: 4,
};
const APPOINTMENT_STATUS_DATA = [
  {
    lookupId: 142,
    type: "AppointmentStatus",
    text: "Completed",
    value: "Completed",
    order: 1,
  },
  {
    lookupId: 143,
    type: "AppointmentStatus",
    text: "Missed",
    value: "Missed",
    order: 2,
  },
  {
    lookupId: 144,
    type: "AppointmentStatus",
    text: "Cancelled",
    value: "Cancelled",
    order: 3,
  },
  {
    lookupId: 145,
    type: "AppointmentStatus",
    text: "Booked",
    value: "Booked",
    order: 4,
  },
  {
    lookupId: 162,
    type: "AppointmentStatus",
    text: "All Visits",
    value: "All Visits",
    order: 5,
  },
];
const MEDICATION_ROUTE = [
  {
    lookupId: 152,
    type: "MedicationRoute",
    text: "oral",
    value: "oral",
    order: 1,
  },
  {
    lookupId: 153,
    type: "MedicationRoute",
    text: "parenteral ",
    value: "parenteral ",
    order: 2,
  },
  {
    lookupId: 154,
    type: "MedicationRoute",
    text: "topical",
    value: "topical",
    order: 3,
  },
];
const APPOINTMENT_TYPE_DATA = [
  {
    lookupId: 146,
    type: "AppointmentType",
    text: "New",
    value: "New",
    order: 1,
  },
  {
    lookupId: 147,
    type: "AppointmentType",
    text: "FollowUp",
    value: "FollowUp",
    order: 2,
  },
];
const GENDER_DATA = [
  {
    lookupId: 7,
    type: "Gender",
    text: "Male",
    value: "Male",
    order: 1,
  },
  {
    lookupId: 8,
    type: "Gender",
    text: "Female",
    value: "Female",
    order: 2,
  },
  {
    lookupId: 161,
    type: "Gender",
    text: "Other",
    value: "Other",
    order: 3,
  },
];
const PLAN_TYPE = [
  {
    lookupId: 155,
    type: "PlanType",
    text: "À la carte",
    value: "À la carte",
    order: 1,
  },
  {
    lookupId: 156,
    type: "PlanType",
    text: "Subscription",
    value: "Subscription",
    order: 2,
  },
];
const PLAN_DURATION = [
  {
    lookupId: 148,
    type: "PlanDuration",
    text: "Month",
    value: "Month",
    order: 1,
  },
  {
    lookupId: 149,
    type: "PlanDuration",
    text: "Day",
    value: "Day",
    order: 2,
  },
  {
    lookupId: 151,
    type: "PlanDuration",
    text: "Year",
    value: "Year",
    order: 4,
  },
];
const SLOT_DURATION_AND_GAP = [
  {
    lookupId: 138,
    type: "SlotDurationAndGap",
    text: "5",
    value: "5",
    order: 1,
  },
  {
    lookupId: 139,
    type: "SlotDurationAndGap",
    text: "10",
    value: "10",
    order: 2,
  },
  {
    lookupId: 140,
    type: "SlotDurationAndGap",
    text: "20",
    value: "20",
    order: 3,
  },
  {
    lookupId: 141,
    type: "SlotDurationAndGap",
    text: "30",
    value: "30",
    order: 4,
  },
];
const TITLE_DATA = [
  {
    lookupId: 17,
    type: "Title",
    text: "Mr.",
    value: "Mr",
    order: 1,
  },
  {
    lookupId: 18,
    type: "Title",
    text: "Ms.",
    value: "Ms",
    order: 2,
  },
  {
    lookupId: 19,
    type: "Title",
    text: "Mrs.",
    value: "Mrs",
    order: 3,
  },
];
const MARITAL_STATUS_DATA = [
  {
    lookupId: 20,
    type: "MaritalStatus",
    text: "Married",
    value: "Married",
    order: 1,
  },
  {
    lookupId: 21,
    type: "MaritalStatus",
    text: "Umnmarried",
    value: "Unmarried",
    order: 2,
  },
];
const EDUCATION_TYPE_DATA = [
  {
    lookupId: 15,
    type: "EducationType",
    text: "Formal",
    value: "Formal",
    order: 1,
  },
  {
    lookupId: 16,
    type: "EducationType",
    text: "Informal",
    value: "Informal",
    order: 2,
  },
];
const LICENSE_DATA = [
  {
    lookupId: 22,
    type: "LicenseType",
    text: "Regular License",
    value: "Regular License",
    order: 1,
  },
  {
    lookupId: 23,
    type: "LicenseType",
    text: "Temporary Permit",
    value: "Temporary Permit",
    order: 2,
  },
  {
    lookupId: 24,
    type: "LicenseType",
    text: "Institutional Practice Limited License",
    value: "Institutional Practice Limited License",
    order: 3,
  },
  {
    lookupId: 25,
    type: "LicenseType",
    text: "Residency Training License",
    value: "Residency Training License",
    order: 4,
  },
  {
    lookupId: 26,
    type: "LicenseType",
    text: "Faculty License",
    value: "Faculty License",
    order: 5,
  },
  {
    lookupId: 27,
    type: "LicenseType",
    text: "Fellowship Training License",
    value: "Fellowship Training License",
    order: 6,
  },
];
const DELIVERY_TYPE_DATA = [
  {
    lookupId: 49,
    type: "DeliveryType",
    text: "Natural Birth",
    value: "Natural Birth",
    order: 1,
  },
  {
    lookupId: 50,
    type: "DeliveryType",
    text: "Scheduled Cesarean",
    value: "Scheduled Cesacrean",
    order: 2,
  },
  {
    lookupId: 51,
    type: "DeliveryType",
    text: "Unplanned Cesarean",
    value: "Unplanned Cesarean",
    order: 3,
  },
];
const GYNECOLOGICAL_CONDITION_DATA = [
  {
    lookupId: 75,
    type: "GynaecologicalConditionType",
    text: "Cervical Cancer",
    value: "Cervical Cancer",
    order: 1,
  },
  {
    lookupId: 76,
    type: "GynaecologicalConditionType",
    text: "Endometrial Cancers",
    value: "Endometrial Cancers",
    order: 2,
  },
  {
    lookupId: 77,
    type: "GynaecologicalConditionType",
    text: "Endometriosis and Fibroids",
    value: "Endometriosis and Fibroids",
    order: 3,
  },
  {
    lookupId: 78,
    type: "GynaecologicalConditionType",
    text: "Heavy Menstrual Bleeding",
    value: "Heavy Menstrual Bleeding",
    order: 4,
  },
  {
    lookupId: 79,
    type: "GynaecologicalConditionType",
    text: "Menopause",
    value: "Menopause",
    order: 5,
  },
  {
    lookupId: 80,
    type: "GynaecologicalConditionType",
    text: "Overian Cancer",
    value: "Overian Cancer",
    order: 6,
  },
  {
    lookupId: 81,
    type: "GynaecologicalConditionType",
    text: "Termination of Pregnancy Services",
    value: "Termination of Pregnancy Services",
    order: 7,
  },
  {
    lookupId: 82,
    type: "GynaecologicalConditionType",
    text: "Uterine Prolapse",
    value: "Uterine Prolapse",
    order: 8,
  },
  {
    lookupId: 83,
    type: "GynaecologicalConditionType",
    text: "Vaginal Conditions",
    value: "Vaginal Conditios",
    order: 9,
  },
];
const MENSTURAL_PERIOD_DATA = [
  {
    lookupId: 52,
    type: "MenstrualPeriod",
    text: "28 Days",
    value: "28 Days",
    order: 1,
  },
  {
    lookupId: 53,
    type: "MenstrualPeriod",
    text: "30 Days",
    value: "30 Days",
    order: 2,
  },
];
const RELATION_DATA = [
  {
    lookupId: 9,
    type: "Relation",
    text: "Brother",
    value: "Brother",
    order: 1,
  },
  {
    lookupId: 10,
    type: "Relation",
    text: "Sister",
    value: "Sister",
    order: 2,
  },
  {
    lookupId: 11,
    type: "Relation",
    text: "Father",
    value: "Father",
    order: 3,
  },
  {
    lookupId: 12,
    type: "Relation",
    text: "Mother",
    value: "Mother",
    order: 4,
  },
  {
    lookupId: 13,
    type: "Relation",
    text: "Friend",
    value: "Friend",
    order: 5,
  },
  {
    lookupId: 14,
    type: "Relation",
    text: "Other",
    value: "Other",
    order: 6,
  },
];
const BLOOD_GROUP_DATA = [
  {
    lookupId: 41,
    type: "BloodGroup",
    text: "A+",
    value: "A+",
    order: 1,
  },
  {
    lookupId: 42,
    type: "BloodGroup",
    text: "A-",
    value: "A-",
    order: 2,
  },
  {
    lookupId: 43,
    type: "BloodGroup",
    text: "B+",
    value: "B+",
    order: 3,
  },
  {
    lookupId: 44,
    type: "BloodGroup",
    text: "B-",
    value: "B-",
    order: 4,
  },
  {
    lookupId: 45,
    type: "BloodGroup",
    text: "O+",
    value: "O+",
    order: 5,
  },
  {
    lookupId: 46,
    type: "BloodGroup",
    text: "O-",
    value: "O-",
    order: 6,
  },
  {
    lookupId: 47,
    type: "BloodGroup",
    text: "AB+",
    value: "AB+",
    order: 7,
  },
  {
    lookupId: 48,
    type: "BloodGroup",
    text: "AB-",
    value: "AB-",
    order: 8,
  },
];
const SURGERY_TYPE_DATA = [
  {
    lookupId: 54,
    type: "SurgeryType",
    text: "Bariatric Surgery",
    value: "Bariatric Surgery",
    order: 1,
  },
  {
    lookupId: 55,
    type: "SurgeryType",
    text: "Breast Surgery",
    value: "Breast Surgery",
    order: 2,
  },
  {
    lookupId: 56,
    type: "SurgeryType",
    text: "Colon & Rectal Surgery",
    value: "Colon & Rectal Surgery",
    order: 3,
  },
  {
    lookupId: 57,
    type: "SurgeryType",
    text: "Endocrine Surgery",
    value: "Endocrine Surgery",
    order: 4,
  },
  {
    lookupId: 58,
    type: "SurgeryType",
    text: "Vascular Surgery",
    value: "Vascular Surgery",
    order: 5,
  },
  {
    lookupId: 59,
    type: "SurgeryType",
    text: "Urologic Surgery",
    value: "Urologic Surgery",
    order: 6,
  },
  {
    lookupId: 60,
    type: "SurgeryType",
    text: "Trauma Surgery",
    value: "Trauma Surgery",
    order: 7,
  },
  {
    lookupId: 61,
    type: "SurgeryType",
    text: "Thoracic Surgery",
    value: "Thoracic Surgery",
    order: 8,
  },
  {
    lookupId: 62,
    type: "SurgeryType",
    text: "Robotic Surgery",
    value: "Robotic Surgery",
    order: 9,
  },
  {
    lookupId: 63,
    type: "SurgeryType",
    text: "Plastic & Reconstructive Surgery",
    value: "Plastic & Reconstructive Surgery",
    order: 10,
  },
  {
    lookupId: 64,
    type: "SurgeryType",
    text: "Pediatric Surgery",
    value: "Pediatric Surgery",
    order: 11,
  },
  {
    lookupId: 65,
    type: "SurgeryType",
    text: "Outpatient Surgery",
    value: "Outpatient Surgery",
    order: 12,
  },
  {
    lookupId: 66,
    type: "SurgeryType",
    text: "Ophthalmological Surgery",
    value: "Ophthalmological Surgery",
    order: 13,
  },
  {
    lookupId: 67,
    type: "SurgeryType",
    text: "Orthopedic Surgery",
    value: "Orthopedic Surgery",
    order: 14,
  },
  {
    lookupId: 68,
    type: "SurgeryType",
    text: "General Surgery",
    value: "General Surgery",
    order: 15,
  },
  {
    lookupId: 69,
    type: "SurgeryType",
    text: "Neurosurgery",
    value: "Neurosurgery",
    order: 16,
  },
  {
    lookupId: 70,
    type: "SurgeryType",
    text: "Minimally Invasive Surgery",
    value: "Minimally Invasive Surgery",
    order: 17,
  },
  {
    lookupId: 71,
    type: "SurgeryType",
    text: "Gynecological Surgery",
    value: "Gynecological Surgery",
    order: 18,
  },
  {
    lookupId: 72,
    type: "SurgeryType",
    text: "Hand Surgery",
    value: "Hand Surgery",
    order: 19,
  },
  {
    lookupId: 73,
    type: "SurgeryType",
    text: "Head & Neck Surgery",
    value: "Head & Neck Surgery",
    order: 20,
  },
  {
    lookupId: 74,
    type: "SurgeryType",
    text: "Hernia Surgery",
    value: "Hernia Surgery",
    order: 21,
  },
];
const CURRENCY_DATA = [
  {
    lookupId: 159,
    type: "CurrencyType",
    text: "Dollar",
    value: "Dollar",
    order: 1,
  },
  {
    lookupId: 160,
    type: "CurrencyType",
    text: "Euro",
    value: "Euro",
    order: 2,
  },
];
const MEDICINE_FREQUENCY_DATA = [
  {
    lookupId: 102,
    type: "MedicineFrequency",
    text: "1-1-1-1",
    value: "1-1-1-1",
    order: 1,
  },
  {
    lookupId: 103,
    type: "MedicineFrequency",
    text: "0-1-1-1",
    value: "0-1-1-1",
    order: 2,
  },
  {
    lookupId: 104,
    type: "MedicineFrequency",
    text: "0-0-1-1",
    value: "0-0-1-1",
    order: 3,
  },
  {
    lookupId: 105,
    type: "MedicineFrequency",
    text: "0-0-0-1",
    value: "0-0-0-1",
    order: 4,
  },
  {
    lookupId: 106,
    type: "MedicineFrequency",
    text: "1-0-0-0",
    value: "1-0-0-0",
    order: 5,
  },
  {
    lookupId: 107,
    type: "MedicineFrequency",
    text: "1-1-0-0",
    value: "1-1-0-0",
    order: 6,
  },
  {
    lookupId: 108,
    type: "MedicineFrequency",
    text: "1-1-1-0",
    value: "1-1-1-0",
    order: 7,
  },
  {
    lookupId: 109,
    type: "MedicineFrequency",
    text: "1-0-1-0",
    value: "1-0-1-0",
    order: 8,
  },
  {
    lookupId: 110,
    type: "MedicineFrequency",
    text: "0-1-0-1",
    value: "0-1-0-1",
    order: 9,
  },
  {
    lookupId: 111,
    type: "MedicineFrequency",
    text: "1-0-0-1",
    value: "1-0-0-1",
    order: 10,
  },
  {
    lookupId: 112,
    type: "MedicineFrequency",
    text: "0-1-1-0",
    value: "0-1-1-0",
    order: 11,
  },
  {
    lookupId: 113,
    type: "MedicineFrequency",
    text: "3 hourly",
    value: "3 hourly",
    order: 12,
  },
  {
    lookupId: 114,
    type: "MedicineFrequency",
    text: "4 hourly",
    value: "4 hourly",
    order: 13,
  },
  {
    lookupId: 115,
    type: "MedicineFrequency",
    text: "6 hourly",
    value: "6 hourly",
    order: 14,
  },
  {
    lookupId: 116,
    type: "MedicineFrequency",
    text: "8 hourly",
    value: "8 hourly",
    order: 15,
  },
  {
    lookupId: 117,
    type: "MedicineFrequency",
    text: "12 hourly",
    value: "12 hourly",
    order: 16,
  },
  {
    lookupId: 118,
    type: "MedicineFrequency",
    text: "When needed",
    value: "When needed",
    order: 17,
  },
  {
    lookupId: 119,
    type: "MedicineFrequency",
    text: "20 drops/min",
    value: "20 drops/min",
    order: 18,
  },
  {
    lookupId: 120,
    type: "MedicineFrequency",
    text: "30 drops/min",
    value: "30 drops/min",
    order: 19,
  },
  {
    lookupId: 121,
    type: "MedicineFrequency",
    text: "60 drops/min",
    value: "60 drops/min",
    order: 20,
  },
  {
    lookupId: 122,
    type: "MedicineFrequency",
    text: "Running",
    value: "Running",
    order: 21,
  },
  {
    lookupId: 123,
    type: "MedicineFrequency",
    text: "15 micro drops/min",
    value: "15 micro drops/min",
    order: 22,
  },
  {
    lookupId: 124,
    type: "MedicineFrequency",
    text: "20 micro drops/min",
    value: "20 micro drops/min",
    order: 23,
  },
  {
    lookupId: 125,
    type: "MedicineFrequency",
    text: "30 micro drops/min",
    value: "30 micro drops/min",
    order: 24,
  },
  {
    lookupId: 126,
    type: "MedicineFrequency",
    text: "1 liter/min",
    value: "1 liter/min",
    order: 25,
  },
  {
    lookupId: 127,
    type: "MedicineFrequency",
    text: "2 liter/min",
    value: "2 liter/min",
    order: 26,
  },
  {
    lookupId: 128,
    type: "MedicineFrequency",
    text: "3 liter/min",
    value: "3 liter/min",
    order: 27,
  },
];
const MEDICINE_DOSE_DATA = [
  {
    lookupId: 84,
    type: "MedicineDose",
    text: "One Tablet",
    value: "One Tablet",
    order: 1,
  },
  {
    lookupId: 85,
    type: "MedicineDose",
    text: "Half Tablet",
    value: "Half Tablet",
    order: 2,
  },
  {
    lookupId: 86,
    type: "MedicineDose",
    text: "Quarter Tablet",
    value: "Quarter Tablet",
    order: 3,
  },
  {
    lookupId: 87,
    type: "MedicineDose",
    text: "Two Tablets",
    value: "Two Tablets",
    order: 4,
  },
  {
    lookupId: 88,
    type: "MedicineDose",
    text: "5 ml",
    value: "5 ml",
    order: 5,
  },
  {
    lookupId: 89,
    type: "MedicineDose",
    text: "1 Teaspoon",
    value: "1 Teaspoon",
    order: 6,
  },
  {
    lookupId: 90,
    type: "MedicineDose",
    text: "10 ml",
    value: "10 ml",
    order: 7,
  },
  {
    lookupId: 91,
    type: "MedicineDose",
    text: "2 Teaspoon",
    value: "2 Teaspoon",
    order: 8,
  },
  {
    lookupId: 92,
    type: "MedicineDose",
    text: "1 drop",
    value: "1 drop",
    order: 9,
  },
  {
    lookupId: 93,
    type: "MedicineDose",
    text: "2 drops",
    value: "2 drops",
    order: 10,
  },
  {
    lookupId: 94,
    type: "MedicineDose",
    text: "4 drops",
    value: "4 drops",
    order: 11,
  },
  {
    lookupId: 95,
    type: "MedicineDose",
    text: "6 drops",
    value: "6 drops",
    order: 12,
  },
  {
    lookupId: 96,
    type: "MedicineDose",
    text: "8 drops",
    value: "8 drops",
    order: 13,
  },
  {
    lookupId: 97,
    type: "MedicineDose",
    text: "10 drops",
    value: "10 drops",
    order: 14,
  },
  {
    lookupId: 98,
    type: "MedicineDose",
    text: "One puff",
    value: "One puff",
    order: 15,
  },
  {
    lookupId: 99,
    type: "MedicineDose",
    text: "Two puff",
    value: "Two puff",
    order: 16,
  },
  {
    lookupId: 100,
    type: "MedicineDose",
    text: "Cozycap",
    value: "Cozycap",
    order: 17,
  },
  {
    lookupId: 101,
    type: "MedicineDose",
    text: "Apply Locally",
    value: "Apply Locally",
    order: 18,
  },
];
const TOASTER_STYLING_VALUES = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const PAGE_SIZE = 6;
const PAGINATION_PAGE_SIZE = 10;
const PAGINATION_PAGE_SIZE_FOR_CARDS = 8;
// const WEB_SOCKET_URL = "https://stealthsocket.ghostlayers.com:3000/chat";
const WEB_SOCKET_URL = "https://intellihealthsocket.xeventechnologies.com/chat";
// const WEB_SOCKET_URL = "https://socketapp.contestantcenter.com/chat";

// export const socket = io("https://socketapp.contestantcenter.com/chat");
export {
  ERROR,
  PATH,
  PAGE_SIZE,
  WEB_SOCKET_URL,
  PLAN_TYPE,
  GENDER_DATA,
  PLAN_DURATION,
  RELATION_DATA,
  MEDICINE_DOSE_DATA,
  APP_SETTINGS,
  GYNECOLOGICAL_CONDITION_DATA,
  EDUCATION_TYPE_DATA,
  MARITAL_STATUS_DATA,
  MENSTURAL_PERIOD_DATA,
  SURGERY_TYPE_DATA,
  SLOT_DURATION_AND_GAP,
  ROLEID,
  TITLE_DATA,
  MEDICINE_FREQUENCY_DATA,
  DELIVERY_TYPE_DATA,
  LICENSE_DATA,
  TOASTER_STYLING_VALUES,
  APPOINTMENT_TYPE_DATA,
  AZURE_BLOB_BASE_URL,
  APPOINTMENT_STATUS_DATA,
  PAGINATION_PAGE_SIZE,
  PAGINATION_PAGE_SIZE_FOR_CARDS,
  BLOOD_GROUP_DATA,
  CURRENCY_DATA,
  MEDICATION_ROUTE,
};
