import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientCurrentMedicationServices from "./patientCurrentMedication.services";

const initialState = {
  addOrUpdate: [],
  getAllCurrentMedication: [],
  deletePatientCurrentMedication: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateCurrentMedicationAction = createAsyncThunk(
  "patientCurrentMedication/addOrUpdateCurrentMedicationAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientCurrentMedicationServices.addOrUpdate(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllCurrentMedicationAction = createAsyncThunk(
  "patientCurrentMedication/getAllCurrentMedicationAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientCurrentMedicationServices.getAllCurrentMedication(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePatientCurrentMedicationAction = createAsyncThunk(
  "patientCurrentMedication/deletePatientCurrentMedicationAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response =
        await PatientCurrentMedicationServices.deletePatientCurrentMedication(
          finalData
        );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const PatientCurrentMedicationSlice = createSlice({
  name: "patientCurrentMedication",
  initialState,
  reducers: {
    clearPatientCurrentMedication: (state) => {
      state.addOrUpdate = [];
      state.getAllCurrentMedication = [];
      state.deletePatientCurrentMedication = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateCurrentMedicationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        addOrUpdateCurrentMedicationAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.addOrUpdate = action.payload;
        }
      )
      .addCase(addOrUpdateCurrentMedicationAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllCurrentMedicationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCurrentMedicationAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllCurrentMedication = action.payload;
      })
      .addCase(getAllCurrentMedicationAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deletePatientCurrentMedicationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        deletePatientCurrentMedicationAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.deletePatientCurrentMedication = action.payload;
        }
      )
      .addCase(
        deletePatientCurrentMedicationAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      );
  },
});

export const { clearPatientCurrentMedication } =
  PatientCurrentMedicationSlice.actions;

export default PatientCurrentMedicationSlice.reducer;
