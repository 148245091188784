import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import VonageServices from "./vonage.services";

const initialState = {
  getVonageSessionID: [],
  getVonageToken: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const getVonageTokenAction = createAsyncThunk(
  "vonage/getVonageTokenAction",
  async ({ finalData, MoveToNext }, thunkAPI) => {
    try {
      const response = await VonageServices.getVonageToken(finalData);
      if (response.succeeded === true) {
        // if (Notificiation) {
        //   Notificiation(response.message, "success");
        // }
        if (MoveToNext) {
          MoveToNext(response.data.tokkenId);
        }
      } else {
        // Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getVonageSessionIDAction = createAsyncThunk(
  "vonage/getVonageSessionIDAction",
  async (thunkAPI) => {
    try {
      return await VonageServices.getVonageSessionID();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const VonageSlice = createSlice({
  name: "vonage",
  initialState,
  reducers: {
    clearVonage: (state) => {
      state.getVonageSessionID = [];
      state.getVonageToken = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVonageTokenAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVonageTokenAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getVonageToken = action.payload;
      })
      .addCase(getVonageTokenAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getVonageSessionIDAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVonageSessionIDAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getVonageSessionID = action.payload;
      })
      .addCase(getVonageSessionIDAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearVonage } = VonageSlice.actions;

export default VonageSlice.reducer;
