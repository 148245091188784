import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EducationDetailServices from "./educationDetail.services";

const initialState = {
  addOrUpdate: [],
  getAllEducationDetail: [],
  deleteEducationDetail: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateAction = createAsyncThunk(
  "educationDetail/addOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await EducationDetailServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllEducationDetailAction = createAsyncThunk(
  "educationDetail/getAllEducationDetailAction",
  async (userId, thunkAPI) => {
    try {
      return await EducationDetailServices.getAllEducationDetail(userId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteEducationDetailAction = createAsyncThunk(
  "educationDetail/deleteEducationDetail",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await EducationDetailServices.deleteEducationDetail(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const EducationDetailSlice = createSlice({
  name: "educationDetail",
  initialState,
  reducers: {
    clearEducationDetail: (state) => {
      state.addOrUpdate = [];
      state.getAllEducationDetail = [];
      state.deleteEducationDetail = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllEducationDetailAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllEducationDetailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllEducationDetail = action.payload;
      })
      .addCase(getAllEducationDetailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteEducationDetailAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEducationDetailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteEducationDetail = action.payload;
      })
      .addCase(deleteEducationDetailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearEducationDetail } = EducationDetailSlice.actions;

export default EducationDetailSlice.reducer;
