import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientPersonalInfoServices from "./patientGeneralInfo.services";

const initialState = {
  addOrUpdate: [],
  getAllPatientPersonalInfo: [],
  getPatientPersonalInfoById: [],
  getPrescriptionWithoutTokken: [],
  getAllSubscribedPatientByDoctor: [],
  getAllPatientByDoctor: [],
  getPrescription: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  SubscriberisLoading: false,
  message: "",
};

export const addOrUpdatePatientInfoAction = createAsyncThunk(
  "PatientPersonalInfo/addOrUpdatePatientInfoAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientPersonalInfoServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSubscribedPatientByDoctorAction = createAsyncThunk(
  "PatientPersonalInfo/getAllSubscribedPatientByDoctorAction",
  async ({ finalData }, thunkAPI) => {
    try {
      return await PatientPersonalInfoServices.getAllSubscribedPatientByDoctor(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientPersonalInfoAction = createAsyncThunk(
  "PatientPersonalInfo/getAllPatientPersonalInfoAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientPersonalInfoServices.getAllPatientPersonalInfo(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientByDoctorAction = createAsyncThunk(
  "PatientPersonalInfo/getAllPatientByDoctorAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientPersonalInfoServices.getAllPatientByDoctor(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPrescriptionWithoutTokkenAction = createAsyncThunk(
  "PatientPersonalInfo/getPrescriptionWithoutTokkenAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientPersonalInfoServices.getPrescriptionWithoutTokken(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPrescriptionAction = createAsyncThunk(
  "PatientPersonalInfo/getPrescriptionAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientPersonalInfoServices.getPrescription(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPatientPersonalInfoByIdAction = createAsyncThunk(
  "PatientPersonalInfo/getPatientPersonalInfoByIdAction",
  async (id, thunkAPI) => {
    try {
      return await PatientPersonalInfoServices.getPatientPersonalInfoById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const PatientPersonalInfoSlice = createSlice({
  name: "PatientPersonalInfo",
  initialState,
  reducers: {
    clearPatientPersonalInfo: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdatePatientInfoAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdatePatientInfoAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdatePatientInfoAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPatientPersonalInfoAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientPersonalInfoAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientPersonalInfo = action.payload;
      })
      .addCase(getAllPatientPersonalInfoAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllSubscribedPatientByDoctorAction.pending, (state) => {
        state.SubscriberisLoading = true;
      })
      .addCase(
        getAllSubscribedPatientByDoctorAction.fulfilled,
        (state, action) => {
          state.SubscriberisLoading = false;
          state.isSuccess = true;
          state.getAllSubscribedPatientByDoctor = action.payload;
        }
      )
      .addCase(
        getAllSubscribedPatientByDoctorAction.rejected,
        (state, action) => {
          state.SubscriberisLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      )

      .addCase(getAllPatientByDoctorAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientByDoctorAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientByDoctor = action.payload;
      })
      .addCase(getAllPatientByDoctorAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getPrescriptionWithoutTokkenAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getPrescriptionWithoutTokkenAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.getPrescriptionWithoutTokken = action.payload;
        }
      )
      .addCase(getPrescriptionWithoutTokkenAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getPrescriptionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPrescriptionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getPrescription = action.payload;
      })
      .addCase(getPrescriptionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getPatientPersonalInfoByIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatientPersonalInfoByIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getPatientPersonalInfoById = action.payload;
      })
      .addCase(getPatientPersonalInfoByIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearPatientPersonalInfo } = PatientPersonalInfoSlice.actions;

export default PatientPersonalInfoSlice.reducer;
