import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AvatarCoordinateServices from "./avatar.services";

const initialState = {
  addOrUpdate: [],
  getAllAvatarCoordinate: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  isLoadingAppointment: false,
  message: "",
};

export const addOrUpdateAvatarAction = createAsyncThunk(
  "avatar/addOrUpdateAvatarAction",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await AvatarCoordinateServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        // if (Notificiation) {
        //   Notificiation(response.message, "success");
        // }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        // Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllAvatarCoordinateAction = createAsyncThunk(
  "avatar/getAllAvatarCoordinateAction",
  async (data, thunkAPI) => {
    try {
      return await AvatarCoordinateServices.getAllAvatarCoordinate(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {
    clearAvatar: (state) => {
      state.addOrUpdate = [];
      state.getAllAvatarCoordinate = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(addOrUpdateAvatarAction.pending, (state) => {
        state.isLoadingAppointment = true;
      })
      .addCase(addOrUpdateAvatarAction.fulfilled, (state, action) => {
        state.isLoadingAppointment = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAvatarAction.rejected, (state, action) => {
        state.isLoadingAppointment = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllAvatarCoordinateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAvatarCoordinateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllAvatarCoordinate = action.payload;
      })
      .addCase(getAllAvatarCoordinateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearAvatar } = avatarSlice.actions;

export default avatarSlice.reducer;
