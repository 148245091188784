import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientPhysicianServices from "./patientPhysician.services";

const initialState = {
  addOrUpdate: [],
  getAllPatPhysicians: [],
  deletePatientPhysician: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateAction = createAsyncThunk(
  "patientPhysician/addOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientPhysicianServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllPatPhysiciansAction = createAsyncThunk(
  "patientPhysician/getAllPatPhysiciansAction",
  async (thunkAPI) => {
    try {
      return await PatientPhysicianServices.getAllPatPhysicians();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePatientPhysicianAction = createAsyncThunk(
  "patientPhysician/deletePatientPhysicianAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientPhysicianServices.deletePatientPhysician(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const PatientPhysicianSlice = createSlice({
  name: "patientPhysician",
  initialState,
  reducers: {
    clearPatientPhysician: (state) => {
      state.addOrUpdate = [];
      state.getAllPatPhysicians = [];
      state.deletePatientPhysician = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPatPhysiciansAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatPhysiciansAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatPhysicians = action.payload;
      })
      .addCase(getAllPatPhysiciansAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deletePatientPhysicianAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatientPhysicianAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePatientPhysician = action.payload;
      })
      .addCase(deletePatientPhysicianAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearPatientPhysician } = PatientPhysicianSlice.actions;

export default PatientPhysicianSlice.reducer;
