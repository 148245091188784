import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/PatientLabResult/addOrUpdate`, data);
  return response.data;
};
const deletePatientLabResult = async (data) => {
  const response = await api.post(`api/PatientLabResult/delete`, data);
  return response.data;
};
const getAllPatientLabResult = async (userId) => {
  const response = await api.get(
    `api/PatientLabResult/getAllLabResult?userId=${userId}`
  );
  return response.data.data;
};

const PatientLabResultServices = {
  addOrUpdate,
  getAllPatientLabResult,
  deletePatientLabResult,
};

export default PatientLabResultServices;
