import React, { useEffect, useState } from "react";
import { IMAGES } from "../../assets/images";
import Dropdown from "react-bootstrap/Dropdown";
import { PATH, ROLEID, TOASTER_STYLING_VALUES } from "../../config";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { SignOut } from "./SignOut";
import { Notification } from "./Notification";
import ReactTooltip from "react-tooltip";
import { IoPersonSharp } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { switchUserAction } from "../../app/features/signUp/signup.slice";
import { useCookies } from "react-cookie";
import { getAllFamilyMemberAction } from "../../app/features/patient/familyMember/familyMember.slice";
import { TbSwitch3 } from "react-icons/tb";
import jwt_decode from "jwt-decode";

export function Header() {
  let auth = useAuth();
  let dispatch = useDispatch();
  let history = useNavigate();
  const [cookies, setCookies, remove] = useCookies("intely_health_user");
  document.body.style.backgroundColor = "#f5f5f5";

  let localStorageValue = window.localStorage.getItem("parentEmail");

  let location = useLocation();
  useEffect(() => {
    if (
      auth?.intely_health_user?.isParent === true ||
      auth?.intely_health_user?.isAdmin === true
    ) {
      window.localStorage.setItem(
        "parentEmail",
        auth?.intely_health_user?.email
      );
    }
  }, [auth]);

  useEffect(() => {
    let finalData = {
      userId: auth?.intely_health_user?.userId,
    };
    dispatch(getAllFamilyMemberAction(finalData));
  }, [dispatch, auth]);

  function SwitchUser(email) {
    if (localStorageValue === "") {
    } else {
      let finalData = {
        email: email,
      };
      dispatch(switchUserAction({ finalData, Notificiation, UpDateCookies }));
    }
  }

  function UpDateCookies(record) {
    remove("intely_health_user");
    setCookies("intely_health_user", record);
    history(PATH.PATIENTDASHBOARD);
  }

  function Notificiation(data, condition) {
    condition === "error"
      ? toast.error(data, TOASTER_STYLING_VALUES)
      : toast.success(data, TOASTER_STYLING_VALUES);
  }

  let { getAllFamilyMember } = useSelector((state) => state.familymember);

  useEffect(() => {
    const decodedToken = jwt_decode(auth?.intely_health_user?.token);
    if (!decodedToken.exp) {
      return null;
    }
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);

    if (expirationDate < new Date()) {
      console.error("Token has expired!");
      remove("intely_health_user", { path: "/" });
    } else {
    }
  }, [auth]);

  return (
    <>
      <ToastContainer />
      <div className="header">
        <div className="PageName">
          {auth?.intely_health_user?.roleId === ROLEID.DOCTOR && (
            <>
              {location.pathname === PATH.DOCTORDASHBOARD ? (
                <h3 className="ml-4">{`Dashboard`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ALLSUBSCRIBERVIEW ? (
                <h3 className="ml-4">{`All Subscriber`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.CALENDARVIEW ? (
                <h3 className="ml-4">{`Visit Slots Management`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.VIEWLISTFORVISITER ? (
                <h3 className="ml-4">{`View List`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.MESSAGE ? (
                <h3 className="ml-4">{`Conversation`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ALLVISITS ? (
                <h3 className="ml-4">{`All Visits`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.DOCTORPROFILE ? (
                <h3 className="ml-4">{`My Profile`}</h3>
              ) : (
                <></>
              )}
              {/* <>
                {location.pathname === PATH.PATIENTVIEW ? (
                  <h3 className="ml-4">{`Patient View`}</h3>
                ) : (
                  <></>
                )}
              </> */}
              {location.pathname === PATH.PATIENTVIEW ? (
                <h3 className="ml-4">{`Patient View`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.MYEARNING ? (
                <h3 className="ml-4">{`My Earnings`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.COMMUNITYFORM ? (
                <h3 className="ml-4">{`Community Forum`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.VIDEOCONSULTATION ? (
                <h3 className="ml-4">{` Consultation Screen`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.BLOGS ? (
                <h3 className="ml-4">{`Blogs`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.CHAT ? (
                <h3 className="ml-4">{`Secure Messages`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.PAYMENTINFO ? (
                <h3 className="ml-4">{`Payment Settings`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.PATIENTLISTING ? (
                <h3 className="ml-4">{`My Patients `}</h3>
              ) : (
                <></>
              )}
            </>
          )}
          {auth?.intely_health_user?.roleId === ROLEID.PATIENT && (
            <>
              {location.pathname === PATH.PATIENTDASHBOARD ? (
                <h3 className="ml-4">{`Dashboard`}</h3>
              ) : (
                <></>
              )}
              <>
                {location.pathname === PATH.FINDADOCTOR ? (
                  <h3 className="ml-4">{`Find a Physician`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.PATIENTOWNVITALS ? (
                  <h3 className="ml-4">{`Patient vitals`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.DOCTORPATIENTPROFILE ? (
                  <h3 className="ml-4">{`Physician Profile`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.SUBSCRIPTIONPLAN ? (
                  <h3 className="ml-4">{`Subscription Plan`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.MYPLANS ? (
                  <h3 className="ml-4">{`My Plans`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.PLANSHISTORY ? (
                  <h3 className="ml-4">{` Plan History`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.APPOINTMENTSLOTS ? (
                  <h3 className="ml-4">{`Physician's Slot`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.CHAT ? (
                  <h3 className="ml-4">{`Secure Messages`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.MESSAGE ? (
                  <h3 className="ml-4">{`Conversation`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.UPDATEFAMILYMEMBERS ? (
                  <h3 className="ml-4">{`Edit Family Member`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.PATIENTVIEW ? (
                  <h3 className="ml-4">{`Patient View`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.DOCTORSLOTS ? (
                  <h3 className="ml-4">{`Physician Slots`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.NOTES ? (
                  <h3 className="ml-4">{`Notes`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.PATIENTCHAT ? (
                  <h3 className="ml-4">{`Chat`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.VIDEOCONSULTATION ? (
                  <h3 className="ml-4">{`Tele-Consultation`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.MYAPPOINTMENTS ? (
                  <h3 className="ml-4">{`MY APPOINTMENTS`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.FAMILYMEMBERS ? (
                  <h3 className="ml-4">{`FAMILY MEMBERS`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.COMMUNITYFORUM ? (
                  <h3 className="ml-4">{`COMMUNITY FORUM`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.INDIVIDUALCOMMUNITYFORM ? (
                  <h3 className="ml-4">{`COMMUNITY FORUM`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.ADDFAMILYMEMBER ? (
                  <h3 className="ml-4">{`Add family member`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname === PATH.UPDATEFAMILYMEMBER ? (
                  <h3 className="ml-4">{`Update family member`}</h3>
                ) : (
                  <></>
                )}
              </>
              <>
                {location.pathname.includes("/doctor-detail/docId=") ? (
                  <h3 className="ml-4">{`Physician's Profile`}</h3>
                ) : (
                  <></>
                )}
              </>
            </>
          )}
          {auth?.intely_health_user?.roleId === ROLEID.ADMIN && (
            <>
              {location.pathname === PATH.ADMINDASHBOARD ? (
                <h3 className="ml-4">{`Dashboard`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ADMINAPPOINTMENTS ? (
                <h3 className="ml-4">{`Appointments`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ADMINROLESETTINGS ? (
                <h3 className="ml-4">{`Employee Role Settings`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ADMINCREATENEWROLE ? (
                <h3 className="ml-4">{`Role Management`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ADMINUPDATEANEMPLOYEE ? (
                <h3 className="ml-4">{`Edit Employee`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ADMINROLEPERMISSIONS ? (
                <h3 className="ml-4">{`Role Permission`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.EMPLOYEEMANAGEMENT ? (
                <h3 className="ml-4">{`Employee Management`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ADMINCREATEANEMPLOYEE ? (
                <h3 className="ml-4">{`Create an Employee`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.COMMUNITYFORUM ? (
                <h3 className="ml-4">{`Community Forum`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.INDIVIDUALCOMMUNITYFORM ? (
                <h3 className="ml-4">{`Community Forum`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.PHYSICIANMANAGEMENT ? (
                <h3 className="ml-4">{`Physician MANAGEMENT`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ADMINFEEDBACK ? (
                <h3 className="ml-4">{`FEEDBACK`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.DOCTORPROFILEPHYSICIAN ? (
                <h3 className="ml-4">{`Physician Profile`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.ADMINSUBSCRIPTIONPLAN ? (
                <h3 className="ml-4">{`Subscription Plans`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.SUBSCRIPTIONUSAGE ? (
                <h3 className="ml-4">{`Subscription Usage`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.COUPONMANAGEMENT ? (
                <h3 className="ml-4">{`COUPON MANAGEMENT`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.PAYMENTMANAGEMENT ? (
                <h3 className="ml-4">{`Payment Management`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.USERPATIENTMANAGEMENT ? (
                <h3 className="ml-4">{`Patient Management`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.PATIENTPROFILEMANAGEMENT ? (
                <h3 className="ml-4">{`Profile Management`}</h3>
              ) : (
                <></>
              )}

              {location.pathname === PATH.USERDOCTORMANAGEMENT ? (
                <h3 className="ml-4">{`Physician Management`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.PAYMENTSETTINGS ? (
                <h3 className="ml-4">{`Payment Settings`}</h3>
              ) : (
                <></>
              )}
              {location.pathname === PATH.PAYMENTREFUND ? (
                <h3 className="ml-4">{`Payments Refund`}</h3>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <ul className="nav user-menu">
          {/* <li className="nav-item dropdown noti-dropdown pr-0 notification_dropdown search-icon-alignment ">
            <form
              action="https://duckduckgo.com/"
              role="search"
              class="search-form"
            >
              <input type="submit" value="" class="search-submit" />
              <input
                type="search"
                name="q"
                class="search-text"
                placeholder="Search..."
                autocomplete="off"
              />
            </form>
          </li> */}
          <li className="nav-item dropdown noti-dropdown pr-0 notification_dropdown bell-icon-alignment">
            <Notification />
          </li>
          <li className="nav-item dropdown has-arrow d-flex pe-0 family-member-parent">
            <span className="user-img mt-0 d-flex justify-content-center">
              <img
                className="rounded-circle"
                src={
                  auth?.intely_health_user?.profilePicture !== null
                    ? auth?.intely_health_user?.profilePicture
                    : IMAGES.NOIMAGE
                }
                width="31"
                alt=""
              />
              <span className="image-side-text">
                <b>Welcome {auth?.intely_health_user?.name} </b>
                <br />
                {auth?.intely_health_user?.speacialization || ""}
                {/* Heart Surgeon */}
              </span>
            </span>

            <Dropdown className="user-dropdown  h-100">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="user_dropdown"
              >
                {/* <BsChevronDown className="text-dark ml-2" /> */}
              </Dropdown.Toggle>
              <Dropdown.Menu className="profile-dropmenu">
                {auth?.intely_health_user?.roleId === ROLEID.DOCTOR && (
                  <Dropdown.Item
                    href={PATH.DOCTORPROFILE}
                    className="no-padding"
                  >
                    <div className="logout-text ms-4">
                      <IoPersonSharp size={19} className="me-3" />
                      <p className="  mb-0 f-13">My Profile</p>
                    </div>
                  </Dropdown.Item>
                )}

                {auth?.intely_health_user?.roleId === ROLEID.PATIENT && (
                  <>
                    {/* {localStorageValue === null ? (
                      <></>
                    ) : ( */}
                    <>
                      {getAllFamilyMember.map((item, index) => {
                        return (
                          <Dropdown.Item
                            disabled={
                              auth?.intely_health_user?.isAdmin === false &&
                              auth?.intely_health_user?.isParent === false &&
                              localStorageValue === null
                            }
                            className={`no-padding ${
                              auth?.intely_health_user?.userId ===
                              item?.familyMemberId
                                ? "family-member-active"
                                : ""
                            }`}
                            onClick={() => SwitchUser(item?.email)}
                          >
                            <div className="logout-text ms-3">
                              <img
                                className="rounded-circle header-dropdown-image me-2"
                                src={
                                  auth?.intely_health_user?.profilePicture !==
                                  null
                                    ? item?.profilePicture
                                    : IMAGES.NOIMAGE
                                }
                                width="31"
                                alt="avatar"
                              />
                              <p className="  mb-0 f-13 mt-0">{item?.name}</p>
                              {auth?.intely_health_user?.userId ===
                              item?.familyMemberId ? (
                                <>
                                  <span
                                    className={`no-padding ${
                                      auth?.intely_health_user?.userId ===
                                      item?.familyMemberId
                                        ? "header-active-class"
                                        : ""
                                    }`}
                                  >
                                    (Active)
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </Dropdown.Item>
                        );
                      })}
                    </>
                    {/* )} */}
                  </>
                )}
                <Dropdown.Item className="d-block px-0">
                  <SignOut />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <ReactTooltip />
      </div>
    </>
  );
}
