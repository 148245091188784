import api from "../../../assets/genericComponents/API";

const getVonageToken = async (data) => {
  const response = await api.post(`api/Vonage/getVonageToken`, data);
  return response.data;
};
const getVonageSessionID = async () => {
  const response = await api.get(`api/Vonage/getVonageSessionID`);
  return response.data.data;
};

const VonageServices = {
  getVonageToken,
  getVonageSessionID,
};

export default VonageServices;
