import api from "../../../../assets/genericComponents/API";

const getDoctorDashboardData = async (data) => {
  const response = await api.get("api/Dashboard/getDoctorDashboardData", {
    params: data,
  });
  return response.data.data;
};

const getDoctorDashboardGraph = async (data) => {
  const response = await api.get("api/Dashboard/getDoctorDashboardGraph", {
    params: data,
  });
  return response.data.data;
};

const getAdminDashboardGraph = async (data) => {
  const response = await api.get("api/Dashboard/getAdminDashboardGraph", {
    params: data,
  });
  return response.data.data;
};

const getPatientDashboardData = async (data) => {
  const response = await api.get("api/Dashboard/getPatientDashboardData", {
    params: data,
  });
  return response.data.data;
};

const getAdminDashboardData = async () => {
  const response = await api.get("api/Dashboard/getAdminDashboardData", {});
  return response.data.data;
};

const getDoctorDashboardDataServices = {
  getDoctorDashboardData,
  getPatientDashboardData,
  getDoctorDashboardGraph,
  getAdminDashboardData,
  getAdminDashboardGraph,
};

export default getDoctorDashboardDataServices;
