import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LookUpServices from "./lookUp.services";

const initialState = {
  lookUpLanguage: [],
  lookUpCountries: [],
  lookUpStates: [],
  getAllMedicine: [],
  lookUpCities: [],
  getAllSpecialities: [],
  getAllSubSpecialities: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const lookupLanguageAction = createAsyncThunk(
  "lookUp/lookupLanguageAction",
  async (thunkAPI) => {
    try {
      return await LookUpServices.lookUpLanguages();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const lookupCountriesAction = createAsyncThunk(
  "lookUp/lookupCountriesAction",
  async (thunkAPI) => {
    try {
      return await LookUpServices.lookUpCountries();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllSpecialitiesAction = createAsyncThunk(
  "lookUp/getAllSpecialitiesAction",
  async (thunkAPI) => {
    try {
      return await LookUpServices.getAllSpecialities();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const lookUpStatesAction = createAsyncThunk(
  "lookUp/lookUpStatesAction",
  async (countryId, thunkAPI) => {
    try {
      return await LookUpServices.lookUpStates(countryId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const lookUpCitiesAction = createAsyncThunk(
  "lookUp/lookUpCitiesAction",
  async (stateId, thunkAPI) => {
    try {
      return await LookUpServices.lookUpCities(stateId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllMedicineAction = createAsyncThunk(
  "lookUp/getAllMedicineAction",
  async (search, thunkAPI) => {
    try {
      return await LookUpServices.getAllMedicine(search);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllSubSpecialitiesAction = createAsyncThunk(
  "lookUp/getAllSubSpecialitiesAction",
  async (subSpeciality, thunkAPI) => {
    try {
      return await LookUpServices.getAllSubSpecialities(subSpeciality);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const lookupSlice = createSlice({
  name: "lookUp",
  initialState,
  reducers: {
    clearlookUp: (state) => {
      state.lookUpLanguage = [];
      state.lookUpCountries = [];
      state.lookUpStates = [];
      state.getAllMedicine = [];
      state.getAllSpecialities = [];
      state.getAllSubSpecialities = [];
      state.lookUpCities = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(lookupLanguageAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lookupLanguageAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lookUpLanguage = action.payload;
      })
      .addCase(lookupLanguageAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(lookupCountriesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lookupCountriesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lookUpCountries = action.payload;
      })
      .addCase(lookupCountriesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(lookUpCitiesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lookUpCitiesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lookUpCities = action.payload;
      })
      .addCase(lookUpCitiesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(lookUpStatesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lookUpStatesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lookUpStates = action.payload;
      })
      .addCase(lookUpStatesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllSpecialitiesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSpecialitiesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllSpecialities = action.payload;
      })
      .addCase(getAllSpecialitiesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllMedicineAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMedicineAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllMedicine = action.payload;
      })
      .addCase(getAllMedicineAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllSubSpecialitiesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSubSpecialitiesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllSubSpecialities = action.payload;
      })
      .addCase(getAllSubSpecialitiesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearlookUp } = lookupSlice.actions;

export default lookupSlice.reducer;
