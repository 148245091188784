import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminManagementServices from "./createdoctor.services";

const initialState = {
  createDoctor: [],
  suspendedUser: [],
  getAllStaffForAdmin: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const createDoctorAction = createAsyncThunk(
  "adminManagement/createDoctorAction",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await AdminManagementServices.createDoctor(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllStaffForAdminAction = createAsyncThunk(
  "adminManagement/getAllStaffForAdminAction",
  async (finalData, thunkAPI) => {
    try {
      return await AdminManagementServices.getAllStaffForAdmin(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const suspendedUserAction = createAsyncThunk(
  "adminManagement/suspendedUserAction",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await AdminManagementServices.suspendedUser(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const AdminManagementSlice = createSlice({
  name: "adminManagement",
  initialState,
  reducers: {
    clearAdminManagement: (state) => {
      state.suspendedUser = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDoctorAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDoctorAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createDoctor = action.payload;
      })
      .addCase(createDoctorAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllStaffForAdminAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllStaffForAdminAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllStaffForAdmin = action.payload;
      })
      .addCase(getAllStaffForAdminAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(suspendedUserAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(suspendedUserAction.fulfilled, (state, action) => {
        // const subscribedList = state?.getAllStaffForAdmin?.data;
        // const updatedList = subscribedList?.map((item) => {
        //   if (item.userId === action.payload.data.userId) {
        //     return {
        //       ...item,
        //       isActive: !item.isActive,
        //     };
        //   }
        //   return item;
        // });

        state.isLoading = false;
        state.isSuccess = true;
        state.suspendedUser = action.payload;
        // state.getAllStaffForAdmin.data = updatedList;
      })
      // .addCase(suspendedUserAction.fulfilled, (state, action) => {
      //   let subscribedList = state?.getAllStaffForAdmin?.data;
      //   for (let i = 0; i < subscribedList?.length; i++) {
      //     if (subscribedList[i].userId === action.payload.data.userId) {
      //       subscribedList[i].isActive = !subscribedList[i].isActive;
      //     }
      //   }
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.suspendedUser = action.payload;
      //   state.getAllStaffForAdmin.data = subscribedList;
      // })
      .addCase(suspendedUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearAdminManagement } = AdminManagementSlice.actions;

export default AdminManagementSlice.reducer;
