import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IllnessHistoryServices from "./patientIllnessHistory.services";

const initialState = {
  addOrUpdate: [],
  getAllIllnessHistory: [],
  deleteIllnessHistory: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateAction = createAsyncThunk(
  "illnessHistory/addOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await IllnessHistoryServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllIllnessHistoryAction = createAsyncThunk(
  "illnessHistory/getAllIllnessHistoryAction",
  async (patientId, thunkAPI) => {
    try {
      return await IllnessHistoryServices.getAllIllnessHistory(patientId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteIllnessHistoryAction = createAsyncThunk(
  "illnessHistory/deleteIllnessHistory",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await IllnessHistoryServices.deleteIllnessHistory(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const IllnessHistorySlice = createSlice({
  name: "illnessHistory",
  initialState,
  reducers: {
    clearIllnessHistory: (state) => {
      state.addOrUpdate = [];
      state.getAllIllnessHistory = [];
      state.deleteIllnessHistory = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllIllnessHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllIllnessHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllIllnessHistory = action.payload;
      })
      .addCase(getAllIllnessHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteIllnessHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteIllnessHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteIllnessHistory = action.payload;
      })
      .addCase(deleteIllnessHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearIllnessHistory } = IllnessHistorySlice.actions;

export default IllnessHistorySlice.reducer;
