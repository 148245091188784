import axios from "axios";
import moment from "moment";
function getTodayDate() {
  var today = new Date();
  return today;
}
function getPreviousDate(date, interval) {
  const toDate = new Date(date);
  const fromDate = new Date(toDate);
  fromDate.setDate(fromDate.getDate() - interval);
  return fromDate;
}
function getCurrentTimeZone(dateTime) {
  let myZone = changeDatetimeByTimezone(dateTime);
  const today = new Date(
    new Date(myZone).toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    })
  );
  let date = today.toLocaleDateString();
  var month = today.getMonth();
  var date_ = today.getDate();
  var year = today.getFullYear();
  let time = today.toLocaleTimeString();
  return { date: `${month + 1}-${date_}-${year}`, time: time };
}
function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date);
  newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return newDate;
}

function getSimpleTimeIn12Format(date) {
  const timeString12hr = new Date(
    "1970-01-01T" + date + "Z"
  ).toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  return timeString12hr;
}

function getNewSlotCurrentTimeZone(dateTime) {
  let myZone = changeDatetimeByTimezone(dateTime);
  const today = new Date(
    new Date(myZone).toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    })
  );
  let date = today.toLocaleDateString();
  var month = today.getMonth();
  var date_ = today.getDate();
  var year = today.getFullYear();
  let time = today.toLocaleTimeString();
  return { date: `${year}-${month + 1}-${date_}`, time: time };
}
// function changeDatetimeByTimezone(datetime) {
//   var dateLocal = new Date(datetime);
//   var newDate = new Date(
//     dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
//   );
//   return newDate;
// }
function changeDatetimeByTimezone(datetime) {
  var dateLocal = new Date(datetime);
  var newDate = new Date(
    Date.UTC(
      dateLocal.getUTCFullYear(),
      dateLocal.getUTCMonth(),
      dateLocal.getUTCDate(),
      dateLocal.getUTCHours(),
      dateLocal.getUTCMinutes(),
      dateLocal.getUTCSeconds(),
      dateLocal.getUTCMilliseconds()
    ) -
      dateLocal.getTimezoneOffset() * 60 * 1000
  );
  return newDate;
}
function getTodayDateWithTimeFormat() {
  var currentDate = new Date();
  var month = currentDate.getMonth() + 1;
  var date_ = currentDate.getDate();
  var year = currentDate.getFullYear();
  if (month.toString().length < 2) {
    month = `0${month}`;
  }
  if (date_.toString().length < 2) {
    date_ = `0${date_}`;
  }
  return `${year}-${month}-${date_}T00:00`;
}
function getNextDate(date, interval) {
  const toDate = new Date();
  const fromDate = new Date(toDate);
  fromDate.setDate(fromDate.getDate() + interval);
  let final = {
    day: dayOfWeekAsString(fromDate.getDay()),
    date: getDateInFormat(fromDate),
  };
  return final;
}
function extractDatefromString(DateAndTime) {
  let date = DateAndTime.slice(0, 10);
  return date;
}
function monthOfYearAsString(monthIndex) {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ][monthIndex];
}
function dayOfWeekAsString(dayIndex) {
  return ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"][dayIndex];
}
function fullMonthOfYearAsString(monthIndex) {
  return [
    "January",
    "Feburary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][monthIndex];
}
function getDateInFormatFormControl(date) {
  return moment(date).format("YYYY-MM-DD");
}
function getTimeInFormatFormControl() {
  var today = new Date();
  var hour = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
  var minutes =
    today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
  var time = hour + ":" + minutes;
  return time;
}
function getDateInFormat(date) {
  if (date) {
    return moment(date).format("MM/DD");
  }
}
function getTimeInFormat(date) {
  return moment(date).format("LT");
}
function changeTimeZone(date) {
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      })
    );
  }

  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  );
}
function getDateAndTime(dateTime) {
  var formatDate = moment(dateTime).format();
  let value = dateDifference(formatDate);
  return `${moment(dateTime).format("LT")}, ${
    value === 0 ? "Today" : dayOfWeekAsString(new Date(dateTime).getDay())
  }`;
}

function getUpdateTime(time) {
  if (time) {
    var timeToChange = new Date(time);
    var hour =
      timeToChange.getHours() < 10
        ? "0" + timeToChange.getHours()
        : timeToChange.getHours();
    var minutes =
      timeToChange.getMinutes() < 10
        ? "0" + timeToChange.getMinutes()
        : timeToChange.getMinutes();
    var newTime = hour + ":" + minutes;
    return newTime;
  }
}
function getPageRecordIndex(PageNumber, index) {
  let pg = PageNumber;
  if (pg < 0) {
    pg *= 0;
  }
  let myCount = index + 1;
  let final = pg * 15 + myCount;
  return final;
}
function getZulluTimeFormat(time) {
  var timeToChange = time
    ? time.length > 5
      ? time.slice(0, 5).replace(":", "") + "Z"
      : time?.replace(":", "") + "Z"
    : "";
  return timeToChange;
}
function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
function getInitialName(name) {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;
  return initials.toUpperCase();
}
function createImageFromInitials(size, name, color) {
  if (name == null) return;
  name = getInitialName(name);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;
  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);
  context.fillStyle = `${color}50`;
  context.fillRect(0, 0, size, size);
  context.fillStyle = color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `${size / 2}px Roboto`;
  context.fillText(name, size / 2, size / 2);
  return canvas.toDataURL();
}
function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
function listOfNextYears(number) {
  let expYearArray = [];
  for (let i = 0; i <= number; i++) {
    expYearArray.push(new Date().getFullYear() + i);
  }
  return expYearArray;
}
function listOfMonths() {
  return [
    {
      id: 0,
      value: 1,
      month: "January",
    },
    {
      id: 1,
      value: 2,
      month: "Feburary",
    },
    {
      id: 2,
      value: 3,
      month: "March",
    },
    {
      id: 3,
      value: 4,
      month: "April",
    },
    {
      id: 4,
      value: 5,
      month: "May",
    },
    {
      id: 5,
      value: 6,
      month: "June",
    },
    {
      id: 6,
      value: 7,
      month: "July",
    },
    {
      id: 7,
      value: 8,
      month: "August",
    },
    {
      id: 8,
      value: 9,
      month: "September",
    },
    {
      id: 9,
      value: 10,
      month: "October",
    },
    {
      id: 10,
      value: 11,
      month: "November",
    },
    {
      id: 11,
      value: 12,
      month: "December",
    },
  ];
}
function expiryDateCheck(expiryDate) {
  var GivenDate = expiryDate;
  var CurrentDate = new Date();
  GivenDate = new Date(GivenDate);
  if (GivenDate > CurrentDate) {
    return true;
  } else {
    return false;
  }
}
function truncate(text, startChars, endChars, maxLength) {
  if (text && text.length > maxLength) {
    var start = text.substring(0, startChars);
    var end = text.substring(text.length - endChars, text.length);
    return start + " " + end;
  }
  return text;
}
function replaceSpace(text) {
  var i = 0,
    length = text.length;
  for (i; i < length; i++) {
    text = text.replace(" ", "-");
    text = text.replace(",", "");
  }
  return text;
}
function viewFile(url) {
  var url2;
  var fileExtension = url.substr(url.lastIndexOf(".") + 1);
  if (
    fileExtension === "png" ||
    fileExtension === "jpg" ||
    fileExtension === "jpeg"
  ) {
    var image = new Image();
    image.src = url;
    url2 = "https://docs.google.com/viewer?url=" + url;
    window.open(url2, "_blank");
  } else if (fileExtension === "pdf") {
    url2 = "https://docs.google.com/viewer?url=" + url;
    window.open(url2, "_blank");
  } else if (fileExtension === "doc" || fileExtension === "docx") {
    url2 = "https://docs.google.com/viewer?url=" + url;
    window.open(url2, "Image Viewer", "_blank");
  } else if (fileExtension === "ppt") {
    url2 = "https://docs.google.com/viewer?url=" + url;
    window.open(url2, "Image Viewer", "_blank");
  } else if (fileExtension === "xlsx" || fileExtension === "xls") {
    url2 = "https://docs.google.com/viewer?url=" + url;
    window.open(url2, "Image Viewer", "_blank");
  } else if (fileExtension === "txt") {
    url2 = "https://docs.google.com/viewer?url=" + url;
    window.open(url2, "Image Viewer", "_blank");
  }

  //window.open(url);
}
function getFileExtension(file) {
  let lastIndexOfDot = file.lastIndexOf(".");
  let ext = file.slice(lastIndexOfDot + 1, file.length);
  return ext;
}
function dateDifference(createdDate) {
  var date1 = new Date(createdDate);
  var date2 = new Date();
  var diffDays = date2.getDate() - date1.getDate();
  if (diffDays < 0) {
    diffDays = diffDays * -1;
  }
  return diffDays;
}

function getCountryTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
function allMonthList() {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
}
function alertDialog(msg, yesFunc, params) {
  return window.confirm(msg) && yesFunc(params);
}
function getOperatingSystem(window) {
  let operatingSystem = "Not known";
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows OS";
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "MacOS";
  }
  if (window.navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX OS";
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux OS";
  }

  return operatingSystem;
}

function getBrowser(window) {
  let currentBrowser = "Not known";
  if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
    currentBrowser = "Google Chrome";
  } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
    currentBrowser = "Mozilla Firefox";
  } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
    currentBrowser = "Internet Exployer";
  } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
    currentBrowser = "Edge";
  } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
    currentBrowser = "Safari";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "Opera";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "YaBrowser";
  } else {
  }

  return currentBrowser;
}
const getDataForIp = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  return res.data.IPv4;
};

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

tConvert("18:00:00");

function getFormatedTimeFromUtc(date) {
  return moment(date).format("DD-MM-YYYY");
}

// function formatInMintAndSec(time) {
//   if (time) {
//     const latestDate = new Date(time).toISOString().split("T")[1].split(":");

//     return latestDate[0] + ":" + latestDate[1];
//   }
// }

function formatInMintAndSec(time) {
  if (time) {
    const utcDate = new Date(time).toLocaleString("en-US", {
      timeZone: "UTC",
      dateStyle: "short",
      timeStyle: "short",
      hour12: false,
    });
    return utcDate.replace(",", "");
  }
}

function formatInISO(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    return date.toISOString().slice(0, -1);
  }
}
// function formatInISO(dateString) {
//   if (dateString) {
//     const date = new Date(dateString);
//     const year = date.getUTCFullYear();
//     const month = String(date.getUTCMonth() + 1).padStart(2, '0');
//     const day = String(date.getUTCDate()).padStart(2, '0');
//     const hours = String(date.getUTCHours()).padStart(2, '0');
//     const minutes = String(date.getUTCMinutes()).padStart(2, '0');
//     const seconds = String(date.getUTCSeconds()).padStart(2, '0');
//     const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

//     return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
//   }
// }

// function formatInISO(date) {
//   if (moment.isMoment(date)) {
//     return date.format("YYYY-MM-DDTHH:mm:ssZ");
//   }
// }

export {
  getFileExtension,
  tConvert,
  replaceSpace,
  formatInISO,
  viewFile,
  getDataForIp,
  getBrowser,
  getOperatingSystem,
  getFormatedTimeFromUtc,
  getTodayDate,
  getPreviousDate,
  getNextDate,
  monthOfYearAsString,
  convertUTCDateToLocalDate,
  getSimpleTimeIn12Format,
  dayOfWeekAsString,
  getInitialName,
  getDateInFormat,
  convertBase64,
  createImageFromInitials,
  getRandomColor,
  listOfNextYears,
  getDateInFormatFormControl,
  getTimeInFormatFormControl,
  listOfMonths,
  expiryDateCheck,
  getTodayDateWithTimeFormat,
  fullMonthOfYearAsString,
  dateDifference,
  getCountryTimeZone,
  allMonthList,
  extractDatefromString,
  getTimeInFormat,
  alertDialog,
  getUpdateTime,
  getNewSlotCurrentTimeZone,
  getPageRecordIndex,
  getZulluTimeFormat,
  truncate,
  getCurrentTimeZone,
  changeTimeZone,
  getDateAndTime,
  formatInMintAndSec,
};
