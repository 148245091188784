/*eslint-disable*/
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { WEB_PAGES } from "../components";
import { PATH } from "../config";
import PublicRoute from "./Auth/PublicRoute";
import PrivateRoute from "./Auth/PrivateRoute";
import { INITIAL_VALUE } from "./DataRouteConfig";
import { useAuth } from "./Auth/ProvideAuth";
export const RouterConfig = () => {
  const [pathNameList, setPathNameList] = useState(INITIAL_VALUE);
  let auth = useAuth();
  return (
    <div>
      <Routes>
        {pathNameList?.map((item, index) => (
          <React.Fragment>
            {item.route === "PublicRoute" ? (
              <Route
                path={item.path}
                element={<PublicRoute>{item.page}</PublicRoute>}
              ></Route>
            ) : (
              <Route
                path={item.path}
                element={<PrivateRoute>{item.page}</PrivateRoute>}
              ></Route>
            )}
          </React.Fragment>
        ))}
        <Route path={PATH.NOPAGE} element={<WEB_PAGES.NOPAGE />}></Route>
      </Routes>
    </div>
  );
};
