import api from "../../../../assets/genericComponents/API";

const getAllFeedBack = async (data) => {
  const response = await api.get("api/FeedBack/getAllFeedBack", {
    params: data,
  });
  return response.data.data;
};
const getAllFeedbackDoctor = async (data) => {
  const response = await api.get("api/Admin/getAllFeedbackDoctor", {
    params: data,
  });
  return response.data.data;
};

const addOrUpdateFeedback = async (data) => {
  const response = await api.post(`api/FeedBack/addOrUpdate`, data);
  return response.data;
};

const FeedbackServices = {
  getAllFeedBack,
  getAllFeedbackDoctor,
  addOrUpdateFeedback,
};

export default FeedbackServices;
