import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LicenseServices from "./license.services";

const initialState = {
  addOrUpdate: [],
  getAllLicense: [],
  deleteLicense: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateAction = createAsyncThunk(
  "license/addOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await LicenseServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllLicenseAction = createAsyncThunk(
  "license/getAllLicenseAction",
  async (userId, thunkAPI) => {
    try {
      return await LicenseServices.getAllLicense(userId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteLicenseAction = createAsyncThunk(
  "license/deleteLicenseAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await LicenseServices.deleteLicense(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const LicenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    clearLicense: (state) => {
      state.addOrUpdate = [];
      state.getAllLicense = [];
      state.deleteLicense = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllLicenseAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLicenseAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllLicense = action.payload;
      })
      .addCase(getAllLicenseAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteLicenseAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteLicenseAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteLicense = action.payload;
      })
      .addCase(deleteLicenseAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearLicense } = LicenseSlice.actions;

export default LicenseSlice.reducer;
