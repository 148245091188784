import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(
    `api/PatientAllergyHistory/addOrUpdate`,
    data
  );
  return response.data;
};
const deletePatientAllergyHistory = async (data) => {
  const response = await api.post(`api/PatientAllergyHistory/delete`, data);
  return response.data;
};
const getAllAllergyHistory = async (patientId) => {
  const response = await api.get(
    `api/PatientAllergyHistory/getAllAllergyHistory?patientId=${patientId}`
  );
  return response.data.data;
};

const PatientAllergyHistoryServices = {
  addOrUpdate,
  getAllAllergyHistory,
  deletePatientAllergyHistory,
};

export default PatientAllergyHistoryServices;
