import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SysRolesServices from "./sysroles.services";

const initialState = {
  getAllSysRoles: [],
  addOrUpdateSysRoles: [],
  saveRolePermission: [],
  getAllMenus: [],
  isError: false,
  isLoading: false,
  saveRolePermissionisLoading: false,
  addUpdateisLoading: false,
  isSuccess: false,
  message: "",
};

export const getAllSysRolesAction = createAsyncThunk(
  "sysRoles/getAllSysRolesAction",
  async (finalData, thunkAPI) => {
    try {
      return await SysRolesServices.getAllSysRoles(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllMenusAction = createAsyncThunk(
  "sysRoles/getAllMenusAction",
  async (finalData, thunkAPI) => {
    try {
      return await SysRolesServices.getAllMenus(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addOrUpdateSysRolesAction = createAsyncThunk(
  "sysRoles/addOrUpdateSysRolesAction",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await SysRolesServices.addOrUpdateSysRoles(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveRolePermissionAction = createAsyncThunk(
  "sysRoles/saveRolePermissionAction",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await SysRolesServices.saveRolePermission(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const SysRolesSlice = createSlice({
  name: "sysRoles",
  initialState,
  reducers: {
    clearSysRoles: (state) => {
      state.getAllSysRoles = [];
      state.getAllMenus = [];
      state.addOrUpdateSysRoles = [];
      state.saveRolePermission = [];
      state.isLoading = false;
      state.saveRolePermissionisLoading = false;
      state.addUpdateisLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllSysRolesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSysRolesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllSysRoles = action.payload;
      })
      .addCase(getAllSysRolesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllMenusAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMenusAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllMenus = action.payload;
      })
      .addCase(getAllMenusAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(saveRolePermissionAction.pending, (state) => {
        state.saveRolePermissionisLoading = true;
      })
      .addCase(saveRolePermissionAction.fulfilled, (state, action) => {
        state.saveRolePermissionisLoading = false;
        state.isSuccess = true;
        state.saveRolePermission = action.payload;
      })
      .addCase(saveRolePermissionAction.rejected, (state, action) => {
        state.saveRolePermissionisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(addOrUpdateSysRolesAction.pending, (state) => {
        state.addUpdateisLoading = true;
      })
      .addCase(addOrUpdateSysRolesAction.fulfilled, (state, action) => {
        state.addUpdateisLoading = false;
        state.isSuccess = true;
        state.addOrUpdateSysRoles = action.payload;
      })
      .addCase(addOrUpdateSysRolesAction.rejected, (state, action) => {
        state.addUpdateisLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearSysRoles } = SysRolesSlice.actions;

export default SysRolesSlice.reducer;
