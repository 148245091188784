import api from "../../../../assets/genericComponents/API";

const communityFormaddOrUpdate = async (data) => {
  const response = await api.post(`api/CommunityForum/addOrUpdate`, data);
  return response.data;
};

const saveLike = async (data) => {
  const response = await api.post(`api/CommunityForum/saveLike`, data);
  return response.data;
};

const publishedForm = async (data) => {
  const response = await api.post(`api/CommunityForum/publishedForm`, data);
  return response.data;
};

const saveIndividualLike = async (data) => {
  const response = await api.post(`api/CommunityForum/saveLike`, data);
  return response.data;
};

const deleteArticle = async (data) => {
  const response = await api.post(`api/CommunityForum/deleteArticle`, data);
  return response.data;
};

const saveComment = async (data) => {
  const response = await api.post(`api/CommunityForum/saveComment`, data);
  return response.data;
};

const subscribedDoctor = async (data) => {
  const response = await api.post(
    `api/CommunityForum/subscribedDoctorWeb`,
    data
  );
  return response.data;
};

const subscribedIndividualDoctor = async (data) => {
  const response = await api.post(
    `api/CommunityForum/subscribedDoctorWeb`,
    data
  );
  return response.data;
};

const blockedArticle = async (data) => {
  const response = await api.post(
    `api/CommunityForum/blockedArticle`,
    data
  );
  return response.data;
};

const getAllCommunityForm = async (data) => {
  const response = await api.get("api/CommunityForum/getAllCommunityForm", {
    params: data,
  });
  return response.data;
};

const getAllCommunityFormByDoctor = async (data) => {
  const response = await api.get(
    "api/CommunityForum/getAllCommunityFormByDoctor",
    {
      params: data,
    }
  );
  return response.data;
};

const getAllCommunitydoctorFormByDoctor = async (data) => {
  const response = await api.get(
    "api/CommunityForum/getAllCommunityFormByDoctor",
    {
      params: data,
    }
  );
  return response.data.data;
};

const getCommunityFormById = async (data) => {
  const response = await api.get("api/CommunityForum/getCommunityFormById", {
    params: data,
  });
  return response.data;
};

const getAllComments = async (data) => {
  const response = await api.get("api/CommunityForum/getAllComments", {
    params: data,
  });
  return response.data.data;
};

const getAllFeedbackDoctor = async (data) => {
  const response = await api.get("api/Admin/getAllFeedbackDoctor", {
    params: data,
  });
  return response.data.data;
};

const CommunityFormServices = {
  communityFormaddOrUpdate,
  getAllFeedbackDoctor,
  getAllCommunityForm,
  getAllCommunityFormByDoctor,
  publishedForm,
  getAllCommunitydoctorFormByDoctor,
  subscribedIndividualDoctor,
  getAllComments,
  blockedArticle,
  deleteArticle,
  getCommunityFormById,
  saveComment,
  subscribedDoctor,
  saveIndividualLike,
  saveLike,
};

export default CommunityFormServices;
