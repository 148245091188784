import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/styles/style.css";
import "./assets/styles/haris.css";
import "./assets/styles/mahid.css";
import "./assets/styles/asaad.css";
import "./assets/styles/components-style.css";
import "./assets/js/jquery.slimscroll";
// import "react-tooltip/dist/react-tooltip.css";
import "./App.css";
import "./assets/js/app.js";
import "./assets/js/common.js";
// import "./sigCanvas.css";
import "./loader.js";
import ".././src/assets/styles/main.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-credit-cards/es/styles-compiled.css";
import ".././src/assets/styles/muzamal.css";
import ".././src/assets/styles/loader.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/mediaquery.css";
import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/antd.css";

import store from "./app/store";
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}
ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
