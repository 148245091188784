import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/Reference/addOrUpdate`, data);
  return response.data;
};
const deleteReference = async (data) => {
  const response = await api.post(`api/Reference/delete`, data);
  return response.data;
};
const getAllReferences = async (userId) => {
  const response = await api.get(
    `api/Reference/getAllReferences?userId=${userId}`
  );
  return response.data.data;
};

const ReferenceServices = {
  addOrUpdate,
  getAllReferences,
  deleteReference,
};

export default ReferenceServices;
