import React, { Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./Navigation/RouterConfig";
import { ProvideAuth } from "./Navigation/Auth/ProvideAuth";
import { LoaderPageWithoutBG } from "./assets";
import { ToastContainer, toast } from "react-toastify";
import { Toast } from "react-bootstrap";
import { onMessageListener } from "./firebase";
import { TOASTER_STYLING_VALUES } from "./config";

function App() {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [show, setShow] = useState(false);

  onMessageListener()
    .then((payload) => {
      console.log("payload notification", payload);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setShow(true);
      console.log(payload);
      Notificiation(payload.notification.title, payload.notification.body);
    })
    .catch((err) => console.log("failed: ", err));

  function Notificiation(title, body, condition) {
    toast.success(`${title}: ${body}`, TOASTER_STYLING_VALUES);
  }
  return (
    <>
      {/* toast.success(data, TOASTER_STYLING_VALUES); */}
      {/* <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={100000}
        autohide
        animation
        className="rpos-push-notification"
      >
        <Toast.Header className="rpos-notification-header">
          <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
          <strong className="mr-auto">
            {notification.title || "Dummy tittle"}
          </strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>{notification.body || "Dummy description"}</Toast.Body>
      </Toast> */}
      <Suspense fallback={<LoaderPageWithoutBG />}>
        <ToastContainer />
        <ProvideAuth>
          <BrowserRouter>
            <RouterConfig />
          </BrowserRouter>
        </ProvideAuth>
      </Suspense>
    </>
  );
}

export default App;
