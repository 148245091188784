import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EmployeeManagementServices from "./employeemanagement.services";

const initialState = {
  createEmployee: [],
  getAllStaff: [],
  getStaffById: [],
  deleteEmployee: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  deleteisLoading: false,
  message: "",
};

export const createEmployeeAction = createAsyncThunk(
  "employeeManagement/createEmployeeAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await EmployeeManagementServices.createEmployee(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllStaffAction = createAsyncThunk(
  "employeeManagement/getAllStaffAction",
  async (finalData, thunkAPI) => {
    try {
      return await EmployeeManagementServices.getAllStaff(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteEmployeeAction = createAsyncThunk(
  "employeeManagement/deleteEmployeeAction",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await EmployeeManagementServices.deleteEmployee(
        finalData.id
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStaffByIdAction = createAsyncThunk(
  "employeeManagement/getStaffByIdAction",
  async (userId, thunkAPI) => {
    try {
      return await EmployeeManagementServices.getStaffById(userId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const suspendedUserAction = createAsyncThunk(
//   "adminManagement/suspendedUserAction",
//   async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
//     try {
//       const response = await AdminManagementServices.suspendedUser(finalData);
//       if (response.succeeded === true) {
//         if (Notificiation) {
//           Notificiation(response.message, "success");
//         }
//         if (moveToNext) {
//           moveToNext();
//         }
//       } else {
//         Notificiation(response.message, "error");
//       }
//       return response;
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );
export const EmployeeManagementSlice = createSlice({
  name: "employeeManagement",
  initialState,
  reducers: {
    clearEmployeeManagement: (state) => {
      state.getAllStaff = [];
      state.getStaffById = [];
      state.createEmployee = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllStaffAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllStaffAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllStaff = action.payload;
      })
      .addCase(getAllStaffAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getStaffByIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStaffByIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getStaffById = action.payload;
      })
      .addCase(getStaffByIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(createEmployeeAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEmployeeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createEmployee = action.payload;
      })
      .addCase(createEmployeeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteEmployeeAction.pending, (state) => {
        state.deleteisLoading = true;
      })
      .addCase(deleteEmployeeAction.fulfilled, (state, action) => {
        state.deleteisLoading = false;
        state.isSuccess = true;
        state.deleteEmployee = action.payload;
      })
      .addCase(deleteEmployeeAction.rejected, (state, action) => {
        state.deleteisLoading = false;
        state.isError = true;
        state.message = action.payload;
      });

    // .addCase(suspendedUserAction.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(suspendedUserAction.fulfilled, (state, action) => {
    //   let staffList = state.getAllStaff;
    //   console.log("staffList", staffList);
    //   console.log("action.payload.data.staffId", action.payload.data.staffId);
    //   // for (let i = 0; i < subscribedList.length; i++) {
    //   //   if (subscribedList[i].doctorId === action.payload.data.staffId) {
    //   //     subscribedList[i].isDoctorSubscribed =
    //   //       !subscribedList[i].isDoctorSubscribed;
    //   //   }
    //   // }
    //   state.isLoading = false;
    //   state.isSuccess = true;
    //   state.suspendedUser = action.payload;
    // })
    // .addCase(suspendedUserAction.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.isError = true;
    //   state.message = action.payload;
    // });
  },
});

export const { clearAdminManagement } = EmployeeManagementSlice.actions;

export default EmployeeManagementSlice.reducer;
