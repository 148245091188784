import api from "../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/SecureChat/addOrUpdate`, data);
  return response.data;
};

const getAllReceivedMails = async (data) => {
  const response = await api.get(
    `api/SecureChat/getAllReceivedMails?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&search=${data.search}&userId=${data.userId}`
  );
  return response.data;
};

const getAllSendMails = async (data) => {
  const response = await api.get(
    `api/SecureChat/getAllSendMails?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&search=${data.search}&userId=${data.userId}`
  );
  return response.data;
};

const getAllDoctorEmail = async (data) => {
  const response = await api.get(
    `api/SecureChat/getAllDoctorEmail?searchByEmail=${data.email}`
  );
  return response.data;
};

const getAllPatientEmail = async (data) => {
  const response = await api.get(
    `api/SecureChat/getAllPatientEmail?searchByEmail=${data.email}`
  );
  return response.data;
};

const patientAllEmail = {
  addOrUpdate,
  getAllReceivedMails,
  getAllSendMails,
  getAllDoctorEmail,
  getAllPatientEmail,
};

export default patientAllEmail;
