import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/EducationDetail/addOrUpdate`, data);
  return response.data;
};
const deleteEducationDetail = async (data) => {
  const response = await api.post(`api/EducationDetail/delete`, data);
  return response.data;
};
const getAllEducationDetail = async (userId) => {
  const response = await api.get(
    `api/EducationDetail/getAllEducationDetail?userId=${userId}`
  );
  return response.data.data;
};

const EducationDetailServices = {
  addOrUpdate,
  getAllEducationDetail,
  deleteEducationDetail,
};

export default EducationDetailServices;
