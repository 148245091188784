import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(
    `api/PatientEmergencyContact/addOrUpdate`,
    data
  );
  return response.data;
};
const deletePatientEmergencyContact = async (data) => {
  const response = await api.post(`api/PatientEmergencyContact/delete`, data);
  return response.data;
};
const getAllEmergencyContact = async (data) => {
  const response = await api.get(
    `api/PatientEmergencyContact/getAllEmergencyContact?userId=${data.userId}`
  );
  return response.data.data;
};

const PatientEmergencyContactServices = {
  addOrUpdate,
  getAllEmergencyContact,
  deletePatientEmergencyContact,
};

export default PatientEmergencyContactServices;
