import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function MyDataTable(props) {

  return (
    <>
      {props.data && props.data.length > 0 ? (
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={props.data}
          columns={props.columns}
          columnToggle
          search
        >
          {(props) => (
            <div>
              <div className="d-flex justify-content-between pt-3 pb-4 mt-0">
                {/* <SearchBar
                  {...props.searchProps}
                  style={{ width: "250px", height: "40px" }}
                /> */}
              </div>
              <BootstrapTable
                wrapperClasses="table-responsive border-less"
                {...props.baseProps}
                // filter={filterFactory()}
                pagination={paginationFactory()}
                defaultSorted={props.defaultSorted}
                onDataSizeChange={props.handleDataChange}
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <p className="text-center">No Record Found</p>
      )}
    </>
  );
}
export function MyDataTableForPatient(props) {

  return (
    <>
      {props.data && props.data.length > 0 ? (
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={props.data}
          columns={props.columns}
          columnToggle
          search
        >
          {(props) => (
            <div>
              <div className="d-flex justify-content-between pt-3 pb-4 mt-0">
                {/* <SearchBar
                  {...props.searchProps}
                  style={{ width: "250px", height: "40px" }}
                /> */}
              </div>
              <BootstrapTable
                wrapperClasses="table-responsive border patient-parent-class"
                {...props.baseProps}
                // filter={filterFactory()}
                // pagination={paginationFactory()}
                defaultSorted={props.defaultSorted}
                onDataSizeChange={props.handleDataChange}
                // hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <p className="text-center">No Record Found</p>
      )}
    </>
  );
}
export function MyDataTableForInternalPages(props) {
  return (
    <>
      {props.data && props.data.length > 0 ? (
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={props.data}
          columns={props.columns}
          columnToggle
          search
        >
          {(props) => (
            <div>
              <div className="d-flex justify-content-between pt-3 pb-4 mt-0">
                {/* <SearchBar
                  {...props.searchProps}
                  style={{ width: "250px", height: "40px" }}
                /> */}
              </div>
              <BootstrapTable
                wrapperClasses="table-responsive table-striped all-visits-parent-class"
                {...props.baseProps}
                // filter={filterFactory()}
                pagination={paginationFactory()}
                defaultSorted={props.defaultSorted}
                onDataSizeChange={props.handleDataChange}
                // hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <p className="text-center">No Record Found</p>
      )}
    </>
  );
}
