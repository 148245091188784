import api from "../../../../assets/genericComponents/API";

const getFamilyMemberById = async (data) => {
  const response = await api.get("api/FamilyMember/getFamilyMemberById", {
    params: data,
  });
  return response.data.data;
};
const remainingFamilyMemberStatus = async (data) => {
  const response = await api.get(
    "api/FamilyMember/remainingFamilyMemberStatus",
    {
      params: data,
    }
  );
  return response.data.data;
};
const getAllFamilyMember = async (data) => {
  const response = await api.get("api/FamilyMember/getAllFamilyMember", {
    params: data,
  });
  return response.data.data;
};

const addOrUpdateFamilyMember = async (data) => {
  const response = await api.post(`api/FamilyMember/addOrUpdate`, data);
  return response.data;
};

const changeFamilyMemberRole = async (data) => {
  const response = await api.post(
    `api/FamilyMember/changeFamilyMemberRole`,
    data
  );
  return response.data;
};

const FamilyMemberServices = {
  getFamilyMemberById,
  changeFamilyMemberRole,
  remainingFamilyMemberStatus,
  addOrUpdateFamilyMember,
  getAllFamilyMember,
};

export default FamilyMemberServices;
