import { PATH } from "../config";
import { WEB_PAGES } from "../components";

let PathName = Object.keys(PATH, "path");
PathName.splice(0, 1);

export const INITIAL_VALUE = PathName.map((item) => {
  let Val = WEB_PAGES[item];

  if (
    item === "LOGIN" ||
    item === "SIGNUP" ||
    item === "PATIENTSIGNUP" ||
    item === "LANDINGSUBSCRIPTION" ||
    item === "LANDINGAVATR" ||
    item === "LANDINGNOTES" ||
    item === "LANDINGEPRESCRIPTION" ||
    item === "LANDINGLABTEST" ||
    item === "LANDINGCOMMUNITYFORM" ||
    item === "FORGOTPASSWORD" ||
    item === "PRIVACYPOLICY"
  ) {
    return { path: PATH[item], page: <Val />, route: "PublicRoute" };
  }
  return { path: PATH[item], page: <Val />, route: "PrivateRoute" };
});
