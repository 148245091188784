import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/PatientSocialHistory/addOrUpdate`, data);
  return response.data;
};
const deletegetAllSocialHistory = async (data) => {
  const response = await api.post(`api/PatientSocialHistory/delete`, data);
  return response.data;
};
const getAllSocialHistory = async (patientId) => {
  const response = await api.get(
    `api/PatientSocialHistory/getAllSocialHistory?patientId=${patientId}`
  );
  return response.data.data;
};

const SocialHistoryServices = {
  addOrUpdate,
  getAllSocialHistory,
  deletegetAllSocialHistory,
};

export default SocialHistoryServices;
