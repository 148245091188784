import api from "../../../../assets/genericComponents/API";

const getAllDoctors = async (data) => {
  const response = await api.get(
    `api/GeneralInformation/getAllDoctors?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&search=${data.search}&languageId=${data.languageId}&specialityId=${data.specialityId}&genderId=${data.genderId}&countryId=${data.countryId}&stateId=${data.stateId}&isApproved=${data.isApproved}&myDoctors=${data.myDoctors}`
  );
  return response.data;
};

const getAllPendingDoctors = async (data) => {
  const response = await api.get(
    `api/GeneralInformation/getAllDoctors?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&search=${data.search}&languageId=${data.languageId}&specialityId=${data.specialityId}&genderId=${data.genderId}&countryId=${data.countryId}&stateId=${data.stateId}&isApproved=${data.isApproved}`
  );
  return response.data;
};

const getAllDeniedDoctors = async (data) => {
  const response = await api.get(
    `api/GeneralInformation/getAllDoctors?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&search=${data.search}&languageId=${data.languageId}&specialityId=${data.specialityId}&genderId=${data.genderId}&countryId=${data.countryId}&stateId=${data.stateId}&isApproved=${data.isApproved}`
  );
  return response.data;
};

const addOrUpdate = async (data) => {
  const response = await api.post(`api/AppointmentBooking/addOrUpdate`, data);
  return response.data;
};

const followUpReason = async (data) => {
  const response = await api.post(
    `api/AppointmentBooking/followUpReason`,
    data
  );
  return response.data;
};

const getFollowUpReasonByAppointmentId = async (data) => {
  const response = await api.get(
    "api/AppointmentBooking/getFollowUpReasonByAppointmentId",
    {
      params: data,
    }
  );
  return response.data;
};

const generateAppointmentId = async (data) => {
  const response = await api.get(
    "api/AppointmentBooking/generateAppointmentId",
    {
      params: data,
    }
  );
  return response.data;
};

const changeAppointmentStatus = async (data) => {
  const response = await api.post(
    `api/AppointmentBooking/changeAppointmentStatus`,
    data
  );
  return response.data;
};

const deleteAppointment = async (data) => {
  const response = await api.post(
    `api/AppointmentBooking/deleteAppointment`,
    data
  );
  return response.data;
};

const getAllAppointment = async (data) => {
  const response = await api.get("api/AppointmentBooking/getAllAppointment", {
    params: data,
  });
  return response.data;
};

const patientAllVisits = async (data) => {
  const response = await api.get("api/AppointmentBooking/patientAllVisits", {
    params: data,
  });
  return response.data;
};

const remainingAppointmentStatus = async () => {
  const response = await api.get(
    `api/AppointmentBooking/remainingAppointmentStatus`
  );
  return response.data.data;
};

const SearchaDoctorServices = {
  getAllDoctors,
  getAllPendingDoctors,
  getFollowUpReasonByAppointmentId,
  changeAppointmentStatus,
  remainingAppointmentStatus,
  deleteAppointment,
  generateAppointmentId,
  followUpReason,
  patientAllVisits,
  getAllAppointment,
  getAllDeniedDoctors,
  addOrUpdate,
};

export default SearchaDoctorServices;
