import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GeneralInformationServices from "./generalInformation.services";

const initialState = {
  updateGeneralInfomartion: [],
  getGeneralInformationById: [],
  updateDoctorSignature: [],
  getDoctorSignatureById: [],
  isError: false,
  isSuccess: false,
  getGeneralInformationByIdisSuccess: false,
  updateisSuccess: false,
  isLoading: false,
  message: "",
};

export const updateGeneralInfomartionAction = createAsyncThunk(
  "GeneralInfomartion/updateGeneralInfomartionAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response =
        await GeneralInformationServices.updateGeneralInfomartion(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateDoctorSignatureAction = createAsyncThunk(
  "GeneralInfomartion/updateDoctorSignatureAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await GeneralInformationServices.updateDoctorSignature(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getGeneralInformationByIdAction = createAsyncThunk(
  "GeneralInfomartion/getGeneralInformationByIdAction",
  async (userId, thunkAPI) => {
    try {
      return await GeneralInformationServices.getGeneralInformationById(userId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDoctorSignatureByIdAction = createAsyncThunk(
  "GeneralInfomartion/getDoctorSignatureByIdAction",
  async (userId, thunkAPI) => {
    try {
      return await GeneralInformationServices.getDoctorSignatureById(userId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const generalInfromationSlice = createSlice({
  name: "GeneralInfomartion",
  initialState,
  reducers: {
    clearGeneralInfromation: (state) => {
      state.updateGeneralInfomartion = [];
      state.getGeneralInformationById = [];
      state.updateDoctorSignature = [];
      state.getDoctorSignatureById = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateGeneralInfomartionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGeneralInfomartionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateisSuccess = true;
        state.updateGeneralInfomartion = action.payload;
      })
      .addCase(updateGeneralInfomartionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getGeneralInformationByIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGeneralInformationByIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getGeneralInformationByIdisSuccess = true;
        state.getGeneralInformationById = action.payload;
      })
      .addCase(getGeneralInformationByIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getDoctorSignatureByIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDoctorSignatureByIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getGeneralInformationByIdisSuccess = true;
        state.getDoctorSignatureById = action.payload;
      })
      .addCase(getDoctorSignatureByIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateDoctorSignatureAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDoctorSignatureAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateDoctorSignature = action.payload;
      })
      .addCase(updateDoctorSignatureAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearGeneralInfromation } = generalInfromationSlice.actions;

export default generalInfromationSlice.reducer;
