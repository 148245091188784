import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FamilyMemberServices from "./familyMember.services";

const initialState = {
  getAllFamilyMember: [],
  addOrUpdateFamilyMember: [],
  getFamilyMemberById: [],
  changeFamilyMemberRole: [],
  remainingFamilyMemberStatus: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const addOrUpdateFamilyMemberAction = createAsyncThunk(
  "familyMember/addOrUpdateFamilyMemberAction",
  async ({ finalData, Notificiation, MoveToNext }, thunkAPI) => {
    try {
      const response = await FamilyMemberServices.addOrUpdateFamilyMember(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (MoveToNext) {
          MoveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const changeFamilyMemberRoleAction = createAsyncThunk(
  "familyMember/changeFamilyMemberRoleAction",
  async ({ finalData, Notificiation, MoveToNext }, thunkAPI) => {
    try {
      const response = await FamilyMemberServices.changeFamilyMemberRole(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (MoveToNext) {
          MoveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllFamilyMemberAction = createAsyncThunk(
  "familyMember/getAllFamilyMemberAction",
  async (data, thunkAPI) => {
    try {
      return await FamilyMemberServices.getAllFamilyMember(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getFamilyMemberByIdAction = createAsyncThunk(
  "familyMember/getFamilyMemberByIdAction",
  async (data, thunkAPI) => {
    try {
      return await FamilyMemberServices.getFamilyMemberById(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const remainingFamilyMemberStatusAction = createAsyncThunk(
  "familyMember/remainingFamilyMemberStatusAction",
  async (data, thunkAPI) => {
    try {
      return await FamilyMemberServices.remainingFamilyMemberStatus(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const FamilyMemberSlice = createSlice({
  name: "familyMember",
  initialState,
  reducers: {
    clearFamilyMember: (state) => {
      state.getAllFamilyMember = [];
      state.addOrUpdateFamilyMember = [];
      state.getFamilyMemberById = [];
      state.changeFamilyMemberRole = [];
      state.remainingFamilyMemberStatus = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateFamilyMemberAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateFamilyMemberAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdateFamilyMember = action.payload;
      })
      .addCase(addOrUpdateFamilyMemberAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getFamilyMemberByIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFamilyMemberByIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getFamilyMemberById = action.payload;
      })
      .addCase(getFamilyMemberByIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(changeFamilyMemberRoleAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeFamilyMemberRoleAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.changeFamilyMemberRole = action.payload;
      })
      .addCase(changeFamilyMemberRoleAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(remainingFamilyMemberStatusAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(remainingFamilyMemberStatusAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.remainingFamilyMemberStatus = action.payload;
      })
      .addCase(remainingFamilyMemberStatusAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllFamilyMemberAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFamilyMemberAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllFamilyMember = action.payload;
      })
      .addCase(getAllFamilyMemberAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearFamilyMember } = FamilyMemberSlice.actions;

export default FamilyMemberSlice.reducer;
