import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(
    `api/PatientSurgeryHistory/addOrUpdate`,
    data
  );
  return response.data;
};
const deletePatientSurgeryHistory = async (data) => {
  const response = await api.post(`api/PatientSurgeryHistory/delete`, data);
  return response.data;
};
const getAllSurgeryHistory = async (patientId) => {
  const response = await api.get(
    `api/PatientSurgeryHistory/getAllSurgeryHistory?patientId=${patientId}`
  );
  return response.data.data;
};

const PatientSurgeryHistoryServices = {
  addOrUpdate,
  getAllSurgeryHistory,
  deletePatientSurgeryHistory,
};

export default PatientSurgeryHistoryServices;
