import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";
export function PaginationComponent(props) {
  const [active, setActive] = useState(props && props.page ? props.page : 0);
  const [pageSizeRange, setPageSizeRange] = useState(
    props && props.pageSize ? props.pageSize : 10
  );
  const [specialitytype, setSpecialitytype] = React.useState(null);
  const handlePageClick = (data) => {
    let selected = data.selected;
    props.pageChange(selected + 1);
    setActive(selected);
  };
  const SPECIALITY_TYPE_DATA = [
    {
      lookupId: 2,
      text: "10",
      value: "10",
      order: 2,
    },
    {
      lookupId: 3,
      text: "20",
      value: "20",
      order: 3,
    },
    {
      lookupId: 4,
      text: "30",
      value: "30",
      order: 4,
    },
  ];
  return (
    <div className="pagination-modify">
      <div className=" pagination-padding-parent-class pagination">
        <div></div>
        <ReactPaginate
          previousLabel={"< Previous"}
          nextLabel={"Next   > "}
          breakLabel={"..."}
          forcePage={active}
          // breakClassName={'break-me'}
          breakClassName="page-item"
          pageCount={props.totalNumber}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}
