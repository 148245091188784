import api from "../../../../assets/genericComponents/API";

const getAllAppointmentAdmin = async (data) => {
  const response = await api.get("api/Admin/getAllAppointmentAdmin", {
    params: data,
  });
  return response.data;
};

const AdminAppointmentServices = {
  getAllAppointmentAdmin,
};

export default AdminAppointmentServices;
