import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { IMAGES } from "..";
import {
  SidebarDataForAdmin,
  SidebarDataForDoctor,
  SidebarDataForPatient,
  SidebarDataForStaff,
} from "./SideBarData";
import SubMenu from "./SubMenu";
import { PATH, ROLEID } from "../../config";
import "../js/jquery.slimscroll";
import $ from "jquery";
import { getAllMenusAction } from "../../app/features/admin/sysRoles/sysroles.slice";
import { useDispatch, useSelector } from "react-redux";
export default function Sidebar() {
  let auth = useAuth();
  let dispatch = useDispatch();
  const [filterSideBarData, setFilterSideBarData] = useState();
  useEffect(() => {
    let $slimScrolls = $(".slimscroll");

    if ($slimScrolls.length > 0) {
      $slimScrolls.slimScroll({
        height: "auto",
        width: "100%",
        position: "right",
        size: "7px",
        color: "#ccc",
        wheelStep: 10,
        touchScrollStep: 100,
      });
      var wHeight = $(window).height() - 60;
      $slimScrolls.height(wHeight);
      $(".sidebar .slimScrollDiv").height(wHeight);
      $(window).resize(function () {
        var rHeight = $(window).height() - 60;
        $slimScrolls.height(rHeight);
        $(".sidebar .slimScrollDiv").height(rHeight);
      });
    }

    function init() {
      $("#sidebar-menu a").on("click", function (e) {
        if ($(this).parent().hasClass("submenu")) {
          e.preventDefault();
        }
        if (!$(this).hasClass("subdrop")) {
          $("ul", $(this).parents("ul:first")).slideUp(350);
          $("a", $(this).parents("ul:first")).removeClass("subdrop");
          $(this).next("ul").slideDown(350);
          $(this).addClass("subdrop");
        } else if ($(this).hasClass("subdrop")) {
          $(this).removeClass("subdrop");
          $(this).next("ul").slideUp(350);
        }
      });
      $("#sidebar-menu ul li.submenu a.active")
        .parents("li:last")
        .children("a:first")
        .addClass("active")
        .trigger("click");
    }

    // Sidebar Initiate
    init();
  }, []);

  useEffect(() => {
    let finalData = {
      subRoleId: auth?.intely_health_user?.subRoleId,
    };
    dispatch(getAllMenusAction(finalData));
  }, [dispatch, auth]);

  let { getAllMenus } = useSelector((state) => state.rolesAndPermission);

  useEffect(() => {
    if (getAllMenus?.length > 0) {
      const permittedNames = new Set(
        getAllMenus
          .filter(({ permission }) => permission)
          .map(({ name }) => name)
      );

      // Filter sidebarData to only include objects with matching names and isPermission is true
      const filteredSidebarData = SidebarDataForStaff.filter(({ title }) =>
        permittedNames.has(title)
      );
      setFilterSideBarData(filteredSidebarData);
    }
  }, [getAllMenus]);


  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <div className="header-left mb-5 mt-3 me-4">
            {auth?.intely_health_user?.roleId === ROLEID.DOCTOR && (
              <Link to={PATH.DOCTORDASHBOARD} className="logo mr-0">
                <img
                  src={IMAGES.LOGOGREEN}
                  alt="Logo"
                  className="img-fluid flight_logo"
                />
              </Link>
            )}
            {auth?.intely_health_user?.roleId === ROLEID.PATIENT && (
              <Link to={PATH.PATIENTDASHBOARD} className="logo mr-0">
                <img
                  src={IMAGES.LOGOGREEN}
                  alt="Logo"
                  className="img-fluid flight_logo"
                />
              </Link>
            )}
            {auth?.intely_health_user?.roleId === ROLEID.ADMIN && (
              <Link to={PATH.ADMINDASHBOARD} className="logo mr-0">
                <img
                  src={IMAGES.LOGOGREEN}
                  alt="Logo"
                  className="img-fluid flight_logo"
                />
              </Link>
            )}
            {auth?.intely_health_user?.roleId === ROLEID.STAFF && (
              <Link to={PATH.ADMINDASHBOARD} className="logo mr-0">
                <img
                  src={IMAGES.LOGOGREEN}
                  alt="Logo"
                  className="img-fluid flight_logo"
                />
              </Link>
            )}
          </div>
          <ul className="mt-5">
            {auth?.intely_health_user?.roleId === ROLEID.DOCTOR && (
              <React.Fragment>
                {SidebarDataForDoctor.map((item, index) => {
                  return <SubMenu key={index} item={item} />;
                })}
              </React.Fragment>
            )}
            {auth?.intely_health_user?.roleId === ROLEID.PATIENT && (
              <React.Fragment>
                {SidebarDataForPatient.map((item, index) => {
                  return <SubMenu key={index} item={item} />;
                })}
              </React.Fragment>
            )}
            {auth?.intely_health_user?.roleId === ROLEID.ADMIN && (
              <React.Fragment>
                {SidebarDataForAdmin.map((item, index) => {
                  return <SubMenu key={index} item={item} />;
                })}
              </React.Fragment>
            )}
            {auth?.intely_health_user?.roleId === ROLEID.STAFF && (
              <React.Fragment>
                {getAllMenus.length > 0 && filterSideBarData && (
                  <>
                    {SidebarDataForStaff.map((item, index) => {
                      if (filterSideBarData && filterSideBarData.length > 0) {
                        for (let i = 0; i < filterSideBarData.length; i++) {
                          if (item.title === filterSideBarData[i].title) {
                            return <SubMenu key={index} item={item} />;
                          }
                        }
                      }
                    })}
                  </>
                )}
              </React.Fragment>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
