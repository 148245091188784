import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/auth.slice";
import signUpReducer from "./features/signUp/signup.slice";
import lookUpLanguageReducer from "./features/lookUp/lookUp.slice";
import checkEmailReducer from "./features/forgotPassword/forgotPassword.slice";
import loginReducer from "./features/login/login.slice";
import generalInformationReducer from "./features/doctor/doctorInformation/generalInformation/generalInformation.slice";
import accountDetailReducer from "./features/doctor/doctorInformation/accountDetail/accountDetail.slice";
import licenseReducer from "./features/doctor/doctorInformation/license/licence.slice";
import awardReducer from "./features/doctor/doctorInformation/award/award.slice";
import educationDetailReducer from "./features/doctor/doctorInformation/educationDetail/educationDetail.slice";
import referenceReducer from "./features/doctor/doctorInformation/reference/reference.slice";
import serviceRegionReducer from "./features/doctor/doctorInformation/serviceRegion/serviceRegion.slice";
import languageReducer from "./features/doctor/doctorInformation/language/language.slice";
import specializationReducer from "./features/doctor/doctorInformation/doctorSpecialization/doctorSpecialization.slice";
import patientEmergencyContactReducer from "./features/patient/patientInfo/patientEmergencyContact/patientEmergencyContact.slice";
import patientPersonalInfoReducer from "./features/patient/patientInfo/patientGeneralInfo/patientGeneralInfo.slice";
import patientGuardianReducer from "./features/patient/patientInfo/patientGuardian/patientGuardian.slice";
import patientLabResultReducer from "./features/patient/patientInfo/patientLabResult/patientLabResult.slice";
import myPhysicianReducer from "./features/patient/patientInfo/patientPhysician/patientPhysician.slice";
import patientBirthHistoryReducer from "./features/patient/patientInfo/patientBirthHistory/patientBirthHistory.slice";
import IllnessHistoryReducer from "./features/patient/patientInfo/patientIllnessHistory/patientIllnessHistory.slice";
import patientSurgeryHistoryReducer from "./features/patient/patientInfo/patientSurgeryHistory/patientSurgeryHistory.slice";
import allergyHistoryReducer from "./features/patient/patientInfo/patientAllergyHistory/patientAllergyHistory.slice";
import currentMedicationReducer from "./features/patient/patientInfo/patientCurrentMedication/patientCurrentMedication.slice";
import socialHistoryReducer from "./features/patient/patientInfo/patientSocialHistory/patientSocialHistory.slice";
import motherExaminationReducer from "./features/patient/patientInfo/patientMotherExamination/patientMotherExamination.slice";
import slotsReducer from "./features/doctor/doctorCalendar/slots/slots.slice";
import searchDoctorReducer from "./features/patient/searchaDoctor/searchaDoctor.slice";
import avatarReducer from "./features/patient/avatar/avatar.slice";
import PatientVitalReducer from "./features/doctor/videoConsultation/videoConsultation.slice";
import ChatSocketReducer from "./features/doctor/chatSocket/chatSocket.slice";
import AdminReducer from "./features/admin/doctormanagemnt/createdoctor.slice";
import UserManagementReducer from "./features/admin/userManagement/usermanagement.slice";
import FeedbackReducer from "./features/admin/feedback/feedback.slice";
import AdminAppointmentReducer from "./features/admin/adminAppointment/adminAppointment.slices";
import VonageReducer from "./features/vonage/vonage.slice";
import AdminSubscriptionReducer from "./features/admin/adminSubscription/adminSubscription.slice";
import FamilyMemberReducer from "./features/patient/familyMember/familyMember.slice";
import patientEmailReducer from "./features/patient/patientEmails/patientAllEmail.slice";
import communtiyFormReducer from "./features/doctor/communityForm/communityForm.slice";
import SysRolesReducer from "./features/admin/sysRoles/sysroles.slice";
import doctorDashboardReducer from "./features/doctor/doctorDashboard/doctorDashboard.slice";
import employeeManagementReducer from "./features/admin/employeemanagement/employeemanagement.slice";
import feePercentageReducer from "./features/admin/feePercentage/feePercentage.slice";

const store = configureStore({
  reducer: {
    login: loginReducer,
    vital: PatientVitalReducer,
    adminAppointment: AdminAppointmentReducer,
    familymember: FamilyMemberReducer,
    adminSubscription: AdminSubscriptionReducer,
    vonage: VonageReducer,
    feedback: FeedbackReducer,
    userManagement: UserManagementReducer,
    admin: AdminReducer,
    rolesAndPermission: SysRolesReducer,
    chatSocket: ChatSocketReducer,
    slots: slotsReducer,
    avatar: avatarReducer,
    searchDoctor: searchDoctorReducer,
    auth: authReducer,
    register: signUpReducer,
    generalInformation: generalInformationReducer,
    lookUpLanguage: lookUpLanguageReducer,
    checkEmail: checkEmailReducer,
    accountDetail: accountDetailReducer,
    license: licenseReducer,
    language: languageReducer,
    award: awardReducer,
    educationDetail: educationDetailReducer,
    reference: referenceReducer,
    serviceRegion: serviceRegionReducer,
    specialization: specializationReducer,
    patientEmergencyContact: patientEmergencyContactReducer,
    patientPersonalInfo: patientPersonalInfoReducer,
    patientGuardian: patientGuardianReducer,
    patientLabResult: patientLabResultReducer,
    myPhysician: myPhysicianReducer,
    patientBirthHistory: patientBirthHistoryReducer,
    motherExamination: motherExaminationReducer,
    IllnessHistory: IllnessHistoryReducer,
    patientSurgeryHistory: patientSurgeryHistoryReducer,
    allergyHistory: allergyHistoryReducer,
    currentMedication: currentMedicationReducer,
    socialHistory: socialHistoryReducer,
    patientEmail: patientEmailReducer,
    communtiyForm: communtiyFormReducer,
    doctorDashboard: doctorDashboardReducer,
    employeeManagement: employeeManagementReducer,
    feePercentage: feePercentageReducer,
  },
});

export default store;
