import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientAllergyHistoryServices from "./patientAllergyHistory.services";

const initialState = {
  addOrUpdate: [],
  getAllAllergyHistory: [],
  deletePatientAllergyHistory: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateAllergyAction = createAsyncThunk(
  "allergyHistory/addOrUpdateAllergyAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientAllergyHistoryServices.addOrUpdate(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllAllergyHistoryAction = createAsyncThunk(
  "allergyHistory/getAllAllergyHistoryAction",
  async (patientId, thunkAPI) => {
    try {
      return await PatientAllergyHistoryServices.getAllAllergyHistory(
        patientId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePatientAllergyHistoryAction = createAsyncThunk(
  "allergyHistory/deletePatientAllergyHistoryAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response =
        await PatientAllergyHistoryServices.deletePatientAllergyHistory(
          finalData
        );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const AllergyHistorySlice = createSlice({
  name: "allergyHistory",
  initialState,
  reducers: {
    clearAllergyHistory: (state) => {
      state.addOrUpdate = [];
      state.getAllAllergyHistory = [];
      state.deletePatientAllergyHistory = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateAllergyAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAllergyAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAllergyAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllAllergyHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAllergyHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllAllergyHistory = action.payload;
      })
      .addCase(getAllAllergyHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deletePatientAllergyHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatientAllergyHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePatientAllergyHistory = action.payload;
      })
      .addCase(deletePatientAllergyHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearAllergyHistory } = AllergyHistorySlice.actions;

export default AllergyHistorySlice.reducer;
