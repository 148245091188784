import React from "react";
import { useLocation } from "react-router-dom";
import { Header } from ".";
import { PATH } from "../../config";
import Sidebar from "./SideBar";
// import swal from 'sweetalert2'
export function ParentComponentWithSideBar({ children, ...rest }) {
  let location = useLocation();
  return (
    <>
      {location.pathname === PATH.DOCTORINFORMATION ||
      location.pathname === PATH.VIEWNOTES ||
      location.pathname === PATH.E_PRESCRIPTION ||
      location.pathname === PATH.LABTESTREPORT ||
      location.pathname === PATH.PATIENTINFORMATION ? (
        <div>{children}</div>
      ) : (
        <div className="main-wrapper">
          <Header />
          <Sidebar />
          <div className="page-wrapper">
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      )}
    </>
  );
}
