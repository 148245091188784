import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientEmergencyContactServices from "./patientEmergencyContact.services";

const initialState = {
  addOrUpdate: [],
  getAllEmergencyContact: [],
  deletePatientEmergencyContact: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateAction = createAsyncThunk(
  "patientEmergencyContact/addOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientEmergencyContactServices.addOrUpdate(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllEmergencyContactAction = createAsyncThunk(
  "patientEmergencyContact/getAllEmergencyContactAction",
  async (finaldata, thunkAPI) => {
    try {
      return await PatientEmergencyContactServices.getAllEmergencyContact(
        finaldata
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePatientEmergencyContactAction = createAsyncThunk(
  "patientEmergencyContact/deletePatientEmergencyContactAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response =
        await PatientEmergencyContactServices.deletePatientEmergencyContact(
          finalData
        );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const PatientEmergencyContactSlice = createSlice({
  name: "patientEmergencyContact",
  initialState,
  reducers: {
    clearPatientEmergencyContact: (state) => {
      state.addOrUpdate = [];
      state.getAllEmergencyContact = [];
      state.deletePatientEmergencyContact = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllEmergencyContactAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllEmergencyContactAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllEmergencyContact = action.payload;
      })
      .addCase(getAllEmergencyContactAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deletePatientEmergencyContactAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        deletePatientEmergencyContactAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.deletePatientEmergencyContact = action.payload;
        }
      )
      .addCase(
        deletePatientEmergencyContactAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      );
  },
});

export const { clearPatientEmergencyContact } =
  PatientEmergencyContactSlice.actions;

export default PatientEmergencyContactSlice.reducer;
