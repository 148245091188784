import api from "../../../assets/genericComponents/API";

const checkEmail = async (email) => {
  const response = await api.get(
    `api/ForgetPassword/checkEmail?email=${email}`
  );
  return response.data;
};
const updatePassword = async (data) => {
  const response = await api.post(`api/ForgetPassword/updatePassword`, data);
  return response.data;
};

const ForgotPaswwordServices = {
  checkEmail,
  updatePassword,
};

export default ForgotPaswwordServices;
