import api from "../../../../../assets/genericComponents/API";

const updateAccount = async (data) => {
  const response = await api.post(`api/AccountDetail/update`, data);
  return response.data;
};
const getAccountDetailById = async (userId) => {
  const response = await api.get(
    `api/AccountDetail/getAccountDetailById?userId=${userId}`
  );
  return response.data.data;
};

const AccountServices = {
  updateAccount,
  getAccountDetailById,
};

export default AccountServices;
