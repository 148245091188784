import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/DoctorSpecialization/addOrUpdate`, data);
  return response.data;
};
const deleteSpecialization = async (data) => {
  const response = await api.post(`api/DoctorSpecialization/delete`, data);
  return response.data;
};
const getAllSpecializations = async (userId) => {
  const response = await api.get(
    `api/DoctorSpecialization/getAllSpecializations?userId=${userId}`
  );
  return response.data.data;
};

const SpecializationServices = {
  addOrUpdate,
  getAllSpecializations,
  deleteSpecialization,
};

export default SpecializationServices;
