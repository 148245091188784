import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientGuardianServices from "./patientGuardian.services";

const initialState = {
  addOrUpdate: [],
  getAllPatientGuardian: [],
  deletePatientGuardian: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateGuardianAction = createAsyncThunk(
  "patientGuardian/addOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientGuardianServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllPatientGuardianAction = createAsyncThunk(
  "patientGuardian/getAllPatientGuardianAction",
  async (finaldata, thunkAPI) => {
    try {
      return await PatientGuardianServices.getAllPatientGuardian(finaldata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePatientGuardianAction = createAsyncThunk(
  "patientGuardian/deletePatientGuardianAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientGuardianServices.deletePatientGuardian(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const PatientGuardianSlice = createSlice({
  name: "patientGuardian",
  initialState,
  reducers: {
    clearPatientGuardian: (state) => {
      state.addOrUpdate = [];
      state.getAllPatientGuardian = [];
      state.deletePatientGuardian = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateGuardianAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateGuardianAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateGuardianAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPatientGuardianAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientGuardianAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientGuardian = action.payload;
      })
      .addCase(getAllPatientGuardianAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deletePatientGuardianAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatientGuardianAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePatientGuardian = action.payload;
      })
      .addCase(deletePatientGuardianAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearPatientGuardian } = PatientGuardianSlice.actions;

export default PatientGuardianSlice.reducer;
