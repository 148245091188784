import api from "../../../../assets/genericComponents/API";

const getAllSysRoles = async (data) => {
  const response = await api.get("api/SysRoles/getAll", {
    params: data,
  });
  return response.data;
};

const getAllMenus = async (data) => {
  const response = await api.get("api/SysRoles/getAllMenus", {
    params: data,
  });
  return response.data.data;
};

const addOrUpdateSysRoles = async (data) => {
  const response = await api.post(`api/SysRoles/addOrUpdate`, data);
  return response.data;
};

const saveRolePermission = async (data) => {
  const response = await api.post(`api/SysRoles/saveRolePermission`, data);
  return response.data;
};

const SysRolesServices = {
  getAllSysRoles,
  saveRolePermission,
  getAllMenus,
  addOrUpdateSysRoles,
};

export default SysRolesServices;
