import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientBirthHistoryServices from "./patientBirthHistory.services";

const initialState = {
  addOrUpdate: [],
  getAllPatientBirthHistory: [],
  deletePatientBirthHistory: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdatePatientBirthHistoryAction = createAsyncThunk(
  "patientBirthHistory/addOrUpdatePatientBirthHistoryAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientBirthHistoryServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllPatientBirthHistoryAction = createAsyncThunk(
  "patientBirthHistory/getAllPatientBirthHistoryAction",
  async (data, thunkAPI) => {
    try {
      return await PatientBirthHistoryServices.getAllPatientBirthHistory(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePatientBirthHistoryAction = createAsyncThunk(
  "patientBirthHistory/deletePatientBirthHistoryAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response =
        await PatientBirthHistoryServices.deletePatientBirthHistory(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const PatientBirthHistorySlice = createSlice({
  name: "patientBirthHistory",
  initialState,
  reducers: {
    clearPatientBirthHistory: (state) => {
      state.addOrUpdate = [];
      state.getAllPatientBirthHistory = [];
      state.deletePatientBirthHistory = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdatePatientBirthHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        addOrUpdatePatientBirthHistoryAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.addOrUpdate = action.payload;
        }
      )
      .addCase(
        addOrUpdatePatientBirthHistoryAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      )

      .addCase(getAllPatientBirthHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientBirthHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientBirthHistory = action.payload;
      })
      .addCase(getAllPatientBirthHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deletePatientBirthHistoryAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatientBirthHistoryAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePatientBirthHistory = action.payload;
      })
      .addCase(deletePatientBirthHistoryAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearPatientBirthHistory } = PatientBirthHistorySlice.actions;

export default PatientBirthHistorySlice.reducer;
