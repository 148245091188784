import api from "../../../assets/genericComponents/API";

const lookUpLanguages = async () => {
  const response = await api.get(`api/Lookup/getAllLanguages`);
  return response.data.data;
};
const lookUpCountries = async () => {
  const response = await api.get(`api/Lookup/getAllCountries`);
  return response.data.data;
};
const getAllSpecialities = async () => {
  const response = await api.get(`api/Lookup/getAllSpecialities`);
  return response.data.data;
};
const lookUpStates = async (countryId) => {
  const response = await api.get(
    `api/Lookup/getAllStates?countryId=${countryId}`
  );
  return response.data.data;
};
const getAllSubSpecialities = async (subSpeciality) => {
  const response = await api.get(
    `api/Lookup/getAllSubSpecialities?subSpeciality=${subSpeciality}`
  );
  return response.data.data;
};
const lookUpCities = async (stateId) => {
  const response = await api.get(`api/Lookup/getAllCities?stateId=${stateId}`);
  return response.data.data;
};
const getAllMedicine = async (search) => {
  const response = await api.get(`api/Lookup/getAllMedicine?search=${search ? search : ""}`);
  return response.data.data;
};

const LookUpServices = {
  lookUpLanguages,
  lookUpCountries,
  getAllMedicine,
  lookUpStates,
  getAllSubSpecialities,
  getAllSpecialities,
  lookUpCities,
};

export default LookUpServices;
