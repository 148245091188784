import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FeedbackServices from "./feedback.services";

const initialState = {
  getAllFeedBack: [],
  getAllFeedbackDoctor: [],
  addOrUpdateFeedback: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const getAllFeedBackAction = createAsyncThunk(
  "feedback/getAllFeedBackAction",
  async (finalData, thunkAPI) => {
    try {
      return await FeedbackServices.getAllFeedBack(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllFeedbackDoctorAction = createAsyncThunk(
  "feedback/getAllFeedbackDoctorAction",
  async (finalData, thunkAPI) => {
    try {
      return await FeedbackServices.getAllFeedbackDoctor(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addOrUpdateFeedbackAction = createAsyncThunk(
  "feedback/addOrUpdateFeedbackAction",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await FeedbackServices.addOrUpdateFeedback(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const FeedBackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    clearFeedback: (state) => {
      state.getAllFeedbackDoctor = [];
      state.getAllFeedBack = [];
      state.addOrUpdateFeedback = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllFeedBackAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFeedBackAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllFeedBack = action.payload;
      })
      .addCase(getAllFeedBackAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllFeedbackDoctorAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFeedbackDoctorAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllFeedbackDoctor = action.payload;
      })
      .addCase(getAllFeedbackDoctorAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearFeedback } = FeedBackSlice.actions;

export default FeedBackSlice.reducer;
