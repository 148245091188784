import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientMotherExaminationServices from "./patientMotherExamination.services";

const initialState = {
  addOrUpdate: [],
  getAllPatientMotherExamination: [],
  deletePatientMotherExamination: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdatePatientMotherExaminationAction = createAsyncThunk(
  "patientMotherExamination/addOrUpdatePatientMotherExaminationAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientMotherExaminationServices.addOrUpdate(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllPatientMotherExaminationAction = createAsyncThunk(
  "patientMotherExamination/getAllPatientMotherExaminationAction",
  async (data,thunkAPI) => {
    try {
      return await PatientMotherExaminationServices.getAllPatientMotherExamination(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePatientMotherExaminationAction = createAsyncThunk(
  "patientMotherExamination/deletePatientMotherExaminationAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response =
        await PatientMotherExaminationServices.deletePatientMotherExamination(
          finalData
        );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const PatientMotherExaminationSlice = createSlice({
  name: "patientMotherExamination",
  initialState,
  reducers: {
    clearPatientMotherExamination: (state) => {
      state.addOrUpdate = [];
      state.getAllPatientMotherExamination = [];
      state.deletePatientMotherExamination = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdatePatientMotherExaminationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        addOrUpdatePatientMotherExaminationAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.addOrUpdate = action.payload;
        }
      )
      .addCase(
        addOrUpdatePatientMotherExaminationAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      )

      .addCase(getAllPatientMotherExaminationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getAllPatientMotherExaminationAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.getAllPatientMotherExamination = action.payload;
        }
      )
      .addCase(
        getAllPatientMotherExaminationAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      )

      .addCase(deletePatientMotherExaminationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        deletePatientMotherExaminationAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.deletePatientMotherExamination = action.payload;
        }
      )
      .addCase(
        deletePatientMotherExaminationAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      );
  },
});

export const { clearPatientMotherExamination } =
  PatientMotherExaminationSlice.actions;

export default PatientMotherExaminationSlice.reducer;
