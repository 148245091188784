import api from "../../../../assets/genericComponents/API";

const getAlldoctor = async (data) => {
  const response = await api.get("api/Admin/getAlldoctor", {
    params: data,
  });
  return response.data;
};

const getAllPatient = async (data) => {
  const response = await api.get("api/Admin/getAllPatient", {
    params: data,
  });
  return response.data;
};

const UserManagementServices = {
  getAlldoctor,
  getAllPatient,
};

export default UserManagementServices;
