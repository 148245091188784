import api from "../../../../assets/genericComponents/API";

const chatCreate = async (data) => {
  const response = await api.post(`api/Chat/create`, data);
  return response.data;
};
const getAllInboxes = async () => {
  const response = await api.get(`api/Chat/getAllInboxes`);
  return response.data.data;
};

const getAllConversationDetails = async (data) => {
  const response = await api.get("api/Chat/getAllConversationDetails", {
    params: data,
  });
  return response.data.data;
};

const ChatServices = {
  chatCreate,
  getAllConversationDetails,
  getAllInboxes,
};

export default ChatServices;
