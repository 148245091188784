import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import patientAllEmail from "./patientAllEmail.services";

const initialState = {
  getAllReceivedMails: [],
  getAllSendMails: [],
  getAllDoctorEmail: [],
  getAllPatientEmail: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  getAllSendMailsisLoading: false,
  getAllReceivedMailsisLoading: false,
  message: "",
};

export const addOrUpdatePatientEmailAction = createAsyncThunk(
  "patientEmails/addOrUpdatePatientEmailAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await patientAllEmail.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllReceivedMailsAction = createAsyncThunk(
  "patientEmails/getAllReceivedMailsAction",
  async (finalData, thunkAPI) => {
    try {
      return await patientAllEmail.getAllReceivedMails(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSendMailsAction = createAsyncThunk(
  "patientEmails/getAllSendMailsAction",
  async (finalData, thunkAPI) => {
    try {
      return await patientAllEmail.getAllSendMails(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllDoctorEmailAction = createAsyncThunk(
  "patientEmails/getAllDoctorEmailAction",
  async (finalData, thunkAPI) => {
    try {
      return await patientAllEmail.getAllDoctorEmail(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllPatientEmailAction = createAsyncThunk(
  "patientEmails/getAllPatientEmailAction",
  async (finalData, thunkAPI) => {
    try {
      return await patientAllEmail.getAllPatientEmail(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patientEmailSlice = createSlice({
  name: "patientEmailSlice",
  initialState,
  reducers: {
    cleargetAllMails: (state) => {
      state.getAllReceivedMails = [];
      state.getAllSendMails = [];
      state.getAllDoctorEmail = [];
      state.getAllPatientEmail = [];
      state.isLoading = false;
      state.getAllReceivedMailsisLoading = false;
      state.getAllSendMailsisLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllReceivedMailsAction.pending, (state) => {
        state.getAllReceivedMailsisLoading = true;
      })
      .addCase(getAllReceivedMailsAction.fulfilled, (state, action) => {
        state.getAllReceivedMailsisLoading = false;
        state.isSuccess = true;
        state.getAllReceivedMails = action.payload;
      })
      .addCase(getAllReceivedMailsAction.rejected, (state, action) => {
        state.getAllReceivedMailsisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllSendMailsAction.pending, (state) => {
        state.getAllSendMailsisLoading = true;
      })
      .addCase(getAllSendMailsAction.fulfilled, (state, action) => {
        state.getAllSendMailsisLoading = false;
        state.isSuccess = true;
        state.getAllSendMails = action.payload;
      })
      .addCase(getAllSendMailsAction.rejected, (state, action) => {
        state.getAllSendMailsisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllDoctorEmailAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDoctorEmailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllDoctorEmail = action.payload;
      })
      .addCase(getAllDoctorEmailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPatientEmailAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientEmailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientEmail = action.payload;
      })
      .addCase(getAllPatientEmailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { cleargetAllMails } = patientEmailSlice.actions;

export default patientEmailSlice.reducer;
