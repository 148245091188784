import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/PatientBirthHistory/addOrUpdate`, data);
  return response.data;
};
const deletePatientBirthHistory = async (data) => {
  const response = await api.post(`api/PatientBirthHistory/delete`, data);
  return response.data;
};

const getAllPatientBirthHistory = async (data) => {
  const response = await api.get(
    "api/PatientBirthHistory/getAllPatientBirthHistory",
    {
      params: data,
    }
  );
  return response.data.data;
};

const PatientBirthHistoryServices = {
  addOrUpdate,
  getAllPatientBirthHistory,
  deletePatientBirthHistory,
};

export default PatientBirthHistoryServices;
