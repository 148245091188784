import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LoginServices from "./login.services";

const initialState = {
  login: [],
  isError: false,
  loginisSuccess: false,
  isLoading: false,
  message: "",
};

export const loginAction = createAsyncThunk(
  "login/loginAction",
  async ({ user, setCookies, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await LoginServices.login(user, setCookies);
      if (response.succeeded === true) {
      } else {
        Notificiation(response.message, "error");
        moveToNext(response.message);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    clearLogin: (state) => {
      state.login = [];
      state.isLoading = false;
      state.loginisSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.loginisSuccess = true;
        state.login = action.payload;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearLogin } = LoginSlice.actions;

export default LoginSlice.reducer;
