import api from "../../../../assets/genericComponents/API";

const getAllSubscriptionPlan = async (data) => {
  const response = await api.get("api/Subscription/getAllSubscriptionPlan", {
    params: data,
  });
  return response.data.data;
};
const subscriptionPlanSave = async (data) => {
  const response = await api.post(
    `api/Subscription/subscriptionPlanSave`,
    data
  );
  return response.data;
};
const patientCard = async (data) => {
  const response = await api.post(`api/Subscription/patientCard`, data);
  return response.data;
};
const oneTimePayment = async (data) => {
  const response = await api.post(`api/Subscription/oneTimePayment`, data);
  return response.data;
};
const cancelSubscription = async (data) => {
  const response = await api.post(`api/Subscription/cancelSubscription`, data);
  return response.data;
};
const patientSubscriptionSave = async (data) => {
  const response = await api.post(
    `api/Subscription/patientSubscriptionSave`,
    data
  );
  return response.data;
};

const getAllPatientSubscription = async (data) => {
  const response = await api.get("api/Subscription/getAllPatientSubscription", {
    params: data,
  });
  return response.data;
};
const getCustomerPaymentsList = async (data) => {
  const response = await api.get("api/Subscription/getCustomerPaymentsList", {
    params: data,
  });
  return response.data;
};

const AdminSubscriptionServices = {
  subscriptionPlanSave,
  getAllSubscriptionPlan,
  cancelSubscription,
  getCustomerPaymentsList,
  getAllPatientSubscription,
  patientSubscriptionSave,
  oneTimePayment,
  patientCard,
};

export default AdminSubscriptionServices;
