import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB-j3RarQbcqeqLBEnhQKZxDhBVLTVkT94",
  authDomain: "intelihealth-19066.firebaseapp.com",
  projectId: "intelihealth-19066",
  storageBucket: "intelihealth-19066.appspot.com",
  messagingSenderId: "759301892253",
  appId: "1:759301892253:web:134d3ede7132296ca1cc74",
  measurementId: "G-99FK0BJV2K",
};
const firebaseApp = initializeApp(firebaseConfig);


const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log("Firebase not supported this browser");
    return () => {};
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const fetchToken = async (setTokenFound) => {
  try {
    const messagingResolve = await messaging;
    const currentToken = await getToken(messagingResolve, {
      vapidKey:
        "BGhTACJhnMCWD3VbQEkK9od3DU4nhcNMBLvRd04pbPoOulMTxrDUzMkfeNzdsO1fYSFLrwh_74m_MvBkQvn3lko",
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      setTokenFound(true);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      setTokenFound(false);
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      onMessage(messagingResolve, (payload) => {
        // console.log('On message: ', messaging, payload);
        resolve(payload);
      });
    })()
  );
