// A wrapper for <Route> that redirects to the login

import React from "react";
import { Navigate } from "react-router-dom";
import { PATH, ROLEID } from "../../config";
import { useAuth } from "./ProvideAuth";
import { ParentComponentWithSideBar } from "../../assets";
// screen if you're not yet authenticated.
function PrivateRoute({ children }) {
  let auth = useAuth();
  return (
    <React.Fragment>
      {auth?.intely_health_user?.roleId === ROLEID.DOCTOR ||
      auth?.intely_health_user?.roleId === ROLEID.PATIENT ||
      auth?.intely_health_user?.roleId === ROLEID.STAFF ||
      auth?.intely_health_user?.roleId === ROLEID.ADMIN ? (
        <ParentComponentWithSideBar>{children}</ParentComponentWithSideBar>
      ) : (
        <Navigate to={PATH.LOGIN} replace />
      )}
    </React.Fragment>
  );
}
export default PrivateRoute;
