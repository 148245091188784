import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/PatientGuardian/addOrUpdate`, data);
  return response.data;
};
const deletePatientGuardian = async (data) => {
  const response = await api.post(`api/PatientGuardian/delete`, data);
  return response.data;
};
const getAllPatientGuardian = async (data) => {
  const response = await api.get(`api/PatientGuardian/getAllPatientGuardian?userId=${data.userId}`);
  return response.data.data;
};

const PatientGuardianServices = {
  addOrUpdate,
  getAllPatientGuardian,
  deletePatientGuardian,
};

export default PatientGuardianServices;
