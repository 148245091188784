import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ChatServices from "./chatSocket.services";

const initialState = {
  chatCreate: [],
  getAllInboxes: [],
  getAllConversationDetails: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const chatCreateAction = createAsyncThunk(
  "chat/chatCreateAction",
  async ({ data, moveToNext }, thunkAPI) => {
    try {
      const response = await ChatServices.chatCreate(data);
      if (response.succeeded === true) {
        // if (Notificiation) {
        //   Notificiation(response.message, "success");
        // }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        // Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllInboxesAction = createAsyncThunk(
  "license/getAllInboxesAction",
  async (thunkAPI) => {
    try {
      return await ChatServices.getAllInboxes();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllConversationDetailsAction = createAsyncThunk(
  "license/getAllConversationDetailsAction",
  async (finalData, thunkAPI) => {
    try {
      return await ChatServices.getAllConversationDetails(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    clearChat: (state) => {
      state.chatCreate = [];
      state.getAllInboxes = [];
      state.getAllConversationDetails = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllInboxesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllInboxesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllInboxes = action.payload;
      })
      .addCase(getAllInboxesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllConversationDetailsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllConversationDetailsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllConversationDetails = action.payload;
      })
      .addCase(getAllConversationDetailsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(chatCreateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(chatCreateAction.fulfilled, (state, action) => {
        let messageList = state.getAllConversationDetails;
        // for (let i = 0; i < messageList.length; i++) {
        //   if (messageList[i].inboxId === action.payload.data.inboxId) {
        //     messageList[i].isDoctorSubscribed =
        //       !messageList[i].isDoctorSubscribed;
        //   }
        // }
        state.isLoading = false;
        state.isSuccess = true;
        state.chatCreate = action.payload;
        state.getAllConversationDetails = messageList;
      })
      .addCase(chatCreateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearChat } = ChatSlice.actions;

export default ChatSlice.reducer;
