import React from "react";
import { useCookies } from "react-cookie";
import { FiLogOut } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { PATH } from "../../config";
export function SignOut() {
  const history = useNavigate();
  const Swal = require("sweetalert2");
  const [cookies, remove] = useCookies(["intely_health_user"]);
  function logout() {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "rgba(5, 104, 96, 1)",
      cancelButtonColor: "#F9A252",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        // history(PATH.LOGIN);
        remove("intely_health_user", { path: "/" });
        window.localStorage.removeItem("parentEmail");
      }
    });
  }

  return (
    <div
      onClick={() => {
        logout();
      }}
    >
      <div className="logout-text ms-4" style={{ color: "#066860" }}>
        <FiLogOut size={19} className="me-3" />
        {"Sign Out"}
      </div>
    </div>
  );
}
