import React, { useState } from "react";
import { BsBell } from "react-icons/bs";
import { IMAGES } from "../../assets/images";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { Button, Card, Modal } from "react-bootstrap";
import { TbBellRinging } from "react-icons/tb";
import ReactStars from "react-rating-stars-component";
import { ROLEID } from "../../config";

export function Notification() {
  const [show, setShow] = useState(false);
  let auth = useAuth();
  const [modalShow, setModalShow] = React.useState(false);
  const [ratingModalShow, setRatingModalShow] = React.useState(false);
  return (
    <>
      {auth?.intely_health_user?.roleId === ROLEID.PATIENT && (
        <Dropdown className="main-drop-down-notification notificationList notify">
          <Dropdown.Toggle
            className="custom-grey-button dropdown-toggle nav-link dropdown_bell"
            id="dropdown-custom-components"
          >
            <p className="no-margin">
              <BsBell
                data-tip
                data-for="notification-icon"
                className="header-icon-tool"
                onClick={() => {
                  setShow(true);
                }}
              />
              {/* <GoPrimitiveDot size={18} className="GoPrimitiveDot" /> */}
            </p>
          </Dropdown.Toggle>
          <Dropdown.Menu className="notification-list " show={show}>
            <div className="p-3 d-flex justify-content-between align-items-center">
              <h3>Notifications</h3>
              <h4
                className="cursor"
                // onClick={() => {
                //   setShow(false);
                // }}
              >
                See All
              </h4>
            </div>
            {FeedbackList?.map((item, index) => {
              return (
                <Dropdown.Item
                  href="#"
                  className="notification-message d-block"
                  key={index}
                  onClick={() => setModalShow(true)}
                  //   onClick={() => {
                  //     setShow(false);
                  //   }}
                >
                  <div className="row">
                    <div className="col-md-10 col-sm-10 col-10 media_body">
                      <div className="notifi_name">
                        <p>{item.feedbackMessage}</p>
                      </div>
                    </div>
                  </div>
                </Dropdown.Item>
              );
            })}
            <FeedBackModal
              show={modalShow}
              setModalShow={setModalShow}
              setRatingModalShow={setRatingModalShow}
              // onHideFeedBackModal={() => setModalShow(false)}
            />
            <RatingModal
              show={ratingModalShow}
              setRatingModalShow={setRatingModalShow}
            />
          </Dropdown.Menu>
        </Dropdown>
      )}
      {auth?.intely_health_user?.roleId === ROLEID.DOCTOR && (
        <Dropdown className="main-drop-down-notification notificationList notify">
          <Dropdown.Toggle
            className="custom-grey-button dropdown-toggle nav-link dropdown_bell"
            id="dropdown-custom-components"
          >
            <p className="no-margin">
              <BsBell
                data-tip
                data-for="notification-icon"
                className="header-icon-tool"
                onClick={() => {
                  setShow(true);
                }}
              />
              {/* <GoPrimitiveDot size={18} className="GoPrimitiveDot" /> */}
            </p>
          </Dropdown.Toggle>
          <Dropdown.Menu className="notification-list " show={show}>
            <div className="p-3 d-flex justify-content-between align-items-center">
              <h3>Notifications</h3>
              <h4
                onClick={() => {
                  setShow(false);
                }}
              >
                See All
              </h4>
            </div>
            {NotificationList?.map((item, index) => {
              return (
                <Dropdown.Item
                  href="#"
                  className="notification-message d-block"
                  key={index}
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <div className="row">
                    <div className="col-md-10 col-sm-10 col-10 media_body">
                      <div className="notifi_name">
                        <h4>{item.name}</h4>
                        <p>{item.time}</p>
                      </div>
                    </div>
                  </div>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
function FeedBackModal(props) {
  const [stars, setStars] = useState(3);
  const ratingChanged = (newRating) => {
    setStars(newRating);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h2>FeedBack</h2>
        <div className="">
          <Card className="info-card">
            <div className="d-flex justify-content-between patient-profile-parent-feedback align-items-top bg-default-light">
              <span className="user-img feedback-section profile-image mt-0 d-flex align-items-center ">
                <img
                  className="rounded-circle ms-4"
                  src={IMAGES.PROFILE}
                  width="31"
                  alt="Ryan Taylor"
                />
                <span className="doc-profile-section">
                  <span className="doc-profile-sec">
                    <p className="doc-profile-name-sty mb-0">
                      {"Dr. Shawn Murphy"}
                    </p>
                    <p className="doc-profile-speciality mb-0">
                      {"Heart Surgeon"}{" "}
                    </p>
                  </span>
                  <span>
                    <span className="doc-profile-rating">
                      <ReactStars
                        count={5}
                        size={24}
                        value={5}
                        activeColor="#ffd700"
                      />
                    </span>
                  </span>
                </span>
              </span>
              <span className="bell-icon cursor mr-3">
                <TbBellRinging />
              </span>
            </div>
          </Card>
          <p>Rate a Doctor:</p>
          <div className="rating-star-style react-star-parent">
            <ReactStars
              count={5}
              size={24}
              value={stars}
              onChange={ratingChanged}
              activeColor="#ffd700"
            />
            <span className="star-number-span">{stars}</span>
          </div>
          <div className="mt-4">
            <p>Please leave your feedback below :</p>
            <textarea
              id="messageBody"
              name="messageBody"
              className="form-control text-area-size-message"
              rows="10"
              cols="50"
              placeholder="Type here....."
            />
          </div>
          <div className="slots-modal-button-parent">
            <Button
              type="button"
              onClick={() => {
                props.setRatingModalShow(true);
                props.setModalShow(false);
              }}
              className="next-button next-button-icon mt-4 me-0 cursor"
            >
              Send
            </Button>
            <Button
              type="button"
              className="cancel-buton next-button-icon  me-2 mt-4 cursor"
              onClick={() => props.setModalShow(false)}
            >
              <span className="sign-in-google-text">{"Cancel"}</span>
            </Button>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}
function RatingModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="p-0 payment-process-parent-class"
      ></Modal.Header>
      <Modal.Body>
        <h2 className="txt-green-color">
          You Shared Rating 3 For Doctor Leazy, Please Share More Details
        </h2>
        <div className="mt-4">
          <p className="">Please leave your feedback below :</p>
          <textarea
            id="messageBody"
            name="messageBody"
            className="form-control text-area-size-message"
            rows="10"
            cols="50"
            placeholder="Tell me about the Behaviour Of Doctor..."
          />
        </div>
        <div className="slots-modal-button-parent">
          <Button
            type="button"
            className="next-button next-button-icon mt-4 me-0 cursor"
          >
            Send
          </Button>
          <Button
            type="button"
            className="cancel-buton next-button-icon  me-2 mt-4 cursor"
            onClick={() => props.setRatingModalShow(false)}
          >
            <span className="sign-in-google-text">{"Cancel"}</span>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const NotificationList = [
  {
    name: "Collin Pazdzior",
    time: "Today 06:12 PM",
  },
  {
    name: "Collin Pazdzior",
    time: "Today 06:12 PM",
  },
  {
    name: "Collin Pazdzior",
    time: "Today 06:12 PM",
  },
];
const FeedbackList = [
  {
    feedbackMessage: "Collin Pazdzior",
  },
];
