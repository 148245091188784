import api from "../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/PatientVital/addOrUpdate`, data);
  return response.data;
};
const soapNotesAddOrUpdate = async (data) => {
  const response = await api.post(`api/SoapNote/addOrUpdate`, data);
  return response.data;
};
const ObservationAddOrUpdate = async (data) => {
  const response = await api.post(`api/Observation/addOrUpdate`, data);
  return response.data;
};
const PatientLabTestAddOrUpdate = async (data) => {
  const response = await api.post(`api/PatientLabTest/addOrUpdate`, data);
  return response.data;
};

const getAllLabTest = async (data) => {
  const response = await api.get(
    `api/PatientLabTest/getAllLabTest?userId=${data.userId}&doctorId=${data.doctorId}&appointmentId=${data.appointmentId}`
  );
  return response.data.data;
};
const getAllPatientVital = async (data) => {
  const response = await api.get(
    `api/PatientVital/getAllPatientVital?patientId=${data.patientId}&appointmentId=${data.appointmentId}`
  );
  return response.data.data;
};

const getVitalListByPatientId = async (data) => {
  const response = await api.get(
    `api/PatientVital/getVitalListByPatientId?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&patientId=${data.patientId}`
  );
  return response.data;
};

const getAllDoctorObservation = async (data) => {
  const response = await api.get(
    `api/Observation/getAllDoctorObservation?patientId=${data.patientId}&doctorId=${data.doctorId}&appointmentId=${data.appointmentId}`
  );
  return response.data.data;
};
const getAllSoapNote = async (data) => {
  const response = await api.get(
    `api/SoapNote/getAllSoapNote?patientId=${data.patientId}&doctorId=${data.doctorId}&appointmentId=${data.appointmentId}`
  );
  return response.data.data;
};

const getAllPatientVitalList = async (data) => {
  const response = await api.get("api/PatientVital/getAllPatientVitalList", {
    params: data,
  });
  return response.data;
};

const getVitalByPatientId = async (data) => {
  const response = await api.get("api/PatientVital/getVitalByPatientId", {
    params: data,
  });
  return response.data.data;
};

const PatientMakeNotesServices = {
  addOrUpdate,
  soapNotesAddOrUpdate,
  getAllDoctorObservation,
  getVitalByPatientId,
  getAllPatientVitalList,
  getAllSoapNote,
  getAllLabTest,
  PatientLabTestAddOrUpdate,
  ObservationAddOrUpdate,
  getAllPatientVital,
  getVitalListByPatientId,
};

export default PatientMakeNotesServices;
