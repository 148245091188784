import React, { createContext, useContext } from "react";
import { useCookies } from "react-cookie";
const authContext = createContext();
export function ProvideAuth({ children }) {
  const [cookies] = useCookies(["intely_health_user"]);
  // const sessional_user = {
  //   intely_health_user: JSON.parse(sessionStorage.getItem("intely_health_user")),
  // };
  // console.log("sessional_user", sessional_user);
  const user = cookies;
  console.log("user", user);
  return <authContext.Provider value={user}>{children}</authContext.Provider>;
}
export function useAuth() {
  return useContext(authContext);
}

// import React, { createContext, useContext } from "react";
// const authContext = createContext();
// export function ProvideAuth({ children }) {
//   const user = {
//     intely_health_user: JSON.parse(localStorage.getItem("rememberMeData")),
//   };
//   console.log("user", user);
//   return <authContext.Provider value={user}>{children}</authContext.Provider>;
// }
// export function useAuth() {
//   return useContext(authContext);
// }
