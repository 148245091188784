import React from "react";
import { PATH } from "../../config";
import { BsChatLeftText, BsCreditCard, BsPeople } from "react-icons/bs";
import { RiCoupon4Line, RiDashboardLine } from "react-icons/ri";
import {
  AiOutlineCalendar,
  AiOutlineCreditCard,
  AiOutlineFileText,
} from "react-icons/ai";
import {
  MdDataUsage,
  MdManageAccounts,
  MdOutlineForum,
  MdOutlinePeopleAlt,
  MdPayment,
  MdNextPlan,
} from "react-icons/md";
import { GrGroup, GrNotification } from "react-icons/gr";
import { FaRegEnvelope, FaUserNurse, FaWpforms } from "react-icons/fa";
import { IoPeopleCircleOutline } from "react-icons/io5";
import { ImHistory, ImPlus } from "react-icons/im";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { GiGlobe, GiPlagueDoctorProfile } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";
import { VscFeedback } from "react-icons/vsc";

export const SidebarDataForDoctor = [
  {
    title: "Dashboard",
    icon: <RiDashboardLine size={24} />,
    path: PATH.DOCTORDASHBOARD,
    pathList: [PATH.DOCTORDASHBOARD],
  },
  {
    title: "Visit Slots Management",
    icon: <AiOutlineCalendar size={24} />,
    path: PATH.CALENDARVIEW,
    pathList: [PATH.CALENDARVIEW],
  },
  {
    title: "All Visits",
    icon: <AiOutlineFileText size={24} />,
    path: PATH.ALLVISITS,
    pathList: [PATH.ALLVISITS, PATH.VIEWLISTFORVISITER],
  },
  {
    title: "My Patients",
    icon: <GrGroup size={24} />,
    path: PATH.PATIENTLISTING,
    pathList: [PATH.PATIENTLISTING],
  },
  {
    title: "Secure Messages",
    icon: <FaRegEnvelope size={24} />,
    path: PATH.CHAT,
    pathList: [PATH.CHAT],
  },
  {
    title: "Conversation",
    icon: <GrNotification size={24} />,
    path: PATH.MESSAGE,
    pathList: [PATH.MESSAGE],
  },
  {
    title: "Payment Settings",
    icon: <MdPayment size={24} />,
    path: PATH.PAYMENTINFO,
    pathList: [PATH.PAYMENTINFO],
  },
  {
    title: "Community forum",
    icon: <FaWpforms size={24} />,
    path: PATH.COMMUNITYFORM,
    pathList: [PATH.COMMUNITYFORM],
  },
  {
    title: "My Earnings",
    icon: <FaWpforms size={24} />,
    path: PATH.MYEARNING,
    pathList: [PATH.MYEARNING],
  },
];
export const SidebarDataForPatient = [
  {
    title: "Dashboard",
    icon: <RiDashboardLine size={24} />,
    path: PATH.PATIENTDASHBOARD,
    pathList: [PATH.PATIENTDASHBOARD],
  },
  {
    title: "Find a Physician",
    icon: <BsPeople size={24} />,
    path: PATH.FINDADOCTOR,
    pathList: [PATH.FINDADOCTOR],
  },

  {
    title: "My Appointments",
    icon: <AiOutlineCalendar size={24} />,
    path: PATH.MYAPPOINTMENTS,
    pathList: [PATH.MYAPPOINTMENTS],
  },
  {
    title: "Patient Vitals",
    icon: <BsPeople size={24} />,
    path: PATH.PATIENTOWNVITALS,
    pathList: [PATH.PATIENTOWNVITALS],
  },
  {
    title: "Notes",
    icon: <AiOutlineFileText size={24} />,
    path: PATH.NOTES,
    pathList: [PATH.NOTES],
  },
  {
    title: "Secure Messages",
    icon: <BsChatLeftText size={20} />,
    path: PATH.CHAT,
    pathList: [PATH.CHAT],
  },
  {
    title: "Conversation",
    icon: <GrNotification size={24} />,
    path: PATH.MESSAGE,
    pathList: [PATH.MESSAGE],
  },
  // {
  //   title: "Tele-Consultation",
  //   icon: <GrVideo size={24} />,
  //   path: PATH.TELECONSULTATION,
  //   pathList: [PATH.TELECONSULTATION],
  // },
  {
    title: "Subscription Plan",
    icon: <BsCreditCard size={24} />,
    path: PATH.SUBSCRIPTIONPLAN,
    pathList: [PATH.SUBSCRIPTIONPLAN],
  },
  {
    title: "My Plans",
    icon: <MdNextPlan size={24} />,
    path: PATH.MYPLANS,
    pathList: [PATH.MYPLANS],
  },
  {
    title: "Plans History",
    icon: <ImHistory size={24} />,
    path: PATH.PLANSHISTORY,
    pathList: [PATH.PLANSHISTORY],
  },
  {
    title: "Patient View",
    icon: <MdOutlinePeopleAlt size={24} />,
    path: PATH.PATIENTVIEW,
    pathList: [PATH.PATIENTVIEW],
  },
  {
    title: "Community Forum",
    icon: <MdOutlineForum size={24} />,
    path: PATH.COMMUNITYFORUM,
    pathList: [PATH.COMMUNITYFORUM],
  },
  {
    title: "Family Members",
    icon: <GrGroup size={24} />,
    path: PATH.FAMILYMEMBERS,
    pathList: [PATH.FAMILYMEMBERS],
  },
];
export const SidebarDataForAdmin = [
  {
    title: "Dashboard",
    icon: <RiDashboardLine size={24} />,
    path: PATH.ADMINDASHBOARD,
    pathList: [PATH.ADMINDASHBOARD],
  },
  {
    title: "Appointments",
    icon: <FaUserNurse size={24} />,
    path: PATH.ADMINAPPOINTMENTS,
    pathList: [PATH.ADMINAPPOINTMENTS],
  },
  {
    title: " Role Management",
    icon: <GrGroup size={24} />,
    path: PATH.ADMINCREATENEWROLE,
    pathList: [PATH.ADMINCREATENEWROLE],
  },
  {
    title: "Employee Management",
    icon: <MdManageAccounts size={24} />,
    path: PATH.EMPLOYEEMANAGEMENT,
    pathList: [PATH.EMPLOYEEMANAGEMENT],
  },
  // {
  //   title: "User Management",
  //   icon: <FaUsersCog size={24} />,
  //   path: "",
  //   pathList: [""],
  // },
  {
    title: "Physician Management",
    icon: <ImPlus size={24} />,
    path: PATH.PHYSICIANMANAGEMENT,
    pathList: [PATH.PHYSICIANMANAGEMENT],
  },
  // {
  //   title: "Doctor Profile",
  //   icon: <ImPlus size={24} />,
  //   path: PATH.DOCTORPROFILEPHYSICIAN,
  //   pathList: [PATH.DOCTORPROFILEPHYSICIAN],
  // },
  // {
  //   title: "Subscription Plan",
  //   icon: <FaCreditCard size={24} />,
  //   path: "",
  //   pathList: [""],
  // },
  {
    title: "Payment",
    icon: <HiOutlineCurrencyDollar size={24} />,
    // path: PATH.PAYMENTMANAGEMENT,
    // pathList: [PATH.PAYMENTMANAGEMENT],
    subNav: [
      {
        title: "Payments Management",
        path: PATH.PAYMENTMANAGEMENT,
        pathList: [PATH.PAYMENTMANAGEMENT],
        icon: <BsPeople className="mr-2 font-16" />,
      },
      {
        title: "Payments Settings",
        path: PATH.PAYMENTSETTINGS,
        pathList: [PATH.PAYMENTSETTINGS],
        icon: <AiOutlineFileText className="mr-2 font-16" />,
      },
      {
        title: "Refund",
        path: PATH.PAYMENTREFUND,
        pathList: PATH.PAYMENTREFUND,
        icon: <AiOutlineFileText className="mr-2 font-16" />,
      },
    ],
  },
  {
    title: "Subscription",
    icon: <AiOutlineCreditCard size={24} />,
    // path: PATH.PAYMENTMANAGEMENT,
    // pathList: [PATH.PAYMENTMANAGEMENT],
    subNav: [
      {
        title: "Plans",
        path: PATH.ADMINSUBSCRIPTIONPLAN,
        pathList: [PATH.ADMINSUBSCRIPTIONPLAN],
        icon: <BsPeople className="mr-2 font-16" />,
      },
      {
        title: "Coupons Management",
        path: PATH.COUPONMANAGEMENT,
        pathList: [PATH.COUPONMANAGEMENT],
        icon: <RiCoupon4Line className="mr-2 font-16" />,
      },
      {
        title: "Usage",
        path: PATH.SUBSCRIPTIONUSAGE,
        pathList: PATH.SUBSCRIPTIONUSAGE,
        icon: <MdDataUsage className="mr-2 font-16" />,
      },
    ],
  },
  {
    title: "User Management",
    icon: <IoIosPeople size={24} />,
    subNav: [
      {
        title: "Physician",
        path: PATH.USERDOCTORMANAGEMENT,
        pathList: [PATH.USERDOCTORMANAGEMENT],
        icon: <GiPlagueDoctorProfile className="mr-2 font-16" />,
      },
      {
        title: "Patient",
        path: PATH.USERPATIENTMANAGEMENT,
        pathList: [PATH.USERPATIENTMANAGEMENT],
        icon: <IoPeopleCircleOutline className="mr-2 font-16" />,
      },
    ],
  },
  {
    title: "Community Forum",
    icon: <GiGlobe size={24} />,
    // path: PATH.ADMINCOMMUNITYFORUM,
    path: PATH.COMMUNITYFORUM,
    pathList: [PATH.COMMUNITYFORUM],
  },
  {
    title: "Feedback",
    icon: <VscFeedback size={24} />,
    path: PATH.ADMINFEEDBACK,
    pathList: [PATH.ADMINFEEDBACK],
  },
];
export const SidebarDataForStaff = [
  {
    title: "Dashboard",
    icon: <RiDashboardLine size={24} />,
    path: PATH.ADMINDASHBOARD,
    pathList: [PATH.ADMINDASHBOARD],
  },
  {
    title: "Appointments",
    icon: <FaUserNurse size={24} />,
    path: PATH.ADMINAPPOINTMENTS,
    pathList: [PATH.ADMINAPPOINTMENTS],
  },
  {
    title: " Role Management",
    icon: <GrGroup size={24} />,
    path: PATH.ADMINCREATENEWROLE,
    pathList: [PATH.ADMINCREATENEWROLE],
  },
  {
    title: "Employee Management",
    icon: <MdManageAccounts size={24} />,
    path: PATH.EMPLOYEEMANAGEMENT,
    pathList: [PATH.EMPLOYEEMANAGEMENT],
  },
  {
    title: "Physician Management",
    icon: <ImPlus size={24} />,
    path: PATH.PHYSICIANMANAGEMENT,
    pathList: [PATH.PHYSICIANMANAGEMENT],
  },
  {
    title: "Payment",
    icon: <HiOutlineCurrencyDollar size={24} />,
    // path: PATH.PAYMENTMANAGEMENT,
    // pathList: [PATH.PAYMENTMANAGEMENT],
    subNav: [
      {
        title: "Payments Management",
        path: PATH.PAYMENTMANAGEMENT,
        pathList: [PATH.PAYMENTMANAGEMENT],
        icon: <BsPeople className="mr-2 font-16" />,
      },
      {
        title: "Payments Settings",
        path: PATH.PAYMENTSETTINGS,
        pathList: [PATH.PAYMENTSETTINGS],
        icon: <AiOutlineFileText className="mr-2 font-16" />,
      },
      {
        title: "Refund",
        path: PATH.PAYMENTREFUND,
        pathList: PATH.PAYMENTREFUND,
        icon: <AiOutlineFileText className="mr-2 font-16" />,
      },
    ],
  },
  {
    title: "Subscription",
    icon: <AiOutlineCreditCard size={24} />,
    // path: PATH.PAYMENTMANAGEMENT,
    // pathList: [PATH.PAYMENTMANAGEMENT],
    subNav: [
      {
        title: "Plans",
        path: PATH.ADMINSUBSCRIPTIONPLAN,
        pathList: [PATH.ADMINSUBSCRIPTIONPLAN],
        icon: <BsPeople className="mr-2 font-16" />,
      },
      {
        title: "Coupons Management",
        path: PATH.COUPONMANAGEMENT,
        pathList: [PATH.COUPONMANAGEMENT],
        icon: <RiCoupon4Line className="mr-2 font-16" />,
      },
      {
        title: "Usage",
        path: PATH.SUBSCRIPTIONUSAGE,
        pathList: PATH.SUBSCRIPTIONUSAGE,
        icon: <MdDataUsage className="mr-2 font-16" />,
      },
    ],
  },
  {
    title: "User Management",
    icon: <IoIosPeople size={24} />,
    subNav: [
      {
        title: "Physician",
        path: PATH.USERDOCTORMANAGEMENT,
        pathList: [PATH.USERDOCTORMANAGEMENT],
        icon: <GiPlagueDoctorProfile className="mr-2 font-16" />,
      },
      {
        title: "Patient",
        path: PATH.USERPATIENTMANAGEMENT,
        pathList: [PATH.USERPATIENTMANAGEMENT],
        icon: <IoPeopleCircleOutline className="mr-2 font-16" />,
      },
    ],
  },
  {
    title: "Community Forum",
    icon: <GiGlobe size={24} />,
    // path: PATH.ADMINCOMMUNITYFORUM,
    path: PATH.COMMUNITYFORUM,
    pathList: [PATH.COMMUNITYFORUM],
  },
  {
    title: "Feedback",
    icon: <VscFeedback size={24} />,
    path: PATH.ADMINFEEDBACK,
    pathList: [PATH.ADMINFEEDBACK],
  },
];
