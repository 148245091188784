import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminSubscriptionServices from "./adminSubscription.services";

const initialState = {
  subscriptionPlanSave: [],
  getAllSubscriptionPlan: [],
  getCustomerPaymentsList: [],
  patientCard: [],
  oneTimePayment: [],
  patientSubscriptionSave: [],
  cancelSubscription: [],
  getAllPatientSubscription: [],
  isError: false,
  isLoading: false,
  getAllSubscriptionPlanisLoading: false,
  patientSubscriptionSaveisLoading: false,
  patientCardisLoading: false,
  isSuccess: false,
  message: "",
};

export const subscriptionPlanSaveAction = createAsyncThunk(
  "adminSubscription/subscriptionPlanSaveAction",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await AdminSubscriptionServices.subscriptionPlanSave(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patientCardAction = createAsyncThunk(
  "adminSubscription/patientCardAction",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await AdminSubscriptionServices.patientCard(finalData);
      if (response.succeeded === true) {
        Notificiation(response.message, "success");
        if (moveToNext) {
          moveToNext(response);
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const oneTimePaymentAction = createAsyncThunk(
  "adminSubscription/oneTimePaymentAction",
  async ({ finalData, Notificiation, MoveToNext }, thunkAPI) => {
    try {
      const response = await AdminSubscriptionServices.oneTimePayment(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (MoveToNext) {
          MoveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const cancelSubscriptionAction = createAsyncThunk(
  "adminSubscription/cancelSubscriptionAction",
  async ({ finalData, Notificiation, MoveToNext }, thunkAPI) => {
    try {
      const response = await AdminSubscriptionServices.cancelSubscription(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (MoveToNext) {
          MoveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patientSubscriptionSaveAction = createAsyncThunk(
  "adminSubscription/patientSubscriptionSaveAction",
  async ({ finalData, Notificiation, MoveToNext }, thunkAPI) => {
    try {
      const response = await AdminSubscriptionServices.patientSubscriptionSave(
        finalData
      );
      if (response.succeeded === true) {
        if (MoveToNext) {
          MoveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSubscriptionPlanAction = createAsyncThunk(
  "adminSubscription/getAllSubscriptionPlanAction",
  async (data, thunkAPI) => {
    try {
      return await AdminSubscriptionServices.getAllSubscriptionPlan(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientSubscriptionAction = createAsyncThunk(
  "adminSubscription/getAllPatientSubscriptionAction",
  async (data, thunkAPI) => {
    try {
      return await AdminSubscriptionServices.getAllPatientSubscription(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCustomerPaymentsListAction = createAsyncThunk(
  "adminSubscription/getCustomerPaymentsListAction",
  async (data, thunkAPI) => {
    try {
      return await AdminSubscriptionServices.getCustomerPaymentsList(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AdminSubscriptionSlice = createSlice({
  name: "adminSubscription",
  initialState,
  reducers: {
    clearAdminSubscription: (state) => {
      state.subscriptionPlanSave = [];
      state.getAllSubscriptionPlan = [];
      state.getAllPatientSubscription = [];
      state.getCustomerPaymentsList = [];
      state.patientCard = [];
      state.cancelSubscription = [];
      state.oneTimePayment = [];
      state.patientSubscriptionSave = [];
      state.isLoading = false;
      state.patientCardisLoading = false;
      state.patientSubscriptionSaveisLoading = false;
      state.getAllSubscriptionPlanisLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscriptionPlanSaveAction.pending, (state) => {
        state.patientSubscriptionSaveisLoading = true;
      })
      .addCase(subscriptionPlanSaveAction.fulfilled, (state, action) => {
        state.patientSubscriptionSaveisLoading = false;
        state.isSuccess = true;
        state.subscriptionPlanSave = action.payload;
      })
      .addCase(subscriptionPlanSaveAction.rejected, (state, action) => {
        state.patientSubscriptionSaveisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(patientCardAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientCardAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientCard = action.payload;
      })
      .addCase(patientCardAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(cancelSubscriptionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelSubscriptionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cancelSubscription = action.payload;
      })
      .addCase(cancelSubscriptionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(oneTimePaymentAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(oneTimePaymentAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.oneTimePayment = action.payload;
      })
      .addCase(oneTimePaymentAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(patientSubscriptionSaveAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientSubscriptionSaveAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientSubscriptionSave = action.payload;
      })
      .addCase(patientSubscriptionSaveAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPatientSubscriptionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientSubscriptionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientSubscription = action.payload;
      })
      .addCase(getAllPatientSubscriptionAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getCustomerPaymentsListAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerPaymentsListAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getCustomerPaymentsList = action.payload;
      })
      .addCase(getCustomerPaymentsListAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllSubscriptionPlanAction.pending, (state) => {
        state.getAllSubscriptionPlanisLoading = true;
      })
      .addCase(getAllSubscriptionPlanAction.fulfilled, (state, action) => {
        state.getAllSubscriptionPlanisLoading = false;
        state.isSuccess = true;
        state.getAllSubscriptionPlan = action.payload;
      })
      .addCase(getAllSubscriptionPlanAction.rejected, (state, action) => {
        state.getAllSubscriptionPlanisLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearAdminSubscription } = AdminSubscriptionSlice.actions;

export default AdminSubscriptionSlice.reducer;
