import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/PatientPersonalInfo/addOrUpdate`, data);
  return response.data;
};

const getAllPatientPersonalInfo = async (data) => {
  const response = await api.get(
    "api/PatientPersonalInfo/getAllPatientPersonalInfo",
    {
      params: data,
    }
  );
  return response.data;
};

const getAllSubscribedPatientByDoctor = async (data) => {
  const response = await api.get(
    "api/PatientPersonalInfo/getAllSubscribedPatientByDoctor",
    {
      params: data,
    }
  );
  return response.data;
};

const getAllPatientByDoctor = async (data) => {
  const response = await api.get(
    "api/PatientPersonalInfo/getAllPatientByDoctor",
    {
      params: data,
    }
  );
  return response.data;
};

const getPrescriptionWithoutTokken = async (data) => {
  const response = await api.get("api/Admin/getPrescriptionWithoutTokken", {
    params: data,
  });
  return response.data.data;
};

const getPrescription = async (data) => {
  const response = await api.get("api/PatientPersonalInfo/getPrescription", {
    params: data,
  });
  return response.data.data;
};

const getPatientPersonalInfoById = async (id) => {
  const response = await api.get(
    `api/PatientPersonalInfo/getPatientPersonalInfoById?id=${id}`
  );
  return response.data.data;
};

const PatientPersonalInfoServices = {
  addOrUpdate,
  getAllPatientPersonalInfo,
  getPrescriptionWithoutTokken,
  getAllSubscribedPatientByDoctor,
  getPrescription,
  getAllPatientByDoctor,
  getPatientPersonalInfoById,
};

export default PatientPersonalInfoServices;
