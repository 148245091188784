import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/License/addOrUpdate`, data);
  return response.data;
};
const deleteLicense = async (data) => {
  const response = await api.post(`api/License/delete`, data);
  return response.data;
};
const getAllLicense = async (userId) => {
  const response = await api.get(`api/License/getAllLicense?userId=${userId}`);
  return response.data.data;
};

const LicenseServices = {
  addOrUpdate,
  getAllLicense,
  deleteLicense,
};

export default LicenseServices;
