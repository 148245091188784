import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ForgotPaswwordServices from "./forgotPassword.services";

const initialState = {
  checkEmail: [],
  updatePassword: [],
  isError: false,
  isSuccess: false,
  updatePasswordisSuccess: false,
  checkEmailisSuccess: false,
  isLoading: false,
  message: "",
};

export const checkEmailAction = createAsyncThunk(
  "checkEmail/checkEmailAction",
  async ({ email, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await ForgotPaswwordServices.checkEmail(email);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatePasswordAction = createAsyncThunk(
  "updatePassword/updatePasswordAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await ForgotPaswwordServices.updatePassword(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const forgotPasswordSlice = createSlice({
  name: "updatePassword",
  initialState,
  reducers: {
    clearforgotPassword: (state) => {
      state.checkEmail = [];
      state.updatePassword = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkEmailAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkEmailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkEmailisSuccess = true;
        state.checkEmail = action.payload;
      })
      .addCase(checkEmailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updatePasswordAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePasswordAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatePasswordisSuccess = true;
        state.updatePassword = action.payload;
      })
      .addCase(updatePasswordAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearforgotPassword } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
