import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import doctorDashboardDataServices from "./doctorDashboard.services";

const initialState = {
  doctorDashboardData: [],
  getDoctorDashboardGraph: [],
  getAdminDashboardGraph: [],
  getPatientDashboardData: [],
  getAdminDashboardData: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  graphisLoading: false,
  message: "",
};

export const getDoctorDashboardDataAction = createAsyncThunk(
  "doctorDashboardData/getDoctorDashboardDataAction",
  async ({ finalData }, thunkAPI) => {
    try {
      return await doctorDashboardDataServices.getDoctorDashboardData(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDoctorDashboardGraphAction = createAsyncThunk(
  "doctorDashboardData/getDoctorDashboardGraphAction",
  async ({ finalData }, thunkAPI) => {
    try {
      return await doctorDashboardDataServices.getDoctorDashboardGraph(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAdminDashboardDataAction = createAsyncThunk(
  "doctorDashboardData/getAdminDashboardDataAction",
  async (thunkAPI) => {
    try {
      return await doctorDashboardDataServices.getAdminDashboardData();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAdminDashboardGraphAction = createAsyncThunk(
  "doctorDashboardData/getAdminDashboardGraphAction",
  async (thunkAPI) => {
    try {
      return await doctorDashboardDataServices.getAdminDashboardGraph();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPatientDashboardDataAction = createAsyncThunk(
  "doctorDashboardData/getPatientDashboardDataAction",
  async ({ finalData }, thunkAPI) => {
    try {
      return await doctorDashboardDataServices.getPatientDashboardData(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const doctorDashboardDataSlice = createSlice({
  name: "doctorDashboardData",
  initialState,
  reducers: {
    cleardoctorDashboardData: (state) => {
      state.doctorDashboardData = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDoctorDashboardDataAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDoctorDashboardDataAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.doctorDashboardData = action.payload;
      })
      .addCase(getDoctorDashboardDataAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getPatientDashboardDataAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatientDashboardDataAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getPatientDashboardData = action.payload;
      })
      .addCase(getPatientDashboardDataAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAdminDashboardDataAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminDashboardDataAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAdminDashboardData = action.payload;
      })
      .addCase(getAdminDashboardDataAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAdminDashboardGraphAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminDashboardGraphAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAdminDashboardGraph = action.payload;
      })
      .addCase(getAdminDashboardGraphAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getDoctorDashboardGraphAction.pending, (state) => {
        state.graphisLoading = true;
      })
      .addCase(getDoctorDashboardGraphAction.fulfilled, (state, action) => {
        state.graphisLoading = false;
        state.isSuccess = true;
        state.getDoctorDashboardGraph = action.payload;
      })
      .addCase(getDoctorDashboardGraphAction.rejected, (state, action) => {
        state.graphisLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { cleardoctorDashboardData } = doctorDashboardDataSlice.actions;

export default doctorDashboardDataSlice.reducer;
