import React from "react";

const NoPage = React.lazy(() => import("./NoPageFound"));
const login = React.lazy(() => import("./Login"));
const Dashboard = React.lazy(() => import("./Dashboard"));
const SignUp = React.lazy(() => import("./SignUp"));
const PatientSignUp = React.lazy(() => import("./SignUp/PatientSignUp"));
const ForgetPassword = React.lazy(() => import("./ForgetPassword"));
const DoctorInfo = React.lazy(() => import("./Doctor/DoctorInfo"));
const PatientInfo = React.lazy(() => import("./Patient/PatientInfo"));
const PatientListing = React.lazy(() => import("./Doctor/PatientList"));
const DoctorProfile = React.lazy(() => import("./Doctor/DoctorProfile"));
const DoctorDashboard = React.lazy(() => import("./Doctor/DoctorDashboard"));
const PatientDashboard = React.lazy(() => import("./Patient/PatientDashboard"));
const AllVisits = React.lazy(() => import("./Doctor/AllVisits"));
const ViewListForVisiter = React.lazy(() =>
  import("./Doctor/AllVisits/ViewListForVisit")
);
const ViewNotes = React.lazy(() => import("./Doctor/ViewNotes"));
const CalendarView = React.lazy(() => import("./Doctor/Calendar"));
const MyEarning = React.lazy(() => import("./Doctor/MyEarning"));
const CommunityForm = React.lazy(() => import("./Doctor/CommunityForm"));
const Blogs = React.lazy(() => import("./Doctor/CommunityForm/blogs"));
const FindADoctor = React.lazy(() => import("./Patient/FindADoctor"));
const PaymentInfo = React.lazy(() => import("./Doctor/PaymentInfo"));
const ChatButton = React.lazy(() => import("./Doctor/Coversation"));
const Message = React.lazy(() => import("./Doctor/Message"));
const VideoConsultation = React.lazy(() =>
  import("./Doctor/VideoConsultation")
);
const E_Prescription = React.lazy(() =>
  import("./Doctor/ViewNotes/eprescription")
);
const LabTest = React.lazy(() => import("./Doctor/ViewNotes/labTest"));
const MyAppointments = React.lazy(() => import("./Patient/MyAppointments"));
const Notes = React.lazy(() => import("./Patient/Notes"));
const PatientView = React.lazy(() => import("./Patient/PatientView"));

const PatientChat = React.lazy(() => import("./Patient/Chat"));
const TeleConsultation = React.lazy(() => import("./Patient/TeleConsultation"));
const DoctorPatientProfile = React.lazy(() =>
  import("./Patient/DoctorProfile")
);
const DoctorSlots = React.lazy(() =>
  import("./Patient/DoctorProfile/doctorSlots")
);
const SubscriptionPlan = React.lazy(() => import("./Patient/SubscriptionPlan"));
const FamilyMembers = React.lazy(() => import("./Patient/FamilyMember"));
const AddFamilyMember = React.lazy(() =>
  import("./Patient/FamilyMember/addFamilyMember")
);
const UpdateFamilyMember = React.lazy(() =>
  import("./Patient/FamilyMember/updateFamilyMember")
);
const AppointmentSlot = React.lazy(() => import("./Patient/AppointmentSlots"));
const CommunityForum = React.lazy(() => import("./Patient/CommunityForum"));
const AdminCommunityForum = React.lazy(() =>
  import("./Admin/AdminCommunityForum")
);
const AdminDashboard = React.lazy(() => import("./Admin/AdminDashboard"));
const AdminAppointments = React.lazy(() => import("./Admin/Appointments"));
const AdminRoleSettings = React.lazy(() => import("./Admin/AdminRoleSettings"));
const AdminCreateNewRole = React.lazy(() =>
  import("./Admin/AdminRoleSettings/CreateNewRole")
);
const AdminRolePermissions = React.lazy(() =>
  import("./Admin/AdminRoleSettings/RolePermissions")
);
const EmployeeManagement = React.lazy(() =>
  import("./Admin/EmployeeManagement")
);
const CreateAnEmployee = React.lazy(() =>
  import("./Admin/EmployeeManagement/CreateAnEmployee")
);
const PaymentManagement = React.lazy(() =>
  import("./Admin/Payments/PaymentsManagement")
);
const PaymentSettings = React.lazy(() =>
  import("./Admin/Payments/PaymentSettings")
);
const Refund = React.lazy(() =>
  import("./Admin/Payments/PaymentSettings/Refund")
);
import("./Admin/Payments/PaymentsManagement");
const PhysicianManagement = React.lazy(() =>
  import("./Admin/PhysicianManagement")
);
const DoctorProfilePhysician = React.lazy(() =>
  import(
    "./Admin/PhysicianManagement/DoctorProfilePhysician/doctorProfilePhysician"
  )
);
const AdminSubscriptionPlan = React.lazy(() =>
  import("./Admin/Subscription/plan")
);
const SubscriptionUsage = React.lazy(() =>
  import("./Admin/Subscription/usage")
);
const CouponManagement = React.lazy(() =>
  import("./Admin/Subscription/coupon")
);
const CheckBoxSection = React.lazy(() =>
  import("./Admin/Subscription/CheckBoxFlow")
);
const UserDoctorManagement = React.lazy(() =>
  import("./Admin/UserManagement/Doctor")
);
const UserPatientManagement = React.lazy(() =>
  import("./Admin/UserManagement/Patient")
);
const PatientProfileManagement = React.lazy(() =>
  import("./Admin/UserManagement/Patient/PatientProfile")
);
const AdminFeedback = React.lazy(() => import("./Admin/AdminFeedback"));
const LandingSubscription = React.lazy(() => import("./LandingSubscription"));
const LandingAvatar = React.lazy(() => import("./LandingAvatar"));
const LandingEPrescription = React.lazy(() =>
  import("./LandingNotes/landingEPrescription")
);
const LandingViewNotes = React.lazy(() =>
  import("./LandingNotes/landingNotes")
);
const LandingCommunityForm = React.lazy(() =>
  import("./LandingCommunityForm/index")
);
const LandingLabTest = React.lazy(() =>
  import("./LandingNotes/landingLabTest")
);
const PlansHistory = React.lazy(() =>
  import("./Patient/SubscriptionPlan/planHistory")
);
const MyPlans = React.lazy(() => import("./Patient/SubscriptionPlan/myPlans"));
const AllSubscriberView = React.lazy(() => import("./Doctor/AllSubscriber"));
const IndividualCommunityForm = React.lazy(() =>
  import("./Patient/CommunityForum/individualForm")
);
const PatientOwnVitals = React.lazy(() => import("./Patient/PatientOwnVitals"));
const PrivacyAndPolicy = React.lazy(() => import("./PrivacyPolicy"));

const WEB_PAGES = {
  NOPAGE: NoPage,
  PRIVACYPOLICY: PrivacyAndPolicy,
  INDIVIDUALCOMMUNITYFORM: IndividualCommunityForm,
  PATIENTOWNVITALS: PatientOwnVitals,
  DOCTORINFORMATION: DoctorInfo,
  PLANSHISTORY: PlansHistory,
  ALLSUBSCRIBERVIEW: AllSubscriberView,
  MYPLANS: MyPlans,
  COMMUNITYFORUM: CommunityForum,
  LANDINGSUBSCRIPTION: LandingSubscription,
  LANDINGAVATR: LandingAvatar,
  LANDINGNOTES: LandingViewNotes,
  LANDINGEPRESCRIPTION: LandingEPrescription,
  LANDINGLABTEST: LandingLabTest,
  LANDINGCOMMUNITYFORM: LandingCommunityForm,
  ADMINFEEDBACK: AdminFeedback,
  USERPATIENTMANAGEMENT: UserPatientManagement,
  PATIENTPROFILEMANAGEMENT: PatientProfileManagement,
  USERDOCTORMANAGEMENT: UserDoctorManagement,
  CHECKBOXFLOW: CheckBoxSection,
  COUPONMANAGEMENT: CouponManagement,
  SUBSCRIPTIONUSAGE: SubscriptionUsage,
  ADMINSUBSCRIPTIONPLAN: AdminSubscriptionPlan,
  DOCTORPROFILEPHYSICIAN: DoctorProfilePhysician,
  PHYSICIANMANAGEMENT: PhysicianManagement,
  ADMINCOMMUNITYFORUM: AdminCommunityForum,
  FAMILYMEMBERS: FamilyMembers,
  ADDFAMILYMEMBER: AddFamilyMember,
  UPDATEFAMILYMEMBER: UpdateFamilyMember,
  SUBSCRIPTIONPLAN: SubscriptionPlan,
  PATIENTCHAT: PatientChat,
  DOCTORSLOTS: DoctorSlots,
  PATIENTVIEW: PatientView,
  TELECONSULTATION: TeleConsultation,
  APPOINTMENTSLOTS: AppointmentSlot,
  MESSAGE: Message,
  E_PRESCRIPTION: E_Prescription,
  LABTESTREPORT: LabTest,
  CALENDARVIEW: CalendarView,
  VIDEOCONSULTATION: VideoConsultation,
  CHAT: ChatButton,
  BLOGS: Blogs,
  PAYMENTINFO: PaymentInfo,
  MYEARNING: MyEarning,
  COMMUNITYFORM: CommunityForm,
  PATIENTLISTING: PatientListing,
  VIEWNOTES: ViewNotes,
  VIEWLISTFORVISITER: ViewListForVisiter,
  ALLVISITS: AllVisits,
  DOCTORPROFILE: DoctorProfile,
  PATIENTINFORMATION: PatientInfo,
  PATIENTDASHBOARD: PatientDashboard,
  DOCTORDASHBOARD: DoctorDashboard,
  LOGIN: login,
  SIGNUP: SignUp,
  DASHBOARD: Dashboard,
  PATIENTSIGNUP: PatientSignUp,
  FORGOTPASSWORD: ForgetPassword,
  FINDADOCTOR: FindADoctor,
  MYAPPOINTMENTS: MyAppointments,
  NOTES: Notes,
  DOCTORPATIENTPROFILE: DoctorPatientProfile,
  ADMINDASHBOARD: AdminDashboard,
  ADMINAPPOINTMENTS: AdminAppointments,
  ADMINROLESETTINGS: AdminRoleSettings,
  ADMINCREATENEWROLE: AdminCreateNewRole,
  ADMINROLEPERMISSIONS: AdminRolePermissions,
  EMPLOYEEMANAGEMENT: EmployeeManagement,
  ADMINCREATEANEMPLOYEE: CreateAnEmployee,
  PAYMENTMANAGEMENT: PaymentManagement,
  PAYMENTSETTINGS: PaymentSettings,
  PAYMENTREFUND: Refund,
};

export { WEB_PAGES };
