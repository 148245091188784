import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LanguageServices from "./language.services";

const initialState = {
  addOrUpdate: [],
  getAllUserLanguages: [],
  deleteLanguage: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateAction = createAsyncThunk(
  "language/addOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await LanguageServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllUserLanguagesAction = createAsyncThunk(
  "language/getAllUserLanguagesAction",
  async (userId, thunkAPI) => {
    try {
      return await LanguageServices.getAllUserLanguages(userId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteLanguageAction = createAsyncThunk(
  "language/deleteLanguageAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await LanguageServices.deleteLanguage(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const LanguageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    clearLanguage: (state) => {
      state.addOrUpdate = [];
      state.getAllUserLanguages = [];
      state.deleteLanguage = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllUserLanguagesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUserLanguagesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllUserLanguages = action.payload;
      })
      .addCase(getAllUserLanguagesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteLanguageAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteLanguageAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteLanguage = action.payload;
      })
      .addCase(deleteLanguageAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
