import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SearchaDoctorServices from "./searchaDoctor.services";

const initialState = {
  getAllDoctors: [],
  getAllPendingDoctors: [],
  getAllDeniedDoctors: [],
  changeAppointmentStatus: [],
  remainingAppointmentStatus: [],
  deleteAppointment: [],
  generateAppointmentId: [],
  followUpReason: [],
  patientAllVisits: [],
  getFollowUpReasonByAppointmentId: "",
  getAllAppointment: [],
  addOrUpdate: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  isLoadingAppointment: false,
  isLoadingdeleteAppointment: false,
  isReasonLoading: false,
  message: "",
};

export const getAllDoctorsAction = createAsyncThunk(
  "getAllDoctors/getAllDoctorsAction",
  async (finalData, thunkAPI) => {
    try {
      return await SearchaDoctorServices.getAllDoctors(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getFollowUpReasonByAppointmentIdAction = createAsyncThunk(
  "getAllDoctors/getFollowUpReasonByAppointmentIdAction",
  async (finalData, thunkAPI) => {
    try {
      return await SearchaDoctorServices.getFollowUpReasonByAppointmentId(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const generateAppointmentIdAction = createAsyncThunk(
  "getAllDoctors/generateAppointmentIdAction",
  async (finalData, thunkAPI) => {
    try {
      return await SearchaDoctorServices.generateAppointmentId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addOrUpdateAppointmentAction = createAsyncThunk(
  "getAllDoctors/addOrUpdateAppointmentAction",
  async ({ finalData, moveToNextAvatarData, Notificiation }, thunkAPI) => {
    try {
      const response = await SearchaDoctorServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNextAvatarData) {
          moveToNextAvatarData(
            response.data.appointmentId,
            response.data.appointmentType
          );
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const followUpReasonAction = createAsyncThunk(
  "getAllDoctors/followUpReasonAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await SearchaDoctorServices.followUpReason(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const changeAppointmentStatusAction = createAsyncThunk(
  "getAllDoctors/changeAppointmentStatusAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await SearchaDoctorServices.changeAppointmentStatus(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext(response.data.appointmentStatus);
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteAppointmentAction = createAsyncThunk(
  "getAllDoctors/deleteAppointmentAction",
  async ({ finalData, moveToNextCancel, Notificiation }, thunkAPI) => {
    try {
      const response = await SearchaDoctorServices.deleteAppointment(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNextCancel) {
          moveToNextCancel();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllPendingDoctorsAction = createAsyncThunk(
  "getAllDoctors/getAllPendingDoctorsAction",
  async (finalData, thunkAPI) => {
    try {
      return await SearchaDoctorServices.getAllDoctors(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const remainingAppointmentStatusAction = createAsyncThunk(
  "getAllDoctors/remainingAppointmentStatusAction",
  async (thunkAPI) => {
    try {
      return await SearchaDoctorServices.remainingAppointmentStatus();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllDeniedDoctorsAction = createAsyncThunk(
  "getAllDoctors/getAllDeniedDoctorsAction",
  async (finalData, thunkAPI) => {
    try {
      return await SearchaDoctorServices.getAllDoctors(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllAppointmentAction = createAsyncThunk(
  "getAllDoctors/getAllAppointmentAction",
  async (finalData, thunkAPI) => {
    try {
      return await SearchaDoctorServices.getAllAppointment(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const patientAllVisitsAction = createAsyncThunk(
  "getAllDoctors/patientAllVisitsAction",
  async (finalData, thunkAPI) => {
    try {
      return await SearchaDoctorServices.patientAllVisits(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllDoctorsSlice = createSlice({
  name: "getAllDoctors",
  initialState,
  reducers: {
    cleargetAllDoctors: (state) => {
      state.getAllDoctors = [];
      state.getAllDeniedDoctors = [];
      state.getAllPendingDoctors = [];
      state.changeAppointmentStatus = [];
      state.remainingAppointmentStatus = [];
      state.getAllAppointment = [];
      state.patientAllVisits = [];
      state.addOrUpdate = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDoctorsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDoctorsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllDoctors = action.payload;
      })
      .addCase(getAllDoctorsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getFollowUpReasonByAppointmentIdAction.pending, (state) => {
        state.isReasonLoading = true;
      })
      .addCase(
        getFollowUpReasonByAppointmentIdAction.fulfilled,
        (state, action) => {
          state.isReasonLoading = false;
          state.isSuccess = true;
          state.getFollowUpReasonByAppointmentId = action.payload;
        }
      )
      .addCase(
        getFollowUpReasonByAppointmentIdAction.rejected,
        (state, action) => {
          state.isReasonLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      )

      .addCase(generateAppointmentIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateAppointmentIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.generateAppointmentId = action.payload;
      })
      .addCase(generateAppointmentIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(addOrUpdateAppointmentAction.pending, (state) => {
        state.isLoadingAppointment = true;
      })
      .addCase(addOrUpdateAppointmentAction.fulfilled, (state, action) => {
        state.isLoadingAppointment = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAppointmentAction.rejected, (state, action) => {
        state.isLoadingAppointment = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(followUpReasonAction.pending, (state) => {
        state.isLoadingAppointment = true;
      })
      .addCase(followUpReasonAction.fulfilled, (state, action) => {
        state.isLoadingAppointment = false;
        state.isSuccess = true;
        state.followUpReason = action.payload;
      })
      .addCase(followUpReasonAction.rejected, (state, action) => {
        state.isLoadingAppointment = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllAppointmentAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAppointmentAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllAppointment = action.payload;
      })
      .addCase(getAllAppointmentAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(patientAllVisitsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patientAllVisitsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientAllVisits = action.payload;
      })
      .addCase(patientAllVisitsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(remainingAppointmentStatusAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(remainingAppointmentStatusAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.remainingAppointmentStatus = action.payload;
      })
      .addCase(remainingAppointmentStatusAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(changeAppointmentStatusAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeAppointmentStatusAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.changeAppointmentStatus = action.payload;
      })
      .addCase(changeAppointmentStatusAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteAppointmentAction.pending, (state) => {
        state.isLoadingdeleteAppointment = true;
      })
      .addCase(deleteAppointmentAction.fulfilled, (state, action) => {
        state.isLoadingdeleteAppointment = false;
        state.isSuccess = true;
        state.deleteAppointment = action.payload;
      })
      .addCase(deleteAppointmentAction.rejected, (state, action) => {
        state.isLoadingdeleteAppointment = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPendingDoctorsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPendingDoctorsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPendingDoctors = action.payload;
      })
      .addCase(getAllPendingDoctorsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllDeniedDoctorsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDeniedDoctorsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllDeniedDoctors = action.payload;
      })
      .addCase(getAllDeniedDoctorsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { cleargetAllDoctors } = getAllDoctorsSlice.actions;

export default getAllDoctorsSlice.reducer;
