import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PatientMakeNotesServices from "./videoConsultation.services";

const initialState = {
  addOrUpdate: [],
  getVitalListByPatientId: [],
  getAllPatientVital: [],
  getAllPatientVitalList: [],
  getAllLabTest: [],
  soapNotesAddOrUpdate: [],
  ObservationAddOrUpdate: [],
  getAllDoctorObservation: [],
  getVitalByPatientId: [],
  getAllSoapNote: [],
  PatientLabTestAddOrUpdate: [],
  isError: false,
  isLoading: false,
  getVitalByPatientIdisLoading: false,
  getVitalListByPatientIdisLoading:false,
  isSuccess: false,
  message: "",
};

export const addOrUpdateAction = createAsyncThunk(
  "patientMakeNotes/addOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientMakeNotesServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const soapNotesAddOrUpdateAction = createAsyncThunk(
  "patientMakeNotes/soapNotesAddOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientMakeNotesServices.soapNotesAddOrUpdate(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ObservationAddOrUpdateAction = createAsyncThunk(
  "patientMakeNotes/ObservationAddOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientMakeNotesServices.ObservationAddOrUpdate(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const PatientLabTestAddOrUpdateAction = createAsyncThunk(
  "patientMakeNotes/PatientLabTestAddOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await PatientMakeNotesServices.PatientLabTestAddOrUpdate(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientVitalAction = createAsyncThunk(
  "patientMakeNotes/getAllPatientVitalAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientMakeNotesServices.getAllPatientVital(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getVitalListByPatientIdAction = createAsyncThunk(
  "patientMakeNotes/getVitalListByPatientIdAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientMakeNotesServices.getVitalListByPatientId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatientVitalListAction = createAsyncThunk(
  "patientMakeNotes/getAllPatientVitalListAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientMakeNotesServices.getAllPatientVitalList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getVitalByPatientIdAction = createAsyncThunk(
  "patientMakeNotes/getVitalByPatientIdAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientMakeNotesServices.getVitalByPatientId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllDoctorObservationAction = createAsyncThunk(
  "patientMakeNotes/getAllDoctorObservationAction",
  async (data, thunkAPI) => {
    try {
      return await PatientMakeNotesServices.getAllDoctorObservation(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSoapNoteAction = createAsyncThunk(
  "patientMakeNotes/getAllSoapNoteAction",
  async (data, thunkAPI) => {
    try {
      return await PatientMakeNotesServices.getAllSoapNote(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllLabTestAction = createAsyncThunk(
  "patientMakeNotes/getAllLabTestAction",
  async (finalData, thunkAPI) => {
    try {
      return await PatientMakeNotesServices.getAllLabTest(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const PatientMakeNotesSlice = createSlice({
  name: "patientMakeNotes",
  initialState,
  reducers: {
    clearPatientMakeNotes: (state) => {
      state.addOrUpdate = [];
      state.soapNotesAddOrUpdate = [];
      state.ObservationAddOrUpdate = [];
      state.getAllDoctorObservation = [];
      state.getAllPatientVitalList = [];
      state.getAllSoapNote = [];
      state.PatientLabTestAddOrUpdate = [];
      state.getAllPatientVital = [];
      state.getVitalListByPatientId = [];
      state.isLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPatientVitalAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientVitalAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientVital = action.payload;
      })
      .addCase(getAllPatientVitalAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getVitalByPatientIdAction.pending, (state) => {
        state.getVitalByPatientIdisLoading = true;
      })
      .addCase(getVitalByPatientIdAction.fulfilled, (state, action) => {
        state.getVitalByPatientIdisLoading = false;
        state.isSuccess = true;
        state.getVitalByPatientId = action.payload;
      })
      .addCase(getVitalByPatientIdAction.rejected, (state, action) => {
        state.getVitalByPatientIdisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getVitalListByPatientIdAction.pending, (state) => {
        state.getVitalListByPatientIdisLoading = true;
      })
      .addCase(getVitalListByPatientIdAction.fulfilled, (state, action) => {
        state.getVitalListByPatientIdisLoading = false;
        state.isSuccess = true;
        state.getVitalListByPatientId = action.payload;
      })
      .addCase(getVitalListByPatientIdAction.rejected, (state, action) => {
        state.getVitalListByPatientIdisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllPatientVitalListAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPatientVitalListAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientVitalList = action.payload;
      })
      .addCase(getAllPatientVitalListAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(ObservationAddOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ObservationAddOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ObservationAddOrUpdate = action.payload;
      })
      .addCase(ObservationAddOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(PatientLabTestAddOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(PatientLabTestAddOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.PatientLabTestAddOrUpdate = action.payload;
      })
      .addCase(PatientLabTestAddOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllLabTestAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLabTestAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllLabTest = action.payload;
      })
      .addCase(getAllLabTestAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllDoctorObservationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDoctorObservationAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllDoctorObservation = action.payload;
      })
      .addCase(getAllDoctorObservationAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllSoapNoteAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSoapNoteAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllSoapNote = action.payload;
      })
      .addCase(getAllSoapNoteAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(soapNotesAddOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(soapNotesAddOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.soapNotesAddOrUpdate = action.payload;
      })
      .addCase(soapNotesAddOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearPatientMakeNotes } = PatientMakeNotesSlice.actions;

export default PatientMakeNotesSlice.reducer;
