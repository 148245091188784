import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/Slot/addOrUpdate`, data);
  return response.data;
};

const getSlotsByDoctor = async (doctorId) => {
  const response = await api.get(
    `api/Slot/getSlotsByDoctor?doctorId=${doctorId}`
  );
  return response.data.data;
};

const SlotsServices = {
  addOrUpdate,
  getSlotsByDoctor,
};

export default SlotsServices;
