import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FeedbackServices from "../../admin/feedback/feedback.services";
import CommunityFormServices from "./communityForm.services";

const initialState = {
  communityFormaddOrUpdate: [],
  getAllCommunityFormByDoctor: [],
  saveLike: [],
  getAllComments: [],
  subscribedDoctor: [],
  publishedForm: [],
  blockedArticle: [],
  saveComment: [],
  getAllFeedbackDoctor: [],
  getAllCommunitydoctorFormByDoctor: [],
  subscribedIndividualDoctor: [],
  getCommunityFormById: [],
  saveIndividualLike: [],
  getAllCommunityForm: [],
  deleteArticle: [],
  isError: false,
  communityisLoading: false,
  isLoading: false,
  deleteisLoading: false,
  isSuccess: false,
  message: "",
};

export const communityFormaddOrUpdateAction = createAsyncThunk(
  "communityform/communityFormaddOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await CommunityFormServices.communityFormaddOrUpdate(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveLikeAction = createAsyncThunk(
  "communityform/saveLikeAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await CommunityFormServices.saveLike(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const publishedFormAction = createAsyncThunk(
  "communityform/publishedFormAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await CommunityFormServices.publishedForm(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveIndividualLikeAction = createAsyncThunk(
  "communityform/saveIndividualLikeAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await CommunityFormServices.saveIndividualLike(
        finalData
      );
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteArticleAction = createAsyncThunk(
  "communityform/deleteArticleAction",
  async ({ finalData, Notificiation, moveToNext }, thunkAPI) => {
    try {
      const response = await CommunityFormServices.deleteArticle(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveCommentAction = createAsyncThunk(
  "communityform/saveCommentAction",
  async ({ finalData, moveToNextComment }, thunkAPI) => {
    try {
      const response = await CommunityFormServices.saveComment(finalData);
      if (response.succeeded === true) {
        if (moveToNextComment) {
          moveToNextComment();
        }
      } else {
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscribedDoctorAction = createAsyncThunk(
  "communityform/subscribedDoctorAction",
  async ({ finalData, moveToNextSubscribe }, thunkAPI) => {
    try {
      const response = await CommunityFormServices.subscribedDoctor(finalData);
      if (response.succeeded === true) {
        // if (Notificiation) {
        //   Notificiation(response.message, "success");
        // }
        if (moveToNextSubscribe) {
          moveToNextSubscribe();
        }
      } else {
        // Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscribedIndividualDoctorAction = createAsyncThunk(
  "communityform/subscribedIndividualDoctorAction",
  async ({ finalData, moveToNextSubscribe }, thunkAPI) => {
    try {
      const response = await CommunityFormServices.subscribedIndividualDoctor(
        finalData
      );
      if (response.succeeded === true) {
        // if (Notificiation) {
        //   Notificiation(response.message, "success");
        // }
        if (moveToNextSubscribe) {
          moveToNextSubscribe();
        }
      } else {
        // Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const blockedArticleAction = createAsyncThunk(
  "communityform/blockedArticleAction",
  async ({ finalData, moveToNextBlock }, thunkAPI) => {
    try {
      const response = await CommunityFormServices.blockedArticle(finalData);
      if (response.succeeded === true) {
        // if (Notificiation) {
        //   Notificiation(response.message, "success");
        // }
        if (moveToNextBlock) {
          moveToNextBlock();
        }
      } else {
        // Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCommunityFormByDoctorAction = createAsyncThunk(
  "communityform/getAllCommunityFormByDoctorAction",
  async (finalData, thunkAPI) => {
    try {
      return await CommunityFormServices.getAllCommunityFormByDoctor(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCommunitydoctorFormByDoctorAction = createAsyncThunk(
  "communityform/getAllCommunitydoctorFormByDoctorAction",
  async (finalData, thunkAPI) => {
    try {
      return await CommunityFormServices.getAllCommunitydoctorFormByDoctor(
        finalData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCommunityFormByIdAction = createAsyncThunk(
  "communityform/getCommunityFormByIdAction",
  async (finalData, thunkAPI) => {
    try {
      return await CommunityFormServices.getCommunityFormById(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCommentsAction = createAsyncThunk(
  "communityform/getAllCommentsAction",
  async (finalData, thunkAPI) => {
    try {
      return await CommunityFormServices.getAllComments(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCommunityFormAction = createAsyncThunk(
  "communityform/getAllCommunityFormAction",
  async (finalData, thunkAPI) => {
    try {
      return await CommunityFormServices.getAllCommunityForm(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllFeedbackDoctorAction = createAsyncThunk(
  "feedback/getAllFeedbackDoctorAction",
  async (finalData, thunkAPI) => {
    try {
      return await CommunityFormServices.getAllFeedbackDoctor(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CommunityFormSlice = createSlice({
  name: "communityform",
  initialState,
  reducers: {
    clearCommunityForm: (state) => {
      state.communityisLoading = false;
      state.isFailure = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(communityFormaddOrUpdateAction.pending, (state) => {
        state.communityisLoading = true;
      })
      .addCase(communityFormaddOrUpdateAction.fulfilled, (state, action) => {
        state.communityisLoading = false;
        state.isSuccess = true;
        state.communityFormaddOrUpdate = action.payload;
      })
      .addCase(communityFormaddOrUpdateAction.rejected, (state, action) => {
        state.communityisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllFeedbackDoctorAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFeedbackDoctorAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllFeedbackDoctor = action.payload;
      })
      .addCase(getAllFeedbackDoctorAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteArticleAction.pending, (state) => {
        state.deleteisLoading = true;
      })
      .addCase(deleteArticleAction.fulfilled, (state, action) => {
        state.deleteisLoading = false;
        state.isSuccess = true;
        state.deleteArticle = action.payload;
      })
      .addCase(deleteArticleAction.rejected, (state, action) => {
        state.deleteisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllCommunityFormByDoctorAction.pending, (state) => {
        state.communityisLoading = true;
      })
      .addCase(getAllCommunityFormByDoctorAction.fulfilled, (state, action) => {
        state.communityisLoading = false;
        state.isSuccess = true;
        state.getAllCommunityFormByDoctor = action.payload;
      })
      .addCase(getAllCommunityFormByDoctorAction.rejected, (state, action) => {
        state.communityisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(publishedFormAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(publishedFormAction.fulfilled, (state, action) => {
        let publishList = state?.getAllCommunityFormByDoctor?.data;
        let updatedList = []; // Create a new array to hold the updated values
        for (let i = 0; i < publishList?.length; i++) {
          if (publishList[i].id === action.payload.data.communityFormId) {
            if (publishList[i].isPublish === "Published") {
              publishList[i].isPublish = "Draft";
            } else if (publishList[i].isPublish === "Draft") {
              publishList[i].isPublish = "Published";
            }
          }
          updatedList.push(publishList[i]); // Add the updated item to the new array
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.publishedForm = action.payload;
        state.getAllCommunityFormByDoctor.data = updatedList; // Assign the updated array to the state
      })
      .addCase(publishedFormAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(blockedArticleAction.pending, (state) => {
        state.deleteisLoading = true;
      })
      .addCase(blockedArticleAction.fulfilled, (state, action) => {
        state.deleteisLoading = false;
        state.isSuccess = true;
        state.blockedArticle = action.payload;
      })
      .addCase(blockedArticleAction.rejected, (state, action) => {
        state.deleteisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(subscribedDoctorAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subscribedDoctorAction.fulfilled, (state, action) => {
        let subscribedList = state.getAllFeedbackDoctor;
        for (let i = 0; i < subscribedList.length; i++) {
          if (subscribedList[i].doctorId === action.payload.data.doctorId) {
            subscribedList[i].isDoctorSubscribed =
              !subscribedList[i].isDoctorSubscribed;
          }
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.subscribedDoctor = action.payload;
        state.getAllFeedbackDoctor = subscribedList;
      })
      .addCase(subscribedDoctorAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(subscribedIndividualDoctorAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subscribedIndividualDoctorAction.fulfilled, (state, action) => {
        let subscribedList = state.getCommunityFormById.data;
        if (subscribedList.doctorId === action.payload.data.doctorId) {
          subscribedList.patientSubscribed = !subscribedList.patientSubscribed;
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.subscribedIndividualDoctor = action.payload;
        state.getCommunityFormById.data = subscribedList;
      })
      .addCase(subscribedIndividualDoctorAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllCommunityFormAction.pending, (state) => {
        state.communityisLoading = true;
      })
      .addCase(getAllCommunityFormAction.fulfilled, (state, action) => {
        state.communityisLoading = false;
        state.isSuccess = true;
        state.getAllCommunityForm = action.payload;
      })
      .addCase(getAllCommunityFormAction.rejected, (state, action) => {
        state.communityisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllCommunitydoctorFormByDoctorAction.pending, (state) => {
        state.communityisLoading = true;
      })
      .addCase(
        getAllCommunitydoctorFormByDoctorAction.fulfilled,
        (state, action) => {
          state.communityisLoading = false;
          state.isSuccess = true;
          state.getAllCommunitydoctorFormByDoctor = action.payload;
        }
      )
      .addCase(
        getAllCommunitydoctorFormByDoctorAction.rejected,
        (state, action) => {
          state.communityisLoading = false;
          state.isError = true;
          state.message = action.payload;
        }
      )

      .addCase(getCommunityFormByIdAction.pending, (state) => {
        state.communityisLoading = true;
      })
      .addCase(getCommunityFormByIdAction.fulfilled, (state, action) => {
        state.communityisLoading = false;
        state.isSuccess = true;
        state.getCommunityFormById = action.payload;
      })
      .addCase(getCommunityFormByIdAction.rejected, (state, action) => {
        state.communityisLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllCommentsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCommentsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllComments = action.payload;
      })
      .addCase(getAllCommentsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(saveCommentAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveCommentAction.fulfilled, (state, action) => {
        // let FinalList = [];
        // let list = state.getAllComments;
        // for (let i = 0; i < list.length; i++) {
        //   if (list[i].communityFormId === action.payload.data.communityFormId) {
        //     FinalList.push(...state.getAllComments, action.payload.data);
        //     // list[i].patientIsLiked = !list[i].patientIsLiked;
        //   }
        // }
        state.isLoading = false;
        state.isSuccess = true;
        state.saveComment = action.payload;
        // state.getAllComments = FinalList;
        // state.saveComment = list;
      })
      .addCase(saveCommentAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(saveLikeAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveLikeAction.fulfilled, (state, action) => {
        let list = state.getAllCommunitydoctorFormByDoctor;
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === action.payload.data.communityFormId) {
            list[i].patientIsLiked = !list[i].patientIsLiked;
            if (list[i].patientIsLiked) {
              list[i].likesCount += 1;
            } else {
              list[i].likesCount -= 1;
            }
            console.log("list[i].likesCount", list[i].likesCount);
          }
        }

        let communityList = state.getAllCommunityForm.data;
        for (let i = 0; i < communityList.length; i++) {
          if (communityList[i].id === action.payload.data.communityFormId) {
            communityList[i].patientIsLiked = !communityList[i].patientIsLiked;
            if (communityList[i].patientIsLiked) {
              communityList[i].likesCount += 1;
            } else {
              communityList[i].likesCount -= 1;
            }
            console.log(
              "communityList[i].likesCount",
              communityList[i].likesCount
            );
          }
        }

        state.isLoading = false;
        state.isSuccess = true;
        state.saveLike = action.payload;
        state.getAllCommunitydoctorFormByDoctor = list;
        state.getAllCommunityForm.data = communityList;
      })
      .addCase(saveLikeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(saveIndividualLikeAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveIndividualLikeAction.fulfilled, (state, action) => {
        let singleCommunitylist = state.getCommunityFormById.data;
        if (singleCommunitylist.id === action.payload.data.communityFormId) {
          singleCommunitylist.patientIsLiked =
            !singleCommunitylist.patientIsLiked;
          if (singleCommunitylist.patientIsLiked) {
            singleCommunitylist.likesCount += 1;
          } else {
            singleCommunitylist.likesCount -= 1;
          }
          console.log(
            "singleCommunitylist.likesCount",
            singleCommunitylist.likesCount
          );
        }
        console.log("singleCommunitylist", singleCommunitylist?.likesCount);

        state.isLoading = false;
        state.isSuccess = true;
        state.saveIndividualLike = action.payload;
        state.getCommunityFormById.data = singleCommunitylist;
      })
      .addCase(saveIndividualLikeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearCommunityForm } = CommunityFormSlice.actions;

export default CommunityFormSlice.reducer;
