import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/Award/addOrUpdate`, data);
  return response.data;
};
const deleteAward = async (data) => {
  const response = await api.post(`api/Award/delete`, data);
  return response.data;
};
const getAllAwards = async (data) => {
  const response = await api.get(
    `api/Award/getAllAwards?userId=${data.userId}`
  );
  return response.data.data;
};

const AwardServices = {
  addOrUpdate,
  getAllAwards,
  deleteAward,
};

export default AwardServices;
