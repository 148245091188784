import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/IllnessHistory/addOrUpdate`, data);
  return response.data;
};
const deleteIllnessHistory = async (data) => {
  const response = await api.post(`api/IllnessHistory/delete`, data);
  return response.data;
};
const getAllIllnessHistory = async (patientId) => {
  const response = await api.get(
    `api/IllnessHistory/getAllIllnessHistory?patientId=${patientId}`
  );
  return response.data.data;
};

const IllnessHistoryServices = {
  addOrUpdate,
  getAllIllnessHistory,
  deleteIllnessHistory,
};

export default IllnessHistoryServices;
