import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { BsArrowRightShort, BsArrowDownShort } from "react-icons/bs";
import { IoMdArrowDropright } from "react-icons/io";
import { MdOutlineArrowDropDown } from "react-icons/md";

export default function SubMenu(props) {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => {
    setSubnav(!subnav);
  };
  let location = useLocation();
  return (
    <li className="sidebar-parent-class">
      <li
        className={`d-flex align-items-center justify-content-start ${
          props?.item?.path?.includes(location?.pathname) ? "active" : ""
        }`}
      >
        {props?.item?.icon}
        <Link
          onClick={showSubnav}
          to={{ pathname: props?.item?.path ? props.item.path : "" }}
          className="d-flex align-items-center justify-content-between"
        >
          <span>{props?.item?.title}</span>
          {props?.item?.subNav && subnav ? (
            <MdOutlineArrowDropDown
              className="ml-auto right_arrow_hide"
              size={20}
            />
          ) : props.item.subNav ? (
            <IoMdArrowDropright
              className="ml-auto right_arrow_hide"
              size={20}
            />
          ) : null}
        </Link>
      </li>
      <ul className={` d-block`}>
        {subnav &&
          props.item.subNav?.map((navItem, idx) => {
            return (
              <React.Fragment>
                {navItem.subNav ? (
                  <SubMenu key={idx} item={navItem} />
                ) : (
                  <li
                    className={` ${
                      navItem?.pathList?.includes(location.pathname)
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="d-flex align-items-center justiy-content-center">
                      <span className="nav-item-new-class-style">
                        {navItem?.icon}
                      </span>
                      <Link
                        className={` ${
                          navItem?.pathList?.includes(location.pathname)
                            ? ""
                            : "text-dark"
                        }`}
                        key={idx}
                        to={{ pathname: navItem?.path }}
                        onClick={navItem?.onClick}
                      >
                        {/* <a href="https://aerostudies.com/kbm/"> */}

                        <div className="d-flex align-items-center margin-left-style-for-navbar justiy-content-center">
                          <div className="SubnavLabel">{navItem.title}</div>
                        </div>
                        {/* </a> */}
                      </Link>
                    </div>
                  </li>
                )}
              </React.Fragment>
            );
          })}
      </ul>
    </li>
  );
}
