import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/userLanguage/addOrUpdate`, data);
  return response.data;
};
const deleteLanguage = async (data) => {
  const response = await api.post(`api/userLanguage/delete`, data);
  return response.data;
};
const getAllUserLanguages = async (userId) => {
  const response = await api.get(
    `api/userLanguage/getAllUserLanguages?userId=${userId}`
  );
  return response.data.data;
};

const LanguageServices = {
  addOrUpdate,
  getAllUserLanguages,
  deleteLanguage,
};

export default LanguageServices;
