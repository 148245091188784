import api from "../../../../../assets/genericComponents/API";

const getGeneralInformationById = async (userId) => {
  const response = await api.get(
    `api/GeneralInformation/getGeneralInformationById?userId=${userId}`
  );
  return response.data.data;
};
const getDoctorSignatureById = async (userId) => {
  const response = await api.get(
    `api/GeneralInformation/getDoctorSignatureById?userId=${userId}`
  );
  return response.data.data;
};
const updateGeneralInfomartion = async (data) => {
  const response = await api.post(
    `api/GeneralInformation/updateGeneralInfomartion`,
    data
  );
  return response.data;
};
const updateDoctorSignature = async (data) => {
  const response = await api.post(
    `api/GeneralInformation/updateDoctorSignature`,
    data
  );
  return response.data;
};

const GeneralInformationServices = {
  getGeneralInformationById,
  getDoctorSignatureById,
  updateGeneralInfomartion,
  updateDoctorSignature,
};

export default GeneralInformationServices;
