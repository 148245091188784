import api from "../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/AvatarCoordinate/addOrUpdate`, data);
  return response.data;
};
const getAllAvatarCoordinate = async (data) => {
  const response = await api.get(
    `api/AvatarCoordinate/getAllAvatarCoordinate?appointmentId=${data.appointmentId}`
  );
  return response.data.data;
};

const AvatarCoordinateServices = {
  addOrUpdate,
  getAllAvatarCoordinate,
};

export default AvatarCoordinateServices;
