import api from "../../../../assets/genericComponents/API";

const createEmployee = async (data) => {
  const response = await api.post(`api/Staff/addOrUpdate`, data);
  return response.data;
};

const getAllStaff = async (data) => {
  const response = await api.get("api/Staff/getAllStaff", {
    params: data,
  });
  return response.data;
};

const getStaffById = async (userId) => {
  const response = await api.get(`api/Staff/getStaffId?id=${userId}`);
  return response.data;
};

const deleteEmployee = async (userId) => {
  const response = await api.post(`api/Staff/deleteStaff?staffId=${userId}`);
  return response.data;
};

const EmployeeManagementServices = {
  createEmployee,
  getAllStaff,
  getStaffById,
  deleteEmployee,
  //   suspendedUser,
};

export default EmployeeManagementServices;
