import api from "../../../../../assets/genericComponents/API";

const addOrUpdate = async (data) => {
  const response = await api.post(`api/PatientPhysician/addOrUpdate`, data);
  return response.data;
};
const deletePatientPhysician = async (data) => {
  const response = await api.post(`api/PatientPhysician/delete`, data);
  return response.data;
};
const getAllPatPhysicians = async () => {
  const response = await api.get(
    `api/PatientPhysician/getAllPatientPhysicians`
  );
  return response.data.data;
};

const PatientPhysicianServices = {
  addOrUpdate,
  getAllPatPhysicians,
  deletePatientPhysician,
};

export default PatientPhysicianServices;
