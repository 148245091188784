import API from "../../../assets/genericComponents/API";

const signUpProcess = async (data) => {
  const response = await API.post(`api/Account/register`, data);
  return response.data;
};
const approvedUser = async (data) => {
  const response = await API.post(`api/Account/approvedUser`, data);
  return response.data;
};
const switchUser = async (data) => {
  const response = await API.post(`api/FamilyMember/switchUser`, data);
  return response.data;
};
const doctorOnBoarding = async () => {
  const response = await API.post(`api/Account/doctorOnBoarding`);
  return response.data.data;
};
const patientOnBoarding = async () => {
  const response = await API.post(`api/Account/patientOnBoarding`);
  return response.data.data;
};
const reSendOtpCode = async (email) => {
  const response = await API.get(`api/Account/resendOTPCode?email=${email}`);
  return response.data;
};
const isEmailExist = async (emailAddress) => {
  const response = await API.get(
    `api/Account/isEmailExist?emailAddress=${emailAddress}`
  );
  return response.data.data;
};
const verifyOTPCode = async (email, code) => {
  const response = await API.get(
    `api/Account/verifyOTPCode?email=${email}&code=${code}`
  );
  return response.data;
};

const SignUpServices = {
  signUpProcess,
  reSendOtpCode,
  patientOnBoarding,
  switchUser,
  isEmailExist,
  doctorOnBoarding,
  approvedUser,
  verifyOTPCode,
};

export default SignUpServices;
