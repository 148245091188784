import api from "../../../../assets/genericComponents/API";

const createDoctor = async (data) => {
  const response = await api.post(`api/Admin/createDoctor`, data);
  return response.data;
};
const suspendedUser = async (data) => {
  const response = await api.post(`api/Admin/suspendedUser`, data);
  return response.data;
};
const getAllStaffForAdmin = async (data) => {
  const response = await api.get("api/Staff/getAllStaff", {
    params: data,
  });
  return response.data;
};

const AdminManagementServices = {
  createDoctor,
  getAllStaffForAdmin,
  suspendedUser,
};

export default AdminManagementServices;
