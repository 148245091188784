// A wrapper for <Route> that redirects to the login

import React from "react";
import { Navigate } from "react-router-dom";
import { PATH, ROLEID } from "../../config";
import { useAuth } from "./ProvideAuth";
function PublicRoute({ children }) {
  let auth = useAuth();
  return (
    <React.Fragment>
      {auth?.intely_health_user?.roleId === ROLEID.DOCTOR ? (
        <Navigate to={PATH.DOCTORDASHBOARD} replace />
      ) : (
        <>
          {auth?.intely_health_user?.roleId === ROLEID.PATIENT ? (
            <Navigate to={PATH.PATIENTDASHBOARD} replace />
          ) : (
            <>
              {auth?.intely_health_user?.roleId === ROLEID.ADMIN ? (
                <Navigate to={PATH.ADMINDASHBOARD} replace />
              ) : (
                <>
                  {auth?.intely_health_user?.roleId === ROLEID.STAFF ? (
                    <Navigate to={PATH.ADMINDASHBOARD} replace />
                  ) : (
                    <>{children}</>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {/* 
      {auth.Intely_Health_user && auth.Intely_Health_user.token ? (
        <Navigate to={PATH.DOCTORDASHBOARD} replace />
      ) : (
        <>{children}</>
      )} */}
    </React.Fragment>
  );
}
export default PublicRoute;
