import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AwardServices from "./award.services";

const initialState = {
  addOrUpdate: [],
  getAllAwards: [],
  deleteAward: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addOrUpdateAction = createAsyncThunk(
  "award/addOrUpdateAction",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await AwardServices.addOrUpdate(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllAwardsAction = createAsyncThunk(
  "award/getAllAwardsAction",
  async (data, thunkAPI) => {
    try {
      return await AwardServices.getAllAwards(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteAwardAction = createAsyncThunk(
  "award/deleteAward",
  async ({ finalData, moveToNext, Notificiation }, thunkAPI) => {
    try {
      const response = await AwardServices.deleteAward(finalData);
      if (response.succeeded === true) {
        if (Notificiation) {
          Notificiation(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        Notificiation(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const AwardSlice = createSlice({
  name: "award",
  initialState,
  reducers: {
    clearAward: (state) => {
      state.addOrUpdate = [];
      state.getAllAwards = [];
      state.deleteAward = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isFailure = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addOrUpdate = action.payload;
      })
      .addCase(addOrUpdateAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllAwardsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAwardsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllAwards = action.payload;
      })
      .addCase(getAllAwardsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteAwardAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAwardAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteAward = action.payload;
      })
      .addCase(deleteAwardAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { clearAward } = AwardSlice.actions;

export default AwardSlice.reducer;
