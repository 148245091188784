import ProfileLogo from "./Ellipse 9.jpg";
import IntelyHealthLogo from "./IntelyHealthLogo.png";
import IntelyHealthBackGround from "./IntelyHealthBackGround.png";
import AppStoreBadge from "./AppStoreBadge.png";
import GooglePlay from "./GooglePlay.png";
import VectorName from "./VectorName.png";
import call from "./call.png";
import email from "./email.png";
import languag from "./languag.png";
import location from "./location.png";
import password from "./password.png";
import TickMark from "./TickMark.png";
import Gender from "./Gender.png";
import Date from "./Date.png";
import forgotpassword from "./forgotpassword.png";
import Verification from "./Verification.png";
import Maskgroup from "./Maskgroup.png";
import ServiceRegion from "./ServiceRegion.png";
import Copy from "./Copy.png";
import SignatureRecognition from "./SignatureRecognition.png";
import payments from "./payments.png";
import Relations from "./Relations.png";
import Ladydoctor from "./Ladydoctor.png";
import Labfile from "./Labfile.png";
import physician from "./physician.png";
import underReview from "./underReview.png";
import logoGreen from "./logoGreen.png";
import profile from "./profile.png";
import alergy from "./alergy.png";
import appointment from "./appointment.png";
import drugs from "./drugs.png";
import medication from "./medication.png";
import myDoctor from "./myDoctor.png";
import preganancy from "./preganancy.png";
import qrCode from "./qrCode.png";
import paypal from "./paypal.png";
import stripe from "./stripe.png";
import doctorSignature from "./doctorSignature.png";
import uploadblog from "./uploadblog.png";
import writeblog from "./writeblog.png";
import noimagefound from "./noimagefound.png";
import salary from "./salary.png";
import mastercard from "./mastercard.png";
import paymentmethod from "./paymentmethod.png";
import checkmark from "./checkmark.png";
import bloodpressure from "./bloodpressure.png";
import heartbeat from "./heartbeat.png";
import height from "./height.png";
import sugarlevel from "./sugarlevel.png";
import weight from "./weight.png";
import doctor from "./doctor.png";
import patient from "./patient.png";
import community1 from "./community1.png";
import community2 from "./community2.png";
import bodyAvatar from "./bodyAvatar.png";
import frontBody from "./front-body.png";
import backBody from "./back-body.png";
import earning from "./earning.png";
import communityForm from "./communityForm.png";
import allVisit from "./allVisits.png";
import allPatient from "./allPatient.png";
import cvcCard from "./cvcCard.png";
import pic2 from "./pic2.png";
import preg from "./preg.png";
import ginfo from "./ginfo.png";
import FemaleDoctor from "./femaleDoctor.png";
import PatientAvatar from "./patientAvatar.png";
import StaffAvatar from "./staffAvatar.png";
import appointmentSuccess from "./appointmentSuccess.png";
import paymenyProcess from "./paymenyProcess.png";
import moreInfo from "./moreInfo.png";
import AdminRoleImage from "./roleSettingsImage.png";
import landingSubscription from "./landingSubscription.png";
import modala from "./modela.png";
import modalb from "./modelb.png";
import modalafe from "./modelafe.png";
import modalbfe from "./modelbfe.png";
import noimage from "./noimage.png";
import default_image from "./default_image.png";
import reason from "./download.jpg";
import resetIcon from "./resetIcon.png";

export const IMAGES = {
  COMMUNITY1: community1,
  REASONMODAL: reason,
  // NOIMAGE: noimage,
  NOIMAGE: default_image,
  FRONTBODY: frontBody,
  LANDINGSUBSCRIPTION: landingSubscription,
  APPOINTMENTSUCCESS: appointmentSuccess,
  MODELA: modala,
  MODELB: modalb,
  MODELAFE: modalafe,
  MODELBFE: modalbfe,
  MOREINFO: moreInfo,
  PAYMENTPROCESS: paymenyProcess,
  EARNING: earning,
  DOCPICTILES: pic2,
  GENERALINFO: ginfo,
  PREGANANCY: preg,
  CVCCARD: cvcCard,
  COMMUNITYFORM: communityForm,
  ALLVISIT: allVisit,
  ALLPATIENT: allPatient,
  BACKBODY: backBody,
  BODYAVATAR: bodyAvatar,
  COMMUNITY2: community2,
  PROFILELOGO: ProfileLogo,
  TICKMARK: TickMark,
  CHECKMARK: checkmark,
  DOCTOR: doctor,
  PATIENT: patient,
  BLODPRESSURE: bloodpressure,
  HEARTBEAT: heartbeat,
  HEIGHT: height,
  SUGARLEVEL: sugarlevel,
  WEIGHT: weight,
  QRCODE: qrCode,
  PAYMENTMETHIO: paymentmethod,
  MASTERCARD: mastercard,
  PAYPAL: paypal,
  UPLOADBLOG: uploadblog,
  SALARY: salary,
  WRITEBLOG: writeblog,
  NOIMAGEFOUND: noimagefound,
  DOCTORSIGNATURE: doctorSignature,
  STRIPE: stripe,
  RELATIONS: Relations,
  ALERGIES: alergy,
  APPOINTMENT: appointment,
  DRUGS: drugs,
  MEDICATION: medication,
  MYDOCTOR: myDoctor,
  PREGANANCY2: preganancy,
  PROFILE: default_image,
  LABFILE: Labfile,
  PHYSICIAN: physician,
  UNDERREVIEW: underReview,
  LOGOGREEN: logoGreen,
  LADYDOCTOR: Ladydoctor,
  FOROTPASSWORD: forgotpassword,
  PAYMENT: payments,
  SIGNATURE: SignatureRecognition,
  VERIFICATION: Verification,
  MASKGROUP: Maskgroup,
  COPY: Copy,
  SERVICEREGION: ServiceRegion,
  GENDER: Gender,
  DATE: Date,
  EMAIL: email,
  CALL: call,
  LANGUAGE: languag,
  LOCATION: location,
  PASSWORD: password,
  VECTOR_NAME: VectorName,
  INTELY_HEALTH_BACKGROUND: IntelyHealthBackGround,
  APP_STORE_BADGE: AppStoreBadge,
  GOOGLE_PLAY: GooglePlay,
  INTELY_HEALTH_LOGO: IntelyHealthLogo,
  FEMALE_DOCTOR: FemaleDoctor,
  PATIENT_AVATAR: PatientAvatar,
  STAFF_AVATAR: StaffAvatar,
  ADMIN_ROLE_IMAGE: AdminRoleImage,
  RESET_ICON: resetIcon,
  // DEFAULT_IMAGE: default_image,
};
